import React, { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { RadioGroup, useFormApi, useFormState, Select, Option } from "informed";
import { useHistory, useLocation } from "react-router-dom";
import { isValid } from "date-fns";
import { isMobile } from "react-device-detect";
import classnames from "classnames";

import styles from "./index.module.scss";
import alertImage from "~/assets/images/veriff-alert-image.svg";
import { useMotorContext } from "~/contexts/MotorProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useUserContext } from "~/contexts/UserProvider";

import { H3, H6, TextExtraSmall, TextSmall } from "~/components/Typography";
import ButtonIcon from "~/components/ButtonIcon";
import Button from "~/components/Button";
import Modal from "~/components/Modal";
import GridWrapper from "~/components/GridWrapper";
import TextInput from "~/components/TextInput";
import Loading from "~/components/Loading";
import MobileUploadRequired from "~/components/MobileUploadRequired";
import UserVerificationModal from "./UserVerification";
import RadioButton from "~/components/RadioButton";

import { formatIsoDate, formatUkDate, localDateFromIso } from "~/helpers/dates";
import getPolicyCodeFromInsuranceType from "~/helpers/getPolicyCodeFromInsuranceType";
import { useHomeContext } from "~/contexts/HomeProvider";
import { InsuranceType } from "../../../types";

import { HeapEventName, useHeapContext } from "~/contexts/HeapProvider";
import hasErrorCode from "~/helpers/hasErrorCode";

import {
  cleanInputDate,
  isFullName,
  MIN_DL_LENGTH,
  required,
  validateDateOfBirth,
  validateLength,
} from "~/helpers/validators";
import {
  VERIFICATION_STATUS,
  ENVIRONMENTS,
  DOCUMENTS_NAMES,
  PARISHES,
} from "~/helpers/constants";
import AppError from "~/helpers/AppError";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";

type IEditField = {
  label: string;
  fieldName: string;
  type: "text" | "date" | "radio" | "select";
  options?: Array<{
    label: string;
    value: string;
  }>;
  placeholder?: string;
  validator: (
    value?: string | number | boolean | {} | undefined
  ) => string | undefined;
};

type DocumentsInfo = {
  fullName?: string;
  dateOfBirth?: string;
  documentNumber?: string;
  gender?: string;
  address?: string;
  parish?: string;
};

type Props = {
  nextPath?: string;
  insuranceType: InsuranceType;
};

const GET_VERIFIED_DOCUMENTS = gql`
  query GetVerifiedData($policyObject: String!) {
    getVerifiedData(policyObject: $policyObject) {
      verificationStatus
      verificationReason
      fullName
      dateOfBirth
      gender
      address
      parish
      documentNumber
      documentType
      driverDocumentType
    }
  }
`;

const SET_VERIFIED_DOCUMENTS = gql`
  mutation SetVerifiedDataReviewed(
    $input: VerifiedDataReviewedInput!
    $policyId: String!
  ) {
    setVerifiedDataReviewed(input: $input, policyId: $policyId)
  }
`;

const GENERATE_CONTRACT = gql`
  mutation GenerateContract($input: GenerateContractInput!) {
    generateContract(input: $input) {
      contractUrl
    }
  }
`;

const keyToUpdate = [
  "fullName",
  "dateOfBirth",
  "gender",
  "address",
  "parish",
  "documentNumber",
];

const genderLabels = {
  M: "Male",
  F: "Female",
  U: "Neither of the above",
};

const pollingInterval = 1000;
const skipVerificationTag = "verskip";
const parishOptions = PARISHES;

export default function DocumentsReview({ nextPath, insuranceType }: Props) {
  const motorCtx = useMotorContext();
  const homeCtx = useHomeContext();
  const userCtx = useUserContext();
  const formState = useFormState();
  const formApi = useFormApi();
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const heapCtx = useHeapContext();
  const querySearch = new URLSearchParams(useLocation().search);

  const [toggleModal, setToggleModal] = useState(false);
  const [currentEditable, setCurrentEditable] = useState<
    IEditField | undefined
  >(undefined);
  const [showModal, setShowModal] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState<string>("");
  const [verificationReason, setVerificationReason] = useState<string>("");
  const [, setError] = useState();
  const [loading, setLoading] = useState(false);

  const policyId =
    insuranceType === ("motor" as InsuranceType)
      ? motorCtx.policyInfo?.data?.id
      : homeCtx.policyId;

  const isVerificationSkip = querySearch.has(skipVerificationTag);
  const getVerifiedData = useQuery(GET_VERIFIED_DOCUMENTS, {
    variables: {
      email: userCtx.email,
      policyObject: getPolicyCodeFromInsuranceType(insuranceType),
    },
    fetchPolicy: "no-cache",
  });

  const [setVerifiedDataReviewed] = useMutation(SET_VERIFIED_DOCUMENTS);
  const [generateContract] = useMutation(GENERATE_CONTRACT);

  const { data = {}, loading: isLoadingDocumentVerification } = getVerifiedData;

  const [values, setValues] = useState<DocumentsInfo>({
    fullName: undefined,
    dateOfBirth: undefined,
    documentNumber: undefined,
    gender: undefined,
    address: undefined,
    parish: undefined,
  });

  const {
    fullName,
    dateOfBirth,
    documentNumber,
    gender,
    address,
    parish,
  } = values;

  const documentName =
    insuranceType === ("motor" as InsuranceType)
      ? data?.getVerifiedData?.documentType &&
        DOCUMENTS_NAMES[data.getVerifiedData.driverDocumentType]
      : data?.getVerifiedData?.documentType &&
        DOCUMENTS_NAMES[data.getVerifiedData.documentType];

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 30,
      totalOfSteps: 37,
      title: "Identity Documents",
    });
    return () => {
      setVerificationStatus("");
      setVerificationReason("");
    };
  });

  useEffect(() => {
    if (
      isLoadingDocumentVerification ||
      verificationStatus === VERIFICATION_STATUS.started
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoadingDocumentVerification, verificationStatus]);

  useEffect(() => {
    if (Object.keys(data).length) {
      setVerificationStatus(data?.getVerifiedData?.verificationStatus);
      setVerificationReason(data?.getVerifiedData?.verificationReason);
    }
  }, [data]);

  useEffect(() => {
    if (
      !verificationStatus ||
      verificationStatus === VERIFICATION_STATUS.started
    ) {
      getVerifiedData.startPolling(pollingInterval);
    } else if (
      verificationStatus &&
      (verificationStatus === VERIFICATION_STATUS.declined ||
        verificationStatus === VERIFICATION_STATUS.resubmissionRequested ||
        verificationStatus === VERIFICATION_STATUS.timeout ||
        verificationStatus === VERIFICATION_STATUS.expired ||
        verificationStatus === VERIFICATION_STATUS.abandoned)
    ) {
      getVerifiedData.stopPolling();
      if (
        isVerificationSkip &&
        process.env.REACT_APP_ENV !== ENVIRONMENTS.production
      ) {
        return;
      }

      const handleContinueClick = () => {
        heapCtx.track(HeapEventName.ONBOARDING_INFO_REVIEW_CONTINUE, {
          "Policy ID": policyId,
        });

        history.push({ search: skipVerificationTag });
      };

      const secondaryBtn =
        process.env.REACT_APP_ENV === ENVIRONMENTS.production
          ? {
              text: "Exit to Portal",
              onClick: () => history.push(`/portal`),
            }
          : {
              text: "Continue",
              onClick: handleContinueClick,
            };

      const errorMessage = verificationReason
        ? verificationReason
        : "We are having trouble verifying who you are.";

      heapCtx.track(HeapEventName.ONBOARDING_ID_ERROR_SCREEN, {
        "Policy ID": policyId,
        "Error Code": verificationStatus,
        "Error Message": errorMessage,
      });

      throw new AppError(`${errorMessage}`, {
        title: "Oops! We couldn’t verify your identity documents.",
        mainButton: {
          text: "Go Back",
          onClick: () => history.push(`/BRB/${insuranceType}/documents`),
        },
        secondaryButton: secondaryBtn,
        assistantMessage: undefined,
        image: (
          <img src={alertImage} alt="Alert" className={styles.AlertImage} />
        ),
      });
    } else if (
      verificationStatus === VERIFICATION_STATUS.approved ||
      (verificationStatus === VERIFICATION_STATUS.timeout &&
        process.env.REACT_APP_ENV !== ENVIRONMENTS.production)
    ) {
      getVerifiedData.stopPolling();
      if (!data?.getVerifiedData) return;
      const currentValues = keyToUpdate.reduce((acc, key) => {
        return {
          ...acc,
          [key]: (() => {
            if (key === "documentNumber" && insuranceType === "motor") {
              return "";
            } else if (key === "dateOfBirth") {
              return data.getVerifiedData[key] &&
                isValid(localDateFromIso(data.getVerifiedData[key]))
                ? data.getVerifiedData[key]
                : "";
            } else {
              return data.getVerifiedData[key];
            }
          })(),
        };
      }, {}) as DocumentsInfo;
      setValues(currentValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingDocumentVerification,
    data,
    getVerifiedData,
    formApi,
    verificationStatus,
  ]);

  const handleOnOpenToEditField = (value: IEditField) => {
    setCurrentEditable(value);
    setToggleModal(true);
  };

  const handleOnUpDateInfo = async () => {
    try {
      setLoading(true);

      const documentType =
        insuranceType === ("motor" as InsuranceType)
          ? data?.getVerifiedData?.driverDocumentType
          : data?.getVerifiedData?.documentType;

      const response = await setVerifiedDataReviewed({
        variables: {
          input: {
            fullName: values.fullName,
            dateOfBirth: values.dateOfBirth,
            gender: values.gender,
            address: values.address,
            parish: values.parish,
            documentNumber: values.documentNumber,
            documentType,
          },
          policyId,
        },
      });

      heapCtx.addUserProperties({
        "Date of Birth": values.dateOfBirth as string,
      });

      if (!policyId) {
        throw new Error("Missing policy ID");
      }

      if (response?.data?.setVerifiedDataReviewed && policyId) {
        const responseContract = await generateContract({
          variables: {
            input: {
              policyId,
              callbackUrl: `${location.origin}${nextPath}`,
            },
          },
        });
        if (responseContract.data?.generateContract?.contractUrl) {
          location.href = responseContract.data.generateContract.contractUrl;
        } else {
          throw new Error("It was not possible to generate the contract");
        }
      }
    } catch (err) {
      setLoading(false);
      if (hasErrorCode(err, "NOT_VERIFIED")) {
        return setShowModal(true);
      } else {
        setError(() => {
          throw err;
        });
      }
    }
  };

  const handleOnSaveEdition = () => {
    if (
      currentEditable?.validator &&
      !!currentEditable?.validator(formState.values[currentEditable.fieldName])
    ) {
      return;
    }

    setValues({
      fullName: formState.values.fullName
        ? `${formState.values.fullName}`
        : values.fullName
        ? `${values.fullName}`
        : "",
      dateOfBirth: formState.values.dateOfBirth
        ? formatUkDate(cleanInputDate(`${formState.values.dateOfBirth}`))
        : values.dateOfBirth
        ? `${values.dateOfBirth}`
        : undefined,
      documentNumber: formState.values.documentNumber
        ? `${formState.values.documentNumber}`
        : values.documentNumber
        ? `${values.documentNumber}`
        : "",
      gender: formState.values.gender
        ? `${formState.values.gender}`
        : values.gender
        ? `${values.gender}`
        : "",
      address: formState.values.address
        ? `${formState.values.address}`
        : values.address
        ? `${values.address}`
        : "",
      parish: formState.values.parish
        ? `${formState.values.parish}`
        : values.parish
        ? `${values.parish}`
        : "",
    });
    setToggleModal(false);
  };

  if (loading)
    return (
      <div className={styles.LoadingWrapper}>
        <Loading />
        {verificationStatus === VERIFICATION_STATUS.started && (
          <div className={styles.LoadingMessage}>
            <H3 className={styles.Title} component="h1">
              We’re verifying your identity documents
            </H3>
            <TextExtraSmall>
              This process could take more than 30 seconds.
            </TextExtraSmall>
            <TextExtraSmall>
              Please, wait until the verification is completed.
            </TextExtraSmall>
          </div>
        )}
      </div>
    );

  const disabledReviewButton =
    !fullName ||
    !dateOfBirth ||
    !documentNumber ||
    !gender ||
    !address ||
    !parish;

  if (!isMobile && userCtx.email) {
    return (
      <>
        <MobileUploadRequired
          title="To complete the process and get your coverage plan, you will need a
          smartphone."
          email={userCtx.email}
          policyId={policyId || ""}
        />
      </>
    );
  }

  return (
    <>
      <div className={styles.Content}>
        <H3 className={styles.Heading} component="h1">
          Let’s review your personal information before you review and sign your
          {insuranceType === ("motor" as InsuranceType) ? " Motor " : " Home "}
          Insurance Policy.
        </H3>
        <TextSmall>
          Please provide any missing information to continue. Afterwards, you’ll
          proceed to the payment stage.
        </TextSmall>
        <div className={styles.EditableBox}>
          <div className={styles.EditableItem}>
            <div
              className={styles.Labels}
              role="group"
              aria-labelledby="review-name"
            >
              <H6 component="p" id="review-name">
                Full name
              </H6>
              <TextSmall>{fullName || "N/A"}</TextSmall>
            </div>
            <ButtonIcon
              iconName="PenEdit"
              className={styles.BtnEdit}
              describedby="review-name"
              onClick={() =>
                handleOnOpenToEditField({
                  label: "Full name",
                  fieldName: "fullName",
                  type: "text",
                  placeholder: "Full Name",
                  validator: isFullName("Please enter your last name as well"),
                })
              }
            >
              <TextSmall>Edit</TextSmall>
            </ButtonIcon>
          </div>
          <div className={styles.EditableItem}>
            <div
              className={styles.Labels}
              role="group"
              aria-labelledby="review-dob"
            >
              <H6 component="p" id="review-dob">
                Date of birth
              </H6>
              <TextSmall>
                {dateOfBirth ? formatIsoDate(dateOfBirth) : "N/A"}
              </TextSmall>
            </div>
            <ButtonIcon
              iconName="PenEdit"
              className={styles.BtnEdit}
              describedby="review-dob"
              onClick={() =>
                handleOnOpenToEditField({
                  label: "Date of birth",
                  fieldName: "dateOfBirth",
                  type: "date",
                  placeholder: "DD / MM / YYYY",
                  validator: validateDateOfBirth,
                })
              }
            >
              <TextSmall>Edit</TextSmall>
            </ButtonIcon>
          </div>
          <div className={styles.EditableItem}>
            <div
              className={styles.Labels}
              role="group"
              aria-labelledby="review-gender"
            >
              <H6 component="p" id="review-gender">
                Gender
              </H6>
              <TextSmall>{genderLabels[gender || ""] || "N/A"}</TextSmall>
            </div>
            <ButtonIcon
              iconName="PenEdit"
              className={styles.BtnEdit}
              describedby="review-gender"
              onClick={() =>
                handleOnOpenToEditField({
                  label: "Gender",
                  fieldName: "gender",
                  type: "radio",
                  options: Object.keys(genderLabels).map((value) => ({
                    label: genderLabels[value],
                    value,
                  })),
                  validator: required("Gender is required"),
                })
              }
            >
              <TextSmall>Edit</TextSmall>
            </ButtonIcon>
          </div>
          <div className={styles.EditableItem}>
            <div
              className={styles.Labels}
              role="group"
              aria-labelledby="review-address"
            >
              <H6 component="p" id="review-address">
                Address
              </H6>
              <TextSmall>{address || "N/A"}</TextSmall>
            </div>
            <ButtonIcon
              iconName="PenEdit"
              className={styles.BtnEdit}
              describedby="review-address"
              onClick={() =>
                handleOnOpenToEditField({
                  label: "Address",
                  fieldName: "address",
                  type: "text",
                  placeholder: "Address",
                  validator: required("Address is required"),
                })
              }
            >
              <TextSmall>Edit</TextSmall>
            </ButtonIcon>
          </div>
          <div className={styles.EditableItem}>
            <div
              className={styles.Labels}
              role="group"
              aria-labelledby="review-parish"
            >
              <H6 component="p" id="review-parish">
                Parish
              </H6>
              <TextSmall>{parish || "N/A"}</TextSmall>
            </div>
            <ButtonIcon
              iconName="PenEdit"
              className={styles.BtnEdit}
              describedby="review-parish"
              onClick={() =>
                handleOnOpenToEditField({
                  label: "Parish",
                  fieldName: "parish",
                  type: "select",
                  placeholder: "Parish",
                  validator: required("Parish is required"),
                  options: parishOptions,
                })
              }
            >
              <TextSmall>Edit</TextSmall>
            </ButtonIcon>
          </div>
          <div className={styles.EditableItem}>
            <div
              className={styles.Labels}
              role="group"
              aria-labelledby="review-license"
            >
              <H6 component="p" id="review-license">
                {`${documentName} No.`}
              </H6>
              <TextSmall>{documentNumber || "N/A"}</TextSmall>
            </div>
            <ButtonIcon
              iconName="PenEdit"
              className={styles.BtnEdit}
              describedby="review-license"
              onClick={() =>
                handleOnOpenToEditField({
                  label: `${documentName} No.`,
                  fieldName: "documentNumber",
                  type: "text",
                  placeholder: "123456789",
                  validator: validateLength(
                    MIN_DL_LENGTH,
                    `${documentName} No`
                  ),
                })
              }
            >
              <TextSmall>Edit</TextSmall>
            </ButtonIcon>
          </div>
        </div>
        <Button
          className={styles.BtnNext}
          onClick={handleOnUpDateInfo}
          disabled={disabledReviewButton}
        >
          Review Policy with DocuSign
        </Button>
      </div>
      <Modal
        isOpen={toggleModal}
        shouldOverlayCloseOnClick
        title="Edit"
        onClose={() => setToggleModal(false)}
        className={styles.ModalWrapper}
        id="document-review-modal"
      >
        <GridWrapper>
          <div className={styles.ModalEditContent}>
            {(currentEditable?.type === "text" ||
              currentEditable?.type === "date") &&
              currentEditable?.fieldName &&
              (currentEditable.fieldName === "dateOfBirth" ? (
                <TextInput
                  field={currentEditable.fieldName}
                  initialValue={
                    values[currentEditable.fieldName]
                      ? formatIsoDate(
                          values[currentEditable.fieldName] as string
                        )
                      : ""
                  }
                  type={currentEditable.type}
                  placeholder={currentEditable?.placeholder || undefined}
                  validate={currentEditable?.validator}
                  validateOnBlur
                  validateOnChange
                  // TODO: UX concern: https://app.asana.com/0/1176838026687375/1200207868328821/f
                  inputMode="numeric"
                  label={currentEditable.label}
                  keepState
                  autoFocus
                />
              ) : (
                <TextInput
                  field={currentEditable.fieldName}
                  initialValue={values[currentEditable.fieldName]}
                  type={currentEditable.type}
                  placeholder={currentEditable?.placeholder || undefined}
                  validate={currentEditable?.validator}
                  validateOnBlur
                  validateOnChange
                  label={currentEditable.label}
                  keepState
                  autoFocus
                />
              ))}
            {currentEditable?.type === "radio" &&
              currentEditable?.fieldName &&
              currentEditable?.options && (
                <fieldset>
                  <legend>
                    <TextSmall className={styles.InputLabel}>
                      {currentEditable?.label}
                    </TextSmall>
                  </legend>
                  <RadioGroup
                    field={currentEditable?.fieldName}
                    initialValue={values[currentEditable.fieldName]}
                  >
                    {currentEditable?.options?.map((option, idx) => (
                      <RadioButton
                        key={`${currentEditable?.fieldName}-${idx}`}
                        label={option.label}
                        value={option.value}
                        field={currentEditable?.fieldName}
                        keepState
                      />
                    ))}
                  </RadioGroup>
                </fieldset>
              )}
            {currentEditable?.type === "select" &&
              currentEditable?.fieldName &&
              currentEditable?.options && (
                <div className={styles.dropdownWrapper}>
                  <TextSmall className={styles.InputLabel}>
                    {currentEditable?.label}
                  </TextSmall>
                  <Select
                    className={classnames(styles.dropdown)}
                    field={currentEditable?.fieldName}
                    initialValue={values[currentEditable.fieldName]}
                  >
                    <Option value="" disabled>
                      Select a parish
                    </Option>
                    {currentEditable?.options?.map((option) => (
                      <Option
                        value={option.value}
                        key={option.label}
                        field={currentEditable?.fieldName}
                      >
                        {option.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
            <Button
              className={styles.ButtonSaveEdition}
              onClick={handleOnSaveEdition}
            >
              Save
            </Button>
          </div>
        </GridWrapper>
      </Modal>
      <UserVerificationModal
        email={userCtx.email}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
}
