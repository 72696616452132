import { FormValue } from "informed";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import SearchList from "~/components/SearchList";
import { Bold, H3, Text } from "~/components/Typography";
import VehicleSearchDisplaySmall from "~/components/VehicleSearchDisplaySmall";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { MotorContext } from "~/contexts/MotorProvider";
import pushWithParams from "~/helpers/pushWithParams";

import styles from "./style.module.scss";

const occupations = [
  "Student",
  "Other",
  "Attorney-at-Law",
  "Accountant",
  "Administrative Assistant",
  "Architect",
  "Banker",
  "Bartender",
  "Chief Executive Officer",
  "Civil Servant",
  "Clergy",
  "Consultant",
  "Dentist",
  "Driver",
  "Economist",
  "Educator",
  "Engineer",
  "Entertainer or DJ",
  "Insurance Executive",
  "Journalist",
  "Librarian",
  "Management and Business Consultant",
  "Manager",
  "Medical Practitioner",
  "Nurse",
  "Pilot",
  "Police Officer",
  "Professor",
  "Psychologist",
  "QEH Staff",
  "Retired",
  "Salesperson",
  "Self Employed",
  "Senior Manager",
  "Social Worker",
  "Soldier",
  "Systems Administrator",
  "Teacher",
  "University Lecturer",
  "Veterinarian",
  "Waiter or Waitress",
  "Unemployed",
];

type Props = {
  nextPath?: string;
};

const Occupation = ({ nextPath }: Props) => {
  const motorCtx = useContext(MotorContext);
  const history = useHistory();
  const heapCtx = useHeapContext();

  const handleChange = async (value: FormValue) => {
    try {
      await motorCtx.savingsInfo.setItem("occupation", value as string);

      heapCtx.addUserProperties({
        Occupation: value as string,
      });

      heapCtx.track(HeapEventName.MOTOR_OCCUPATION, {
        Occupation: value as string,
      });

      if (value && value?.toString()?.match(/Unemployed/)) {
        pushWithParams(history, "/BRB/motor/email");
      } else if (nextPath) {
        pushWithParams(history, nextPath);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <fieldset>
      <legend>
        <VehicleSearchDisplaySmall
          prefix={`Your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()} `}
        />
        <H3 fontWeight="bold" component="h1">
          What is your occupation? Choose the best match.
        </H3>
        <Text className={styles.SubHeader}>
          If unsure, please choose&nbsp;
          <Bold>&apos;Other&apos;</Bold>
        </Text>
      </legend>
      <SearchList
        field="occupation"
        list={occupations}
        onChange={handleChange}
        keepState
        classNameGrid={styles.GridList}
        autoFocus
        excludeFromFilter={["Other"]}
        noResultsCopy="Try to type something new, or select Other if you cannot find
        your occupation."
      />
    </fieldset>
  );
};

export default Occupation;
