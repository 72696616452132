import { useFieldState, useFormState } from "informed";
import React from "react";
import { useHistory } from "react-router";
import Button from "~/components/Button";
import LoadingWrapper from "~/components/LoadingWrapper";
import TextInput from "~/components/TextInput";
import { H3, TextSmall } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import { formatCurrency } from "~/helpers/currency";
import cleanNumberInput from "~/helpers/formatters/cleanNumberInput";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import styles from "./index.module.scss";

type Props = {
  nextPath?: string;
};

const CONTENTS_VALUE_FIELD_NAME = "ContentsValueField";
const MINIMUM_TOTAL_BUFFER_PERCENTAGE = 0.2;
const MINIMUM_CONTENTS_VALUE = 25000;
const ROUND_UP_AMOUNT = 1000;

export default function ContentsValue({ nextPath }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const homeCtx = useHomeContext();
  const formState = useFormState();
  const contentsValueFieldState = useFieldState(CONTENTS_VALUE_FIELD_NAME);
  const [minimumValue, setMinimumValue] = React.useState<number>(0);

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 1,
      totalOfSteps: 3,
      title: "Contents Information",
    });
  });

  React.useEffect(() => {
    let contentsTotal =
      homeCtx.getContentItemsTotal() + homeCtx.getAllRiskItemsTotal();

    if (contentsTotal) {
      const contentsBuffer = contentsTotal * MINIMUM_TOTAL_BUFFER_PERCENTAGE;
      contentsTotal = contentsTotal + contentsBuffer;
    }

    const max = Math.max(contentsTotal, MINIMUM_CONTENTS_VALUE);

    if (!max) {
      setMinimumValue(0);
    } else {
      setMinimumValue(Math.ceil(max / ROUND_UP_AMOUNT) * ROUND_UP_AMOUNT);
    }
  }, [homeCtx, homeCtx.contents, homeCtx.allRisk, homeCtx.replacementValue]);

  useUpdateAssistant({
    text: (
      <TextSmall>
        Almi requires a minimum of $25,000 in content insurance.
      </TextSmall>
    ),
    isOpen: true,
  });

  const handleNextClick = () => {
    if (nextPath) {
      homeCtx.setState({
        contentsValue: cleanNumberInput(
          formState.values[CONTENTS_VALUE_FIELD_NAME]
        ),
      });

      history.push(nextPath);
    }
  };

  return !minimumValue ? (
    <LoadingWrapper loading={!!minimumValue} />
  ) : (
    <div className={styles.Content}>
      <H3 className={styles.Title}>
        What is the value of the contents in your home?
      </H3>

      <TextSmall>
        Estimate the value of your contents below, including the items you
        listed previously.
      </TextSmall>

      <TextInput
        field={CONTENTS_VALUE_FIELD_NAME}
        placeholder={"$ 0"}
        type="currency"
        initialValue={0}
        validate={(value) => {
          const cleanValue = cleanNumberInput(value);

          if (!cleanValue) {
            return "Contents value is required";
          } else if (cleanValue < minimumValue) {
            return `Cannot enter a value lower than ${formatCurrency(
              minimumValue
            )}.`;
          } else {
            return undefined;
          }
        }}
        autoFocus
        validateOnBlur
        validateOnChange
        validateOnMount
        pattern="\d*"
      />

      <div className={styles.ButtonWrapper}>
        <Button
          onClick={handleNextClick}
          disabled={!!contentsValueFieldState.error}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
