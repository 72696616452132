import React from "react";
import { matchPath, useHistory } from "react-router";
import { Bold, H4, TextSmall } from "~/components/Typography";
import CheckImg from "~/assets/images/check.png";
import styles from "./index.module.scss";
import Button from "../Button";
import Modal from "../Modal";
import PaymentCheckout from "~/screens/Payment/PaymentCheckout";
import { PAYMENT_UPDATE_RESULT_URL } from "~/routes/index.constant";

type PathMatchType = {
  params: { policyId: string };
};

type Props = {
  cardIsUpdateable: boolean;
  finalCardNumbers: string;
  policyId: string;
};

const PortalPaymentMethodCard = ({
  cardIsUpdateable,
  finalCardNumbers,
  policyId,
}: Props) => {
  const history = useHistory();
  const pathMatch: PathMatchType | null = matchPath(history.location.pathname, {
    path: "/portal/policy/:policyId/update-payment",
    exact: true,
    strict: false,
  });
  const modalOpen = !!(pathMatch && pathMatch.params.policyId === policyId);
  const openModal = () => {
    history.push(`/portal/policy/${policyId}/update-payment`);
  };
  const closeModal = () => {
    history.push(`/portal/policy/${policyId}`);
  };

  return (
    <div className={styles.Container} role="group" aria-label="payment method">
      <div className={styles.ImageContainer}>
        <img
          src={CheckImg}
          alt="check mark"
          aria-hidden
          className={styles.Image}
        />
      </div>

      <div className={styles.TextContainer}>
        <div>
          <TextSmall>Payment Method</TextSmall>
          <H4>Card Details</H4>
        </div>

        <div className={styles.TextSection}>
          <TextSmall className={styles.TextLeftSection} fontWeight="bold">
            Card Number
          </TextSmall>
          <div className={styles.TextRightSection}>
            <TextSmall className={styles.CardNumber}>
              <Bold>···· ···· ····</Bold>{" "}
              {finalCardNumbers ? (
                <span className={styles.FinalCardNumbers}>
                  {finalCardNumbers}
                </span>
              ) : (
                <Bold>····</Bold>
              )}
            </TextSmall>
            {cardIsUpdateable && (
              <Button
                className={styles.UpdateCard}
                type="button"
                text
                variant="textSmall"
                onClick={openModal}
              >
                <Bold>Update Card</Bold>
              </Button>
            )}
          </div>
        </div>
      </div>
      <Modal
        title="Update Payment Information"
        isOpen={modalOpen}
        shouldOverlayCloseOnClick
        onClose={closeModal}
        noOffset
        id="policy-modal"
      >
        <PaymentCheckout
          isPaymentUpdate
          paymentResultUrl={PAYMENT_UPDATE_RESULT_URL}
          updatePolicyId={policyId}
          onNext={closeModal}
        />
      </Modal>
    </div>
  );
};

export default PortalPaymentMethodCard;
