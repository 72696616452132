/* eslint-disable no-magic-numbers */
import React, { useState } from "react";
import { useHistory, matchPath } from "react-router-dom";
import gql from "graphql-tag";
import { useMotorContext } from "~/contexts/MotorProvider";
import { useUserContext } from "~/contexts/UserProvider";
import { useAuth0Context } from "~/contexts/Auth0Context";
import { TextSmall, Text, H3, Bold } from "~/components/Typography";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import { validatePassword } from "~/helpers/validators";
import { findCountryByAlpha3 } from "~/helpers/countryCodes";
import { CountryCode, InsuranceType } from "../../../types";
import { Eye, EyeClose } from "~/components/Icon/icons";
import pushWithParams from "~/helpers/pushWithParams";
import { useMutation } from "@apollo/react-hooks";
import Loading from "~/components/Loading";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

import styles from "./index.module.scss";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useHomeContext } from "~/contexts/HomeProvider";

const CREATE_PROFILE_MUTATION = gql`
  mutation CreateIndividualProfile(
    $input: CreateIndividualProfileInput!
    $policyId: String!
  ) {
    createIndividualProfile(input: $input, policyId: $policyId) {
      token {
        idToken
        exp
      }
    }
  }
`;

type CreateProfileResponse = {
  createIndividualProfile: {
    token?: {
      idToken: string;
      exp: number;
    };
  };
};

type Props = {
  nextPath?: string;
  insuranceType: InsuranceType;
};

const AccountPassword = ({ insuranceType, nextPath }: Props) => {
  const auth0 = useAuth0Context();
  const motorCtx = useMotorContext();
  const homeCtx = useHomeContext();
  const userCtx = useUserContext();
  const heapCtx = useHeapContext();
  const history = useHistory();
  const fieldName = "password";
  const field = useCustomFieldState(fieldName);
  const [showPassword, setShowPassword] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [, setError] = useState();
  const [createProfile, { loading }] = useMutation<CreateProfileResponse>(
    CREATE_PROFILE_MUTATION
  );

  const pathMatch: {
    params: { countryCode: CountryCode };
  } | null = matchPath(history.location.pathname, {
    path: "/:countryCode/:insuranceType/:step",
    exact: true,
    strict: false,
  });

  const createProfileHandler = async () => {
    try {
      setShowLoading(true);
      const countryCode = findCountryByAlpha3(userCtx.countryPhoneCode);

      const profile = await createProfile({
        variables: {
          input: {
            email: userCtx.email,
            chosenName: userCtx.name,
            diallingCode: countryCode?.diallingCode
              ? `+${countryCode.diallingCode}`
              : undefined,
            phoneNumber: userCtx.phoneNumber
              ? userCtx.phoneNumber.replace(/\s|-|_|\./g, "")
              : undefined,
            password: field.value,
            countryCode:
              pathMatch && pathMatch.params.countryCode
                ? pathMatch.params.countryCode.toUpperCase()
                : "",
            occupation:
              insuranceType === "motor"
                ? motorCtx.savingsInfo.data.occupation
                : homeCtx.occupation,
            association:
              insuranceType === "motor"
                ? motorCtx.savingsInfo.data.association
                : "",
          },
          policyId:
            insuranceType === "motor"
              ? motorCtx.policyInfo.data.id
              : homeCtx.policyId,
        },
      });

      if (
        profile &&
        profile.data &&
        profile.data.createIndividualProfile &&
        profile.data.createIndividualProfile.token &&
        !loading &&
        nextPath
      ) {
        if (insuranceType === "motor") {
          auth0.handleSetToken(
            profile.data.createIndividualProfile.token.idToken,
            profile.data.createIndividualProfile.token.exp
          );

          heapCtx.track(HeapEventName.ONBOARDING_PASSWORD, {
            "Policy ID": motorCtx.policyInfo.data.id, //TODO check homeCtx too
          });
        } else if (insuranceType === "home") {
          auth0.handleSetToken(
            profile.data.createIndividualProfile.token.idToken,
            profile.data.createIndividualProfile.token.exp
          );
          heapCtx.track(HeapEventName.ONBOARDING_PASSWORD, {
            "Policy ID": homeCtx.policyId,
          });
        }

        pushWithParams(history, nextPath);
      } else {
        setShowLoading(false);
      }
    } catch (error) {
      setShowLoading(false);
      // Make error boundary catch this
      setError(() => {
        throw error;
      });
    }
  };

  const handleClick = async () => {
    try {
      await createProfileHandler();
    } catch (error) {
      console.warn(error);
    }
  };

  const label = "Create a password";

  if (showLoading)
    return (
      <div className={styles.LoadingWrapper}>
        <Loading />
      </div>
    );

  return (
    <>
      <Text>
        Your email <Bold>{userCtx.email}</Bold>
      </Text>
      <H3 className={styles.Subtitle} component="h1">
        {label}
      </H3>
      <div className={styles.InputContainer}>
        <div className={styles.InputWrapper}>
          <TextInput
            label={label}
            hideLabelFromView
            field={fieldName}
            placeholder="Password"
            keepState
            type={showPassword ? "text" : "password"}
            validate={validatePassword}
            validateOnBlur
            validateOnChange
            validateOnMount
            validationSuccess="Strong password"
            autoComplete="current-password"
            autoFocus
            ariaDescribedBy="password-instructions"
          />
          <button
            className={styles.IconButton}
            type="button"
            aria-label={showPassword ? "Hide password" : "Show password"}
            onClick={() => {
              setShowPassword(!showPassword);

              heapCtx.track(HeapEventName.ONBOARDING_PASSWORD_VISIBILITY, {
                "Policy ID":
                  insuranceType === "motor"
                    ? motorCtx.policyInfo?.data?.id
                    : homeCtx.policyId,
              });
            }}
          >
            {showPassword ? <Eye /> : <EyeClose />}
          </button>
        </div>

        <TextSmall className={styles.PasswordCopy}>
          <span id="password-instructions">
            Password must be at least 8 characters and contain at least 3 of: 1
            uppercase, 1 lowercase, 1 number, and 1 special character
          </span>
        </TextSmall>
      </div>

      <div className={styles.ButtonWrapper}>
        <Button disabled={!!field.error} type="button" onClick={handleClick}>
          Next
        </Button>
      </div>
    </>
  );
};

export default AccountPassword;
