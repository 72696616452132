import React from "react";
// eslint-disable-next-line import/no-named-as-default-member
import DocumentUpload, {
  DocumentWrapper,
} from "~/screens/Identification/DocumentUpload";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useUserContext } from "~/contexts/UserProvider";
import { DOCUMENT_TYPES } from "~/helpers/constants";
import { InsuranceType } from "../../../types";

type Props = {
  nextPath?: string;
  insuranceType: InsuranceType;
};

const documentWrappersList: DocumentWrapper[] = [
  {
    name: "Selfie",
    shortDescription: "Tap to add selfie",
    description: "Take a selfie so we can verify your identity",
    documentParts: [
      {
        name: "Face",
        accept: "image/*",
        context: "face",
        documentType: DOCUMENT_TYPES.face,
      },
    ],
    verify: true,
  },
  {
    name: "Identification",
    description: "Upload at least one of the following:",
    shortDescription: "Tap to add documents",
    minNumberOfOptionalDocs: 1,
    optionalDocuments: [
      {
        name: "National ID",
        shortDescription: "Tap to add document",
        description: "Take a photo of your National ID card",
        documentParts: [
          {
            name: "National ID front",
            accept: "image/*",
            context: "document-front",
            documentType: DOCUMENT_TYPES.idCard,
          },
          {
            name: "National ID back",
            accept: "image/*",
            context: "document-back",
            documentType: DOCUMENT_TYPES.idCard,
          },
        ],
        verify: true,
      },
      {
        name: "Passport",
        shortDescription: "Tap to add document",
        description: "Upload a photo of your Passport",
        documentParts: [
          {
            name: "Passport front",
            accept: "image/*",
            context: "document-front",
            documentType: DOCUMENT_TYPES.passport,
          },
        ],
        verify: true,
      },
    ],
  },
  {
    name: "Proof of address",
    shortDescription: "Tap to add document",
    description:
      "Upload a photo of a document with your address (eg. a utility bill or bank statement)",
    documentParts: [
      {
        name: "Proof of address",
        accept: "image/*,application/pdf",
        documentType: DOCUMENT_TYPES.proofAddress,
      },
    ],
  },
];

const RequiredDocuments = ({ nextPath, insuranceType }: Props) => {
  const homeCtx = useHomeContext();
  const userCtx = useUserContext();

  return (
    <DocumentUpload
      documentWrappersList={documentWrappersList}
      policyId={homeCtx.policyId as string}
      email={userCtx.email as string}
      nextPath={nextPath}
      insuranceType={insuranceType}
    />
  );
};

export default RequiredDocuments;
