import React, { ReactNode } from "react";
import classnames from "classnames";

import { TextExtraSmall, H5 } from "~/components/Typography";
import Button from "~/components/Button";

import styles from "./index.module.scss";
import { formatCurrency } from "~/helpers/currency";
import { InstallmentValue } from "../InstallmentValue";

type Props = {
  variant?: "common" | "highlight" | "featured";
  columns?: number;
  title: string;
  subTitle: string;
  installmentCount: number;
  installmentValue: number;
  children?: ReactNode;
  showTopHeader?: boolean;
  forceShowBodyContent?: boolean;
  showBorder?: boolean;
  secondaryButton?: ReactNode;
  onClickDetails?: () => void;
  labelReviewDetail?: string;
  className?: string;
};

export default function PolicySummaryCard(props: Props) {
  const {
    variant = "common",
    columns = 1,
    installmentCount,
    installmentValue,
    title,
    subTitle,
    children,
    showTopHeader = true,
    forceShowBodyContent = false,
    showBorder = true,
    secondaryButton,
    labelReviewDetail = "Purchase this policy",
    onClickDetails,
  } = props;

  const wrapperClasses = classnames(
    props.className,
    styles.PolicySummaryCardWrapper,
    {
      [styles.CommonCard]: variant === "common",
      [styles.HighlightCard]: variant === "highlight",
      [styles.FeaturedCard]: variant === "featured",
      [styles.Border]: variant === "highlight" && showBorder,
      // eslint-disable-next-line no-magic-numbers
      [styles.In3ColumnLayout]: columns === 3,
      // eslint-disable-next-line no-magic-numbers
      [styles.In2ColumnLayout]: columns === 2,
    }
  );

  const handleOnClickDetails = () => {
    if (typeof onClickDetails === "function") onClickDetails();
  };

  const formattedPrice = formatCurrency(installmentValue, false);

  return (
    <div className={wrapperClasses} role="group" aria-label={title}>
      {variant === "highlight" && showTopHeader && (
        <div className={styles.TopHeader}>
          <TextExtraSmall fontWeight="bold" className={styles.TopHeaderTitle}>
            Most Popular
          </TextExtraSmall>
        </div>
      )}

      <div className={styles.Heading}>
        <H5 className={styles.HeadingLeftElements}>{title}</H5>
        <H5>{formattedPrice}</H5>
      </div>

      <div className={styles.Heading}>
        <TextExtraSmall className={styles.HeadingLeftElements}>
          {subTitle}
        </TextExtraSmall>
        <InstallmentValue
          installmentCount={installmentCount}
          installmentValue={installmentValue}
          showValue={false}
        />
      </div>

      {children && (
        <div
          className={classnames(styles.BodyContent, {
            [styles.ForceShowBodyContent]: forceShowBodyContent,
          })}
        >
          {children}
        </div>
      )}

      <div
        className={classnames(styles.ButtonsWrapper, {
          [styles.NoSecondaryButton]: !secondaryButton,
          [styles.HasSecondaryButton]: !!secondaryButton,
        })}
      >
        <Button
          className={styles.ButtonSeeDetails}
          variant="tertiary"
          size="small"
          onClick={handleOnClickDetails}
        >
          {labelReviewDetail}
        </Button>
        {secondaryButton}
      </div>
    </div>
  );
}
