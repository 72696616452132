import React from "react";
import AlertImage from "~/assets/images/alert.png";
import { useHistory } from "react-router-dom";
import { parse } from "query-string";
import CheckedImg from "~/assets/images/checked.png";
import { Bold, H3, PreText } from "~/components/Typography";
import { getItem } from "~/helpers/localStorage";

import styles from "./index.module.scss";
import Button from "~/components/Button";

const PortalVerify = () => {
  const history = useHistory();
  const searchParams = parse(history.location.search);

  const savedId = getItem("verifyEmailId");

  let url = "/portal";

  if (savedId) {
    url = `/resume/${savedId}`;
  }

  console.log("URL: ", url);

  if (
    Object.keys(searchParams).length === 0 ||
    (searchParams.success !== "true" && searchParams.success !== "false")
  ) {
    history.push("/portal");
  }

  return (
    <div className={styles.Wrapper}>
      {searchParams.success === "false" && (
        <>
          <img className={styles.Image} src={AlertImage} alt="Alert" />
          <H3 className={styles.Title}>We were unable to verify your email</H3>
          <PreText className={styles.Subtitle}>{searchParams.message}</PreText>
        </>
      )}

      {searchParams.success === "true" && (
        <>
          <img className={styles.Image} src={CheckedImg} alt="Checked" />
          <H3 className={styles.Title}>Great! You&apos;re all set!</H3>

          <PreText className={styles.Subtitle}>
            Your email was successfully verified.{" "}
            <Bold>You can continue your quote in the same tab you started</Bold>
            , or
          </PreText>

          <Button
            className={styles.ContinueButton}
            onClick={() => history.push(url)}
          >
            Continue here
          </Button>
        </>
      )}
      <br />
    </div>
  );
};

export default PortalVerify;
