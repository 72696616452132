import React from "react";
import { Route } from "react-router-dom";
import ErrorBoundary from "~/components/ErrorBoundary";
import Error from "~/components/Error";
import OnboardProvider from "~/contexts/OnboardProvider";
import AssistantProvider from "~/contexts/AlmiAssistantProvider";
import HeaderProvider from "~/contexts/HeaderProvider";
import UserProvider from "~/contexts/UserProvider";
import MotorProvider from "~/contexts/MotorProvider";
import HomeProvider from "~/contexts/HomeProvider";
import { AnimatedSwitch } from "react-router-transition";
import styles from "../App.module.scss";
import { NO_COVERAGE_URL, PAYMENT_LANDING_URL } from "./index.constant";
import { screenTransitionAnimation } from "~/helpers/flow";

import FlowRouter from "~/flows";

import Welcome from "~/screens/Welcome";
/* TODO: for now, the BRB will be the country by default, when GUY is ready we can enable the component */
// import SelectCountry from "~/screens/SelectCountry";
import SelectInsuranceType from "~/screens/SelectInsuranceType";
import NoAvailableCoverage from "~/screens/NoAvailableCoverage";
import PaymentLanding from "~/screens/Motor/PaymentLanding";
import ResumeQuote from "~/screens/ResumeQuote";
import Footer from "~/components/Footer";
import NotFound from "~/components/NotFound";
import SupportProvider from "~/contexts/AlmiSupportProvider";

const OnboardingRoutes = () => {
  return (
    <main className={styles.OnboardingApp}>
      <OnboardProvider>
        <AssistantProvider>
          <SupportProvider>
            <UserProvider>
              <HeaderProvider>
                <ErrorBoundary errorComponent={Error}>
                  <MotorProvider>
                    <HomeProvider>
                      <AnimatedSwitch
                        {...screenTransitionAnimation}
                        className={styles.SwitchWrapper}
                      >
                        <Route exact path={"/"}>
                          <Welcome nextPath="/BRB/insurance" />
                        </Route>
                        {/* TODO: for now, the BRB will be the country by default, when GUY is ready we can enable the component */}
                        {/* <Route exact path={"/country"} component={SelectCountry} /> */}
                        <Route
                          exact
                          path={"/:countryCode(GUY|BRB)/insurance"}
                          component={SelectInsuranceType}
                        />
                        <Route
                          exact
                          path={
                            "/:countryCode(GUY|BRB)/:insuranceType(motor|home)/:step?"
                          }
                          component={FlowRouter}
                        />
                        <Route
                          path={NO_COVERAGE_URL}
                          component={NoAvailableCoverage}
                        />
                        <Route
                          path={PAYMENT_LANDING_URL}
                          component={PaymentLanding}
                        />
                        <Route
                          path={"/resume/:quote"}
                          component={ResumeQuote}
                        />
                        <Route path="*" component={NotFound} />
                      </AnimatedSwitch>
                      <Footer />
                    </HomeProvider>
                  </MotorProvider>
                </ErrorBoundary>
              </HeaderProvider>
            </UserProvider>
          </SupportProvider>
        </AssistantProvider>
      </OnboardProvider>
    </main>
  );
};
export default OnboardingRoutes;
