import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";

import { useHomeContext } from "~/contexts/HomeProvider";
import { required } from "~/helpers/validators";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";

import { H3 } from "~/components/Typography";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";

export default function HomeAddress({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const fieldName = "homeAddress";
  const { value, error } = useCustomFieldState(fieldName);

  const handleOnNext = () => {
    if (nextPath && typeof value === "string" && value?.length) {
      homeCtx.setState({ address: value });
      history.push(nextPath);
    }
  };

  return (
    <>
      <H3>What’s the address of the home you are insuring?</H3>
      <div className={styles.TextInputWrapper}>
        <TextInput
          hideLabelFromView
          field={fieldName}
          type="text"
          keepState
          placeholder="Home address"
          validate={required("Please enter the home address")}
          validateOnBlur
          validateOnChange
          autoFocus
          initialValue={homeCtx?.address}
        />
      </div>
      <div className={styles.ButtonWrapper}>
        <Button
          className={styles.ButtonNext}
          disabled={!!(error || !value || !String(value)?.length)}
          onClick={handleOnNext}
        >
          Next
        </Button>
      </div>
    </>
  );
}
