import React from "react";
import { useHistory } from "react-router-dom";

import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import { H3, TextSmall } from "~/components/Typography";
import { required } from "~/helpers/validators";

import VehicleSearchDisplay from "~/components/VehicleSearchDisplay";

import styles from "./index.module.scss";

import { useMotorContext } from "~/contexts/MotorProvider";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import pushWithParams from "~/helpers/pushWithParams";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

type PropsType = {
  nextPath?: string;
};

export const MIN_VEHICLE_VALUE = 14000;
export const MAX_VEHICLE_VALUE = 250000;

export default function VehicleValue({ nextPath }: PropsType) {
  const fieldName = "vehicleValue";
  const history = useHistory();
  const { value, error } = useCustomFieldState(fieldName);
  const motorCtx = useMotorContext();
  const heapCtx = useHeapContext();

  useUpdateAssistant({
    text: (
      <TextSmall>
        This is based on what you recently paid for the vehicle, or your
        official vehicle valuation certificate. You can get this from a licensed
        mechanic.
      </TextSmall>
    ),
  });

  const handleOnClick = async () => {
    try {
      const vehicleValueString = value as string;
      const vehicleValue = parseFloat(vehicleValueString.replace(/[^\d]/g, ""));
      await motorCtx.vehicleInfo.setItem("value", vehicleValue);

      heapCtx.track(HeapEventName.MOTOR_VEHICLE_VALUE, {
        "Vehicle Value": vehicleValue,
      });

      if (
        vehicleValue < MIN_VEHICLE_VALUE ||
        vehicleValue > MAX_VEHICLE_VALUE
      ) {
        return pushWithParams(
          history,
          `${"/contact-guardian"}?message=vehicle-value`
        );
      }

      if (nextPath) {
        pushWithParams(history, nextPath);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  const label = `How much is your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()} worth?`;
  return (
    <>
      <H3>{label}</H3>
      <VehicleSearchDisplay display={["year", "make", "model"]} />
      <div className={styles.TextInputWrapper}>
        <TextInput
          label={label}
          hideLabelFromView
          field={fieldName}
          placeholder="$ 0"
          type="currency"
          initialValue={motorCtx.vehicleInfo.data.value?.toString()}
          keepState
          validate={required("Please enter your vehicle's value")}
          validateOnBlur
          validateOnChange
          autoFocus
          inputMode="numeric"
        />
      </div>
      <div className={styles.ButtonWrapper}>
        <Button
          onClick={handleOnClick}
          className={styles.ButtonNext}
          disabled={!!(error || !value)}
        >
          Next
        </Button>
      </div>
    </>
  );
}
