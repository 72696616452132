import React, { useEffect } from "react";
import { Checkbox, RadioGroup } from "informed";
import { useHistory } from "react-router-dom";
import TileCard from "~/components/TileCard";
import { Bold, H3, Text, TextSmall } from "~/components/Typography";
import { useMotorContext } from "~/contexts/MotorProvider";
import styles from "./index.module.scss";
import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";
import Button from "~/components/Button";
import pushWithParams from "~/helpers/pushWithParams";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

const associations = [
  {
    title: "BARP",
    description: "Barbados Associations of Retired Persons",
  },
  {
    title: "Teachers",
  },
  {
    title: "BAMP",
    description: "Barbados Associations of Medical Practitioners",
  },
];

type Props = {
  nextPath?: string;
};

const Association = ({ nextPath }: Props) => {
  const motorCtx = useMotorContext();
  const assistantCtx = useAssistantContext();
  const heapCtx = useHeapContext();
  const history = useHistory();
  const selectedAssociation = motorCtx.savingsInfo.data.association;
  const fieldState = useCustomFieldState("association");

  useEffect(() => {
    assistantCtx.updateAssistant({
      isOpen: false,
      text: undefined,
    });
    // Disabling the rule here because it's only necessary to update the assistant/header
    // in the first rendering

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: string) => {
    try {
      const association = selectedAssociation === value ? "" : value;
      if (motorCtx?.setState) {
        motorCtx.setState("savingsInfo", {
          association,
          previousClaims: motorCtx.savingsInfo.data.previousClaims,
        });

        heapCtx.addUserProperties({
          Association: association,
        });

        heapCtx.track(HeapEventName.MOTOR_ASSOCIATION, {
          Association: association,
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const next = () => {
    try {
      if (nextPath) {
        pushWithParams(history, nextPath);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <fieldset>
      <legend className={styles.Heading}>
        <Text>
          You chose <Bold>{motorCtx.savingsInfo.data.occupation} </Bold>
        </Text>
        <H3 className={styles.Subheading} component="h1">
          Are you part of any of these associations?
        </H3>
        <TextSmall className={styles.Subcopy}>
          Membership in certain associations can get you discounts.
        </TextSmall>
      </legend>
      <div className={styles.Association}>
        <div className={styles.AssociationsList}>
          <RadioGroup field="association" keepState>
            {associations.map(({ title, description }, i) => (
              <TileCard
                key={`Association-TileCard-${i}`}
                title={title}
                text={description}
                inputId={`Association-TileCard-${i}`}
                checked={
                  selectedAssociation === title ||
                  motorCtx.savingsInfo.data.association === title
                }
                inputElement={
                  <Checkbox
                    id={`Association-TileCard-${i}`}
                    field={title}
                    value={title}
                    className={styles.HideCheckbox}
                    checked={fieldState.value === title}
                    onChange={(e) => handleChange(e.target.value)}
                  />
                }
                className={styles.TileCard}
              />
            ))}
          </RadioGroup>
        </div>
        <div className={styles.SkipBtnWrapper}>
          <Button onClick={next}>
            {selectedAssociation ? "Continue" : "Skip"}
          </Button>
        </div>
      </div>
    </fieldset>
  );
};

export default Association;
