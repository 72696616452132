import gql from "graphql-tag";
import React from "react";
import { useHistory } from "react-router-dom";
import Button from "~/components/Button";
import PhotoOrDocumentFileInput from "~/components/FileInput/PhotoOrDocumentFileInput";
import type { FileType } from "~/components/FileInput/utils";
import Modal from "~/components/Modal";
import {
  H3,
  TextSmall,
  Text,
  Bold,
  TextExtraSmall,
} from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useModalContext } from "~/contexts/ModalContext";
import styles from "./index.module.scss";
import SelectableCard from "~/components/SelectableCard";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { useMutation } from "@apollo/react-hooks";
import GridWrapper from "~/components/GridWrapper";
import UploadImg from "~/assets/images/illustration-documentupload.png";

type Props = {
  nextPath?: string;
};

const UPLOAD_DOCUMENT = gql`
  mutation uploadDocument($policyId: String!, $document: DocumentFileInput!) {
    uploadDocument(policyId: $policyId, document: $document)
  }
`;

const DOCUMENT_TEXT: {
  [index: string]: {
    documentType: string;
    documentTitle: string;
    documentHeader: React.ReactNode | string;
  };
} = {
  PURCHASE_LETTER: {
    documentType: "PURCHASE_LETTER",
    documentTitle: "Purchase Letter",
    documentHeader: (
      <>
        Upload a photo of your Purchase Letter. The{" "}
        <Bold>date of purchase</Bold>, <Bold>price paid</Bold> and{" "}
        <Bold>foreign used information</Bold> should all be visible on your
        Purchase Letter photo.
      </>
    ),
  },
  REGISTRATION_CARD: {
    documentType: "REGISTRATION_CARD",
    documentTitle: "Registration Card",
    documentHeader: "Upload a photo of your motor Registration Card.",
  },
  VALUATION_CERTIFICATE: {
    documentType: "VALUATION_CERTIFICATE",
    documentTitle: "Valuation Certificate",
    documentHeader: "Upload a photo of your Vehicle Valuation.",
  },
  ASSIGNMENT_LETTER: {
    documentType: "ASSIGNMENT_LETTER",
    documentTitle: "Assignment Letter",
    documentHeader:
      "Upload a photo of your Assignment Letter. You can get a copy of this from your bank or the institution financing your vehicle.",
  },
  NO_CLAIMS_DISCOUNT_LETTER: {
    documentType: "NO_CLAIMS_DISCOUNT_LETTER",
    documentTitle: "No Claims Discount Letter",
    documentHeader: "Upload a photo of your No Claims Discount Letter.",
  },
};

type ChecklistDocumentPart = {
  contextType: string;
  contextTitle: string;
  uploadedFileName?: string;
  uploaded: boolean;
};

type ChecklistDocument = {
  documentType: string;
  uploadedFileName?: string;
  contexts: ChecklistDocumentPart[];
  uploaded: boolean;
  isOptional?: boolean;
};

type ChecklistDocumentContainer = { [index: string]: ChecklistDocument };

export default function FinalDocumentUpload({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const [uploadDocument] = useMutation(UPLOAD_DOCUMENT);
  const { openModal, setOpenModal } = useModalContext();
  const [documentsUploaded, setDocumentsUploaded] = React.useState(false);

  const [
    selectedDocument,
    setSelectedDocument,
  ] = React.useState<ChecklistDocument>();

  const [
    documentList,
    setDocumentList,
  ] = React.useState<ChecklistDocumentContainer>({});

  React.useEffect(() => {
    const docs: ChecklistDocumentContainer = {};

    checklistCtx.requiredDocuments.forEach((requiredDocument) => {
      const index = requiredDocument.documentType;
      docs[index] = {
        documentType: requiredDocument.documentType,
        contexts: [],
        uploaded: false,
        isOptional: requiredDocument.isOptional,
      };

      if (
        requiredDocument.documentParts &&
        requiredDocument.documentParts.length
      ) {
        requiredDocument.documentParts.forEach((requiredContext) => {
          docs[index].contexts.push({
            ...requiredContext,
            uploaded: false,
          });
        });
      }
    });

    if (checklistCtx.documents && checklistCtx.documents.length) {
      checklistCtx.documents.forEach((savedDocument) => {
        const index = savedDocument.documentType;

        if (docs[index]) {
          if (savedDocument.context) {
            let allContextsUploaded = true;

            for (const context of docs[index].contexts) {
              if (context.contextType === savedDocument.context) {
                context.uploadedFileName = savedDocument.fileName;
                context.uploaded = true;
              } else if (!context.uploaded) {
                allContextsUploaded = false;
              }
            }

            if (allContextsUploaded) {
              docs[index].uploaded = true;
            }
          } else {
            docs[index].uploadedFileName = savedDocument.fileName;
            docs[index].uploaded = true;
          }
        }
      });
    }

    setDocumentList(docs);
  }, [
    checklistCtx.requiredDocuments,
    checklistCtx.documents,
    checklistCtx.isFinanced,
  ]);

  React.useEffect(() => {
    let uploaded = true;

    Object.keys(documentList).forEach((key) => {
      const doc = documentList[key];

      if (!doc.uploaded && !doc.isOptional) {
        uploaded = false;
      }
    });

    setDocumentsUploaded(uploaded);
  }, [documentList]);

  const portalLink = "/portal/";

  const handleDocumentClick = (document: ChecklistDocument) => {
    setSelectedDocument(document);
    setOpenModal("open");
  };

  const getDocumentTitle = (document: ChecklistDocument) => {
    return DOCUMENT_TEXT[document.documentType].documentTitle;
  };

  const getDocumentHeader = (document: ChecklistDocument) => {
    return DOCUMENT_TEXT[document.documentType].documentHeader;
  };

  const handleUpload = async (
    documentType: string,
    fileObject: FileType,
    context?: string
  ) => {
    const { base64Contents, fileName, mimeType } = fileObject;
    await uploadDocument({
      variables: {
        policyId: checklistCtx.policyId,
        document: {
          contents: base64Contents,
          mimeType,
          context,
          documentType,
          fileName,
        },
      },
    });
    // Update checklist held in context in case the user navigates away
    checklistCtx.fetchChecklist(checklistCtx.policyId);
  };

  const handleContinueClick = () => {
    if (nextPath) {
      history.push(nextPath);
    }
  };

  const handleSkipClick = () => {
    history.push(portalLink);
  };

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 6,
      totalOfSteps: 7,
      title: "Final Document Upload",
    });
  });

  return (
    <div className={styles.Content}>
      <H3>Please upload the following documents.</H3>

      <TextSmall>
        Please complete your verification before your coverage start date.
        Document images are sufficient. Scanner not required.
      </TextSmall>

      <div className={styles.DocumentsContainer}>
        {Object.keys(documentList).map((key, i) => {
          const document = documentList[key];

          return (
            <SelectableCard
              key={`FinalDocumentUpload-SelectableCard-${i}`}
              title={DOCUMENT_TEXT[document.documentType].documentTitle}
              description={
                document.uploaded ? "Successfully uploaded" : "Add document"
              }
              ctaText={document.uploaded ? "Change" : undefined}
              selected={document.uploaded}
              onClick={() => handleDocumentClick(document)}
            />
          );
        })}
      </div>

      <Modal
        isOpen={openModal === "open" && !!selectedDocument}
        title={selectedDocument ? getDocumentTitle(selectedDocument) : ""}
        shouldOverlayCloseOnClick
      >
        {selectedDocument && (
          <GridWrapper>
            <div className={styles.ModalContent}>
              <div className={styles.ModalTop}>
                <img
                  src={UploadImg}
                  className={styles.ModalTopIcon}
                  alt="a camera icon"
                />

                <Text>{getDocumentHeader(selectedDocument)}</Text>
              </div>

              <div className={styles.CTAs}>
                <div className={styles.FileInputWrapper}>
                  {/* One file */}
                  {!selectedDocument.contexts.length && (
                    <PhotoOrDocumentFileInput
                      accept="image/*, application/pdf"
                      key="FinalDocumentUpload-FileInput"
                      title={
                        selectedDocument
                          ? getDocumentTitle(selectedDocument)
                          : ""
                      }
                      defaultFacingMode="environment"
                      onFileSelect={async (fileObject) => {
                        await handleUpload(
                          selectedDocument.documentType,
                          fileObject
                        );
                      }}
                      initialFilename={selectedDocument.uploadedFileName}
                      initialUploaded={selectedDocument.uploaded}
                      disabledUpload={false}
                    />
                  )}

                  {/* Multiple Files */}
                  {selectedDocument.contexts.length
                    ? selectedDocument.contexts.map(
                        (contextDocument, index) => {
                          return (
                            <PhotoOrDocumentFileInput
                              accept="image/*, application/pdf"
                              key={`FinalDocumentUpload-FileInput-${index}`}
                              title={contextDocument.contextTitle}
                              defaultFacingMode="environment"
                              onFileSelect={async (fileObject) => {
                                await handleUpload(
                                  selectedDocument.documentType,
                                  fileObject,
                                  contextDocument.contextType
                                );
                              }}
                              initialFilename={contextDocument.uploadedFileName}
                              initialUploaded={contextDocument.uploaded}
                              disabledUpload={false}
                            />
                          );
                        }
                      )
                    : null}

                  <TextExtraSmall className={styles.FileTypeMessage}>
                    Files must be .pdf, .jpg, .jpeg, or .png format under 10MB
                    in size.
                  </TextExtraSmall>
                </div>
              </div>

              <div className={styles.ModalButtonsRow}>
                <div className={styles.ButtonsWrapper}>
                  <Button
                    type="button"
                    onClick={() => {
                      setOpenModal(undefined);
                      setSelectedDocument(undefined);
                    }}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </div>
          </GridWrapper>
        )}
      </Modal>

      <div className={styles.ButtonsRow}>
        <div className={styles.ButtonsWrapper}>
          <Button
            type="button"
            text={!documentsUploaded}
            variant={!documentsUploaded ? "textSmall" : undefined}
            onClick={documentsUploaded ? handleContinueClick : handleSkipClick}
          >
            {documentsUploaded ? "Continue" : "Save and continue later"}
          </Button>
        </div>
      </div>
    </div>
  );
}
