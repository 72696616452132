import React from "react";
import gql from "graphql-tag";
import jwtDecode from "jwt-decode";
import { useApolloClient } from "@apollo/react-hooks";
import { useHistory, matchPath } from "react-router-dom";

import { useAuth0Context } from "~/contexts/Auth0Context";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import { useUserContext } from "~/contexts/UserProvider";

import Button from "~/components/Button";
import HomePlanDetail from "~/components/PlanDetail/HomePlanDetail";
import Icon from "~/components/Icon";
import LoadingWrapper from "~/components/LoadingWrapper";
import PolicySummaryCard from "~/components/PolicySummaryCard";
import { TextSmall, H3 } from "~/components/Typography";

import formHomePolicyPayload from "~/helpers/formHomePolicyPayload";
import pushWithParams from "~/helpers/pushWithParams";
import useHasRequiredData from "~/helpers/useHasRequiredData";
import { DEFAULT_ALL_RISK_TYPE, POLICY_STATUS } from "~/helpers/constants";
import { useQueryThrowError } from "~/helpers/queryWithErrorHandling";

import { CountryCode, InsuranceType, PolicyStatus } from "../../../types";

import styles from "./index.module.scss";

export type PathMatchType = {
  params: { countryCode: CountryCode; insuranceType: InsuranceType };
};

const TextWithCheck = ({ children }: { children: React.ReactNode }) => (
  <TextSmall className={styles.ListItem}>
    <Icon
      name="CheckRound"
      backgroundColor="transparent"
      fill={styles.chateauGreen}
    />
    {children}
  </TextSmall>
);

const NUMBER_OF_PAYMENTS = {
  twelve: 12,
  one: 1,
};

const HOME_QUOTE = gql`
  query($proposal: HomePolicyProposalInput!) {
    homeQuote(proposal: $proposal) {
      typeOfCover
      basePremiumValue
      netPremiumValue
      taxValue
      totalValue
      valueByNumberOfInstallments {
        twelve
      }
    }
  }
`;

export const SAVE_POLICY = gql`
  mutation SaveHomePolicy($saveHomePolicyInput: SaveHomePolicyInput!) {
    saveHomePolicy(input: $saveHomePolicyInput)
  }
`;

const CoveragePlan = ({
  nextPath,
  countryName,
}: {
  nextPath?: string;
  countryName: string;
}) => {
  const onboardCtx = useOnboardContext();
  const userCtx = useUserContext();
  const homeCtx = useHomeContext();
  const apolloClient = useApolloClient();
  const history = useHistory();
  const auth0 = useAuth0Context();
  const [, setError] = React.useState();
  const [showDetail, setShowDetail] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [complete, setComplete] = React.useState(false);

  useHasRequiredData({
    requiredData: [],
    policyRequiredStatus: POLICY_STATUS.quote as PolicyStatus,
    policyStatus: homeCtx.status,
    skip: complete,
  });

  const allRiskValue =
    homeCtx.contents
      ?.filter((insuredItem) => insuredItem.isAllRisk)
      .map((insuredItem) => insuredItem.value)
      .reduce((total, itemValue) => total + itemValue, 0) ?? 0;

  const proposal = {
    allRiskType: homeCtx.allRiskType ?? DEFAULT_ALL_RISK_TYPE,
    allRiskValue,
    branch: "BB",
    constructionType: homeCtx.constructionType,
    contentsValue: homeCtx.contentsValue
      ? homeCtx.contentsValue - allRiskValue
      : 0,
    coverageStart: homeCtx.coverageStart,
    homeValue: homeCtx.replacementValue,
    isCoastal: homeCtx.isCoastal,
  };

  const { loading, data } = useQueryThrowError(HOME_QUOTE, {
    variables: {
      emailQuoteInput: {
        email: userCtx.email,
        policyId: homeCtx.policyId,
      },
      proposal,
    },
  });

  const quote = data?.homeQuote[0];

  // `quote` is only undefined when data is undefined, i.e. when loading === true
  if (loading || showLoading || quote === undefined)
    return <LoadingWrapper loading={loading} />;

  const isRecurring = onboardCtx.paymentIsRecurring; // i.e. is it monthly?
  const installments = onboardCtx.paymentNumberOfRecurrences ?? 1; // 12 or 1
  const additionalContents = !!homeCtx.contents; // Items in the home are being insured
  const allRisk = homeCtx.allRisk;
  const subTitle = `Property ${
    additionalContents ? "+ Contents " : ""
  }insurance`;

  const handleOnSelectedPayment = (isRecurring: boolean) => {
    onboardCtx.setState({
      paymentIsRecurring: isRecurring,
      // TODO: we need to better handle the number of payments
      paymentNumberOfRecurrences: isRecurring
        ? NUMBER_OF_PAYMENTS.twelve
        : NUMBER_OF_PAYMENTS.one,
    });
  };
  const installmentValue = isRecurring
    ? quote.valueByNumberOfInstallments.twelve
    : quote.totalValue;

  const pathMatch: PathMatchType | null = matchPath(history.location.pathname, {
    path: "/:countryCode/:insuranceType/:step",
    exact: true,
    strict: false,
  });

  const handleOnSelectPlan = async () => {
    setShowLoading(true);
    setShowDetail(false);

    if (pathMatch) {
      const policyPayload = formHomePolicyPayload(
        homeCtx,
        userCtx.email,
        !!isRecurring,
        pathMatch.params.countryCode,
        false
      );

      try {
        const policyMutation = await apolloClient.mutate({
          mutation: SAVE_POLICY,
          variables: {
            saveHomePolicyInput: {
              policy: policyPayload,
            },
          },
        });
        if (policyMutation.data.saveHomePolicy) {
          if (!auth0.isLoading()) {
            if (auth0.token) {
              const tokenObj: { email: string } = jwtDecode(
                auth0.token.idToken
              );

              if (userCtx.email === tokenObj.email) {
                if (pathMatch) {
                  setComplete(true);

                  homeCtx.setState({
                    policyId: policyMutation.data.saveHomePolicy,
                    status: POLICY_STATUS.pendingDocuments as PolicyStatus,
                  });

                  pushWithParams(
                    history,
                    `/${pathMatch.params.countryCode}/${pathMatch.params.insuranceType}/documents`
                  );
                  return;
                }
              }
            } else {
              setComplete(true);
              homeCtx.setState({
                policyId: policyMutation.data.saveHomePolicy,
                status: POLICY_STATUS.pendingDocuments as PolicyStatus,
              });
            }
          }
          if (nextPath) {
            history.push(nextPath);
          }
        }
      } catch (error) {
        // Make error boundary catch this
        setError(() => {
          throw error;
        });
        setShowLoading(false);
      }
      if (nextPath) history.push(nextPath);
    }
  };

  return (
    <>
      <div className={styles.CoveragePlansContent}>
        <div className={styles.HeadingScreen}>
          <TextSmall component="h1">Here is the plan for your</TextSmall>
          <H3 className={styles.HomeCoverage} component="h2">
            Home Coverage
          </H3>
        </div>

        <div
          className={styles.SelectorButtons}
          role="tablist"
          aria-label="Coverage Plans"
        >
          <Button
            role="tab"
            aria-selected={isRecurring}
            aria-controls="instalment-content"
            className={styles.ButtonPayment}
            variant={isRecurring ? "secondary" : "tertiary"}
            size="small"
            onClick={() => handleOnSelectedPayment(true)}
          >
            Instalments
          </Button>

          <Button
            role="tab"
            aria-selected={!isRecurring}
            aria-controls="annual-content"
            className={styles.ButtonPayment}
            variant={!isRecurring ? "secondary" : "tertiary"}
            size="small"
            onClick={() => handleOnSelectedPayment(false)}
          >
            One payment
          </Button>
        </div>

        <div
          className={styles.CoveragePlanCard}
          id={isRecurring ? "instalment-content" : "annual-content"}
        >
          <div className={styles.CoveragePlanContent}>
            <PolicySummaryCard
              variant="highlight"
              showTopHeader={false}
              showBorder={false}
              title="Home Coverage Plan"
              subTitle={subTitle}
              installmentCount={isRecurring ? installments : 1}
              installmentValue={installmentValue}
              onClickDetails={() => setShowDetail(true)}
            >
              <TextWithCheck>Natural Disaster Protection</TextWithCheck>
              {additionalContents && homeCtx.contentsValue && (
                <TextWithCheck>Contents Coverage</TextWithCheck>
              )}
              {allRisk && proposal.allRiskValue && (
                <TextWithCheck>All Risks Coverage</TextWithCheck>
              )}
              <TextWithCheck>Theft and Burglary</TextWithCheck>
              <TextWithCheck>Emergency Expenses</TextWithCheck>
              <TextWithCheck>Accidental Damage</TextWithCheck>
            </PolicySummaryCard>
          </div>
        </div>
      </div>
      <HomePlanDetail
        quote={quote}
        allRisk={allRisk}
        allRiskType={homeCtx.allRiskType}
        allRiskValue={proposal.allRiskValue}
        installments={installments}
        additionalContents={additionalContents}
        countryName={countryName}
        isRecurring={isRecurring}
        replacementValue={homeCtx.replacementValue}
        contentsValue={homeCtx.contentsValue}
        isOpen={showDetail}
        onCloseModal={() => setShowDetail(false)}
        onSelectPlan={handleOnSelectPlan}
      />
    </>
  );
};

export default CoveragePlan;
