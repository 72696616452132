import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { useMotorContext } from "~/contexts/MotorProvider";
import { UserContext } from "~/contexts/UserProvider";
import { isMobile } from "react-device-detect";
import { Text, H3 } from "~/components/Typography";
import Button from "~/components/Button";
import NoteWithList from "~/components/NoteWithList";
import styles from "./index.module.scss";
import { InsuranceType } from "../../../types";
import MobileUploadRequired from "~/components/MobileUploadRequired";
import { useHomeContext } from "~/contexts/HomeProvider";
import { HeapEventName, useHeapContext } from "~/contexts/HeapProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";

type Props = {
  nextPath?: string;
  insuranceType: InsuranceType;
};

const AccountCreated = ({ nextPath, insuranceType }: Props) => {
  const motorCtx = useMotorContext();
  const homeCtx = useHomeContext();
  const userCtx = useContext(UserContext);
  const heapCtx = useHeapContext();
  const history = useHistory();

  useMountEffect(() => {
    if (userCtx.email) {
      const eventName = isMobile
        ? HeapEventName.ONBOARDING_ACCOUNT_CREATION_MOBILE
        : HeapEventName.ONBOARDING_ACCOUNT_CREATION_DESKTOP;

      heapCtx.track(eventName, {
        "Policy ID":
          insuranceType === "motor"
            ? motorCtx.policyInfo.data.id
            : homeCtx.policyId,
      });
    }
  });

  const handleClick = () => {
    if (nextPath) {
      heapCtx.track(HeapEventName.ONBOARDING_CREATE_ACCOUNT_SUCCESS, {
        "Policy ID":
          insuranceType === "motor"
            ? motorCtx.policyInfo.data.id
            : homeCtx.policyId,
      });

      history.push(nextPath);
    }
  };

  if (!isMobile && userCtx.email) {
    return (
      <MobileUploadRequired
        title="Great! Your account has been created.
  To complete the process and get your coverage plan, you will need a smartphone."
        email={userCtx.email}
        policyId={
          insuranceType === "motor"
            ? motorCtx.policyInfo.data.id || ""
            : homeCtx.policyId || ""
        }
      />
    );
  }

  return (
    <>
      <Text>{`Welcome to Almi, ${userCtx.name}`}</Text>
      <H3 className={styles.Subtitle} component="h1">
        Your account has been created.
      </H3>
      <div className={styles.InputWrapper}>
        <NoteWithList
          className={styles.Note}
          mainText="To verify your identity, you’ll be asked to take a selfie, and upload a photo of the following documents:"
          list={
            insuranceType === "motor"
              ? [
                  "Photo of your driver’s license or driver’s license slip if expired",
                  "Photo of your national ID or passport",
                  "Proof of address (e.g. a utility bill or bank statement with your address)",
                  "Pro forma invoice or sales letter",
                ]
              : [
                  "Photo of your national ID or passport",
                  "Proof of address (e.g. a utility bill or bank statement with your address)",
                ]
          }
        />
      </div>
      <div className={styles.ButtonWrapper}>
        <Button
          className={styles.Button}
          type="button"
          onClick={handleClick}
          autoFocus
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default AccountCreated;
