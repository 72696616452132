import React, { useState, useEffect } from "react";
import cx from "classnames";
import { RadioGroup, Form, FormValues } from "informed";
import GridWrapper from "~/components/GridWrapper";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import Modal from "~/components/Modal";
import RadioSelector from "~/components/RadioSelector";

import { Text } from "~/components/Typography";
import {
  previousClaimDate,
  required,
  validateNotZero,
} from "~/helpers/validators";

import styles from "./index.module.scss";
import { localDateFromIso } from "~/helpers/dates";
import { format } from "date-fns";

type Props = {
  onSave: (formValues: FormValues) => void;
  modal: string;
  openModal: string | undefined;
  closeModal: () => void;
  onRemove?: () => void;
  initialValues?: FormValues;
  options?: { nth: number };
};

const ClaimModal = ({
  openModal,
  modal,
  onSave,
  closeModal,
  onRemove,
  initialValues,
  options,
}: Props) => {
  const [savedValues, setSavedValues] = useState<FormValues>({});

  useEffect(() => {
    const values = {
      claimDate: initialValues?.date
        ? format(localDateFromIso(initialValues?.date as string), "dd/MM/yyyy")
        : "",
      claimAmount: initialValues?.amount.toString() || "",
      claimFault: initialValues?.atFault ? "faultYes" : "faultNo",
      claimFinalized: initialValues?.isFinalized ? "finalYes" : "finalNo",
    };

    setSavedValues(initialValues ? values : {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <Modal
      isOpen={openModal === modal}
      title="Add claim"
      shouldOverlayCloseOnClick
      onClose={closeModal}
      id="claim-details-modal"
    >
      <GridWrapper>
        <Form initialValues={savedValues}>
          {({ formState }) => {
            return (
              <>
                <div
                  className={cx(styles.InputWrapper, {
                    [styles.Show]: true,
                  })}
                >
                  <Text>What was the date of the claim?</Text>
                  <TextInput
                    label="What was the date of the claim?"
                    hideLabelFromView
                    field="claimDate"
                    placeholder="DD / MM / YYYY"
                    type="date"
                    maintainCursor
                    validate={previousClaimDate}
                    validateOnMount
                    validateOnChange
                    keepState
                  />
                </div>
                <div
                  className={cx(styles.InputWrapper, {
                    [styles.Show]:
                      formState.values.claimDate &&
                      ((formState.touched.claimDate &&
                        !formState.errors.claimDate) ||
                        !formState.touched.claimDate),
                  })}
                >
                  <Text>Enter claim&apos;s value</Text>
                  <TextInput
                    label="Enter claim's value"
                    hideLabelFromView
                    field="claimAmount"
                    placeholder="$ 0"
                    type="currency"
                    keepState
                    validate={validateNotZero}
                    validateOnBlur
                    validateOnChange
                    validateOnMount
                    inputMode="numeric"
                    initialValue={initialValues?.amount.toString()}
                  />
                </div>
                <fieldset
                  className={cx(styles.InputWrapper, {
                    [styles.Show]:
                      formState.values.claimAmount &&
                      formState.values.claimAmount !== "$ " &&
                      !formState.errors.claimAmount,
                  })}
                >
                  <legend>
                    <Text>
                      {options
                        ? `Who was at fault for the ${
                            options.nth === 1 ? "first" : "second"
                          } claim?`
                        : ""}
                    </Text>
                  </legend>
                  <div className={styles.Radios}>
                    <RadioGroup
                      field="claimFault"
                      keepState
                      validate={required("Please inform who was at fault")}
                      validateOnBlur
                      validateOnChange
                      validateOnMount
                    >
                      <RadioSelector
                        small
                        key="faultYes"
                        field="claimFault"
                        value="faultYes"
                        // checked={state.claimFault.value === "faultYes"}
                      >
                        Me
                      </RadioSelector>
                      <RadioSelector
                        small
                        key="faultNo"
                        field="claimFault"
                        value="faultNo"
                        // checked={state.claimFault.value === "faultNo"}
                      >
                        Not me
                      </RadioSelector>
                    </RadioGroup>
                  </div>
                </fieldset>
                <fieldset
                  className={cx(styles.InputWrapper, {
                    [styles.Show]:
                      formState.values.claimFault &&
                      !formState.errors.claimFault,
                  })}
                >
                  <legend>
                    <Text>Has the claim been finalized?</Text>
                  </legend>
                  <div className={styles.Radios}>
                    <RadioGroup
                      field="claimFinalized"
                      keepState
                      validate={required("Please inform if the claim is open")}
                      validateOnBlur
                      validateOnChange
                      validateOnMount
                    >
                      <RadioSelector
                        small
                        key="finalYes"
                        field="claimFinalized"
                        value="finalYes"
                      >
                        Yes
                      </RadioSelector>
                      <RadioSelector
                        small
                        key="finalNo"
                        field="claimFinalized"
                        value="finalNo"
                      >
                        No, it&apos;s still open
                      </RadioSelector>
                    </RadioGroup>
                  </div>
                </fieldset>
                <div className={styles.ButtonsRow}>
                  <div className={styles.ButtonsWrapper}>
                    <Button
                      onClick={() => {
                        formState.values.claimAmount = formState.values.claimAmount
                          .toString()
                          .replace(/[^\d]/g, "");
                        onSave(formState.values);
                      }}
                      disabled={formState.invalid || formState.pristine}
                      type="submit"
                    >
                      Save
                    </Button>
                    <br />
                    {onRemove && (
                      <Button
                        className={styles.RemoveButton}
                        onClick={onRemove}
                        text
                        variant="textSmall"
                      >
                        Remove Claim
                      </Button>
                    )}
                  </div>
                </div>
              </>
            );
          }}
        </Form>
      </GridWrapper>
    </Modal>
  );
};

export default ClaimModal;
