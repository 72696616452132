import React from "react";
import classnames from "classnames";
import { CountryCode, InsuranceType } from "../../../../types";

import styles from "./index.module.scss";

import { TextSmall } from "~/components/Typography";

import Icon from "~/components/Icon";

export type PathMatchType = {
  params: { countryCode: CountryCode; insuranceType: InsuranceType };
};
import { Quote } from "~/contexts/MotorProvider/PolicyInfo";

export default function CoveredList({ quote }: { quote: Quote }) {
  const type = quote.typeOfCover;
  return (
    <>
      <TextSmall className={styles.ListItem}>
        <Icon name="CheckRound" backgroundColor="transparent" fill="#31AD63" />
        {`$${quote.coverage
          .find((item) => item.peril === "third-party-property")
          ?.coveredValue?.toLocaleString()} Third Party Coverage`}
      </TextSmall>
      <TextSmall
        className={classnames(styles.ListItem, {
          [styles.ItemDisabled]: type === "T",
        })}
      >
        <Icon
          name={type === "T" ? "Close" : "CheckRound"}
          backgroundColor="transparent"
          fill={type === "T" ? "#91A29C" : "#31AD63"}
        />
        Comprehensive coverage
      </TextSmall>
      <TextSmall className={styles.ListItem}>
        <Icon name="CheckRound" backgroundColor="transparent" fill="#31AD63" />
        24/7 Emergency Assistance
      </TextSmall>
      <TextSmall
        className={classnames(styles.ListItem, {
          [styles.ItemDisabled]: type === "T",
        })}
      >
        <Icon
          name={type === "T" ? "Close" : "CheckRound"}
          backgroundColor="transparent"
          fill={type === "T" ? "#91A29C" : "#31AD63"}
        />
        $1,000 deductible
      </TextSmall>
      <TextSmall
        className={classnames(styles.ListItem, {
          [styles.ItemDisabled]: type === "T",
        })}
      >
        <Icon
          name={type === "T" ? "Close" : "CheckRound"}
          backgroundColor="transparent"
          fill={type === "T" ? "#91A29C" : "#31AD63"}
        />
        Windscreen protection
      </TextSmall>
      <TextSmall
        className={classnames(styles.ListItem, {
          [styles.ItemDisabled]: type === "T",
        })}
      >
        <Icon
          name={type === "T" || type === "L" ? "Close" : "CheckRound"}
          backgroundColor="transparent"
          fill={type === "T" || type === "L" ? "#91A29C" : "#31AD63"}
        />
        Courtesy Vehicle
      </TextSmall>
    </>
  );
}
