import React from "react";
import CoverageList from "~/components/CoverageList";
import { Quote } from "~/contexts/MotorProvider/PolicyInfo";
import { formatCurrency } from "~/helpers/currency";
import type { SummaryValue } from "../index";
import PlanDetail from "../index";
import { IParsedDetails, parseQuoteDetails } from "./index.utils";

type Props = {
  isOpen: boolean;
  quote?: Quote;
  installmentCount: number;
  installmentValue: number;
  vehicleValue?: number;
  showContinue?: boolean;
  onSelectPlan?: () => void;
  onCloseModal: () => void;
  onPrev?: () => void;
  onNext?: () => void;
};

const THIRD_PARTY_EXCESS = 1000;

const MotorPlanDetail = (props: Props) => {
  const {
    isOpen,
    quote,
    installmentCount,
    installmentValue,
    vehicleValue,
    showContinue,
    onSelectPlan,
    onCloseModal,
    onPrev,
    onNext,
  } = props;
  if (!quote) return null;
  // parseQuoteDetails can return {}, so Typescript complains without the assertion
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const info = parseQuoteDetails(quote) as IParsedDetails;
  const summaryValues: SummaryValue[] = [];

  if (info.thirdLiability)
    summaryValues.push({
      title: "Third party coverage",
      value: `$${info.thirdLiability}`,
      excess:
        quote.typeOfCover === "L" || quote.typeOfCover === "C"
          ? formatCurrency(THIRD_PARTY_EXCESS, true)
          : undefined,
    });
  if ((quote.typeOfCover === "L" || quote.typeOfCover === "C") && vehicleValue)
    summaryValues.push({
      title: "Vehicle value insured",
      value: `${formatCurrency(vehicleValue, true)}`,
    });

  return (
    <PlanDetail
      title={info.title}
      subTitle={info.subTitle}
      summary={
        <CoverageList
          typeOfCover={quote.typeOfCover}
          coverage={quote.coverage}
          itemFilterPredicate={(item) => !item.types.includes("S")}
          showNotCovered
        />
      }
      summaryTitle="Quick Overview"
      summaryValues={summaryValues}
      coveredContent={info.covered}
      notCoveredContent={info.notCovered}
      installmentCount={installmentCount}
      installmentValue={installmentValue}
      isOpen={isOpen}
      showContinue={showContinue}
      onSelectPlan={onSelectPlan}
      onCloseModal={onCloseModal}
      onPrev={onPrev}
      onNext={onNext}
    />
  );
};

export default MotorPlanDetail;
