import { format } from "date-fns";
import React from "react";
import { useHistory } from "react-router-dom";
import Button from "~/components/Button";
import NoteWithList from "~/components/NoteWithList";
import { Bold, H3, Text } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import styles from "./style.module.scss";
import { localDateFromIso } from "~/helpers/dates";
import { InsuranceType } from "../../../../../types";

type Props = {
  insuranceType: InsuranceType;
  nextPath?: string;
};

export default function ChecklistStarted({ insuranceType, nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const [noteList, setNoteList] = React.useState<string[]>([]);

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 1,
      totalOfSteps: 6,
      title: "Finish Onboarding",
    });
  });

  const policyId = checklistCtx.policyId;
  const dueDate = format(localDateFromIso(checklistCtx.dueDate), "MMM d, yyyy");

  const onContinueClick = () => {
    if (nextPath) {
      history.push(nextPath);
    }
  };

  React.useEffect(() => {
    if (insuranceType === "motor") {
      const list: string[] = [
        `Your motor purchase letter`,
        `Your motor registration card`,
        `Your vehicle valuation`,
        `Your assignment letter (if your vehicle is financed)`,
        `No claims letter or renewal notice (optional)`,
      ];

      let noClaims = false;

      checklistCtx.requiredDocuments.forEach((document) => {
        if (document.documentType === "NO_CLAIMS_DISCOUNT_LETTER") {
          noClaims = true;
        }
      });

      if (noClaims) {
        list.push(`Your NCD Letter`);
      }

      setNoteList(list);
    } else if (insuranceType === "home") {
      setNoteList([
        `A recent home valuation of your property from an appraiser`,
      ]);
    }
  }, [checklistCtx.requiredDocuments, insuranceType]);

  return (
    <>
      <Text>
        Finish onboarding for <Bold>Policy {policyId}</Bold>
      </Text>

      <H3>Just a quick note before you continue...</H3>

      <NoteWithList
        className={styles.Note}
        list={noteList}
        mainText={
          <Text>
            To issue your full policy certificate we need a bit more
            information.{" "}
            <Bold>Please provide this information by {dueDate}.</Bold> Here’s
            {insuranceType === "motor"
              ? " a few documents"
              : " the document"}{" "}
            you’ll need handy to complete the process:
          </Text>
        }
      />
      <Button className={styles.ContinueButton} onClick={onContinueClick}>
        Ok, got it!
      </Button>
    </>
  );
}
