export function floatToCurrency(value: number | string) {
  const fractionDigits = 2;
  return parseFloat(value.toString()).toFixed(fractionDigits);
}

export function formatCurrency(
  value: number,
  dropZeroes = false,
  prefix = false,
  currencyCode: "BBD" | "GYD" = "BBD",
  round = true
) {
  const currencies = [
    {
      currency: "BBD",
      locale: "en-BB",
    },
    {
      currency: "GYD",
      locale: "en-GY",
    },
  ];
  const roundedValue = round ? floatToCurrency(value) : value;
  const currency = currencies.find(({ currency }) => currencyCode === currency);
  if (!currency) throw new Error("Unknown currency code");
  const formatted = new Intl.NumberFormat(currency.locale, {
    style: "currency",
    currency: currencyCode,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(Number(roundedValue));
  const stripped = dropZeroes ? formatted.replace(/\.\d{2}$/, "") : formatted;

  return prefix ? `${currencyCode}${stripped}` : stripped;
}
