/* eslint-disable no-magic-numbers */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useUserContext } from "~/contexts/UserProvider";
import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";
import { Text, H3 } from "~/components/Typography";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import { required } from "~/helpers/validators";
import pushWithParams from "~/helpers/pushWithParams";
import styles from "./index.module.scss";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useMotorContext } from "~/contexts/MotorProvider";

const AccountName = ({ nextPath }: { nextPath?: string }) => {
  const userCtx = useUserContext();
  const assistantCtx = useAssistantContext();
  const history = useHistory();
  const fieldName = "name";
  const { value } = useCustomFieldState(fieldName);
  const heapCtx = useHeapContext();
  const motorCtx = useMotorContext();

  useEffect(() => {
    if (assistantCtx.isOpen) {
      assistantCtx.updateAssistant({
        isOpen: false,
      });
    }
  }, [assistantCtx]);

  const handleClick = async () => {
    try {
      if (typeof value === "string") {
        await userCtx.setState({ name: value });

        heapCtx.track(HeapEventName.ONBOARDING_NAME, {
          "Policy ID": motorCtx.policyInfo.data.id, //TODO check homeCtx too
        });

        if (nextPath) {
          pushWithParams(history, nextPath);
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };
  const label = "What would you like us to call you?";
  return (
    <>
      <Text>Let&apos;s create your account!</Text>
      <H3 className={styles.Subtitle} component="h1">
        {label}
      </H3>
      <div className={styles.InputWrapper}>
        <TextInput
          label={label}
          hideLabelFromView
          field={fieldName}
          placeholder="Name"
          initialValue={userCtx.name?.toString()}
          keepState
          validate={required("Please enter your name")}
          validateOnChange
          validateOnBlur
          validateOnMount
          autoComplete="name"
          autoFocus
        />
      </div>
      <div className={styles.ButtonWrapper}>
        <Button disabled={!value} type="button" onClick={handleClick}>
          Next
        </Button>
      </div>
    </>
  );
};

export default AccountName;
