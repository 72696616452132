import { useFormState } from "informed";
import React from "react";
import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import { Text } from "~/components/Typography";
import { formatIsoDate, formatUkDate } from "~/helpers/dates";
import {
  ageValidator,
  required,
  validateLength,
  validateIssueDateOfLicense,
  MIN_DL_LENGTH,
} from "~/helpers/validators";
import { ChecklistAdditionalDriver } from "..";
import styles from "./style.module.scss";

type Props = {
  additionalDriver: ChecklistAdditionalDriver;
  handleSave: (savedDriver: ChecklistAdditionalDriver) => void;
};

const LICENSE_TYPES: Array<{
  name: string;
  value: string;
}> = [
  {
    name: "Class A",
    value: "A",
  },
  {
    name: "Class B",
    value: "B",
  },
  {
    name: "Class C",
    value: "C",
  },
  {
    name: "Class D",
    value: "D",
  },
  {
    name: "Class DJ",
    value: "DJ",
  },
  {
    name: "Class E",
    value: "E",
  },
];

enum FieldNames {
  Name = "AdditionalDriverName",
  Age = "AdditionalDriverAge",
  YearsOfExperience = "AdditionalDriverYearsOfExperience",
  Occupation = "AdditionalDriverOccupation",
  LicenseNumber = "AdditionalDriverLicenseNumber",
  LicenseDateOfIssue = "AdditionalDriverDateOfIssue",
}

export default function AdditionalDriverModal({
  additionalDriver,
  handleSave,
}: Props) {
  const [
    selectedLicenseType,
    setSelectedLicenseType,
  ] = React.useState<string>();
  const formState = useFormState();

  React.useEffect(() => {
    if (additionalDriver.licenseType) {
      setSelectedLicenseType(additionalDriver.licenseType);
    }
  }, [additionalDriver.licenseType]);

  const handleSaveClick = () => {
    const saveDriver: ChecklistAdditionalDriver = {
      index: additionalDriver.index,
      name: String(formState.values[FieldNames.Name]) ?? "",
      age: Number(formState.values[FieldNames.Age]) ?? 0,
      yearsDrivingExperience:
        Number(formState.values[FieldNames.YearsOfExperience]) ?? 0,
      occupation: String(formState.values[FieldNames.Occupation]) ?? "",
      licenseNumber: String(formState.values[FieldNames.LicenseNumber]) ?? "",
      licenseDateOfIssue: formState.values[FieldNames.LicenseDateOfIssue]
        ? formatUkDate(String(formState.values[FieldNames.LicenseDateOfIssue]))
        : "",
      licenseType: selectedLicenseType,
      completed: true,
    };

    handleSave(saveDriver);
  };

  return (
    <div className={styles.Container}>
      <div className={styles.InputContainer}>
        <div className={styles.InputWrapper}>
          <Text>Driver&apos;s Name</Text>

          <TextInput
            field={FieldNames.Name}
            placeholder="Name"
            type="text"
            validate={required("Name is required")}
            validateOnBlur
            validateOnChange
            validateOnMount
            initialValue={additionalDriver.name ?? ""}
          />
        </div>

        <div className={styles.InputWrapper}>
          <Text>Driver&apos;s age</Text>

          <TextInput
            field={FieldNames.Age}
            type="number"
            size={3}
            placeholder="00"
            validate={ageValidator}
            validateOnBlur
            validateOnChange
            validateOnMount
            initialValue={String(additionalDriver.age) ?? ""}
            inputMode="numeric"
          />
        </div>

        <div className={styles.InputWrapper}>
          <Text>Years of driving experience</Text>

          <TextInput
            field={FieldNames.YearsOfExperience}
            type="number"
            size={3}
            placeholder="00"
            validate={required("Years driving experience is required")}
            validateOnBlur
            validateOnChange
            validateOnMount
            initialValue={String(additionalDriver.yearsDrivingExperience) ?? ""}
            inputMode="numeric"
          />
        </div>

        <div className={styles.InputWrapper}>
          <Text>Occupation</Text>

          <TextInput
            field={FieldNames.Occupation}
            placeholder="Occupation"
            type="text"
            validate={required("Occupation is required")}
            validateOnBlur
            validateOnChange
            validateOnMount
            initialValue={String(additionalDriver.occupation) ?? ""}
          />
        </div>

        <div className={styles.InputWrapper}>
          <Text>DNL (Driver License Number)</Text>

          <TextInput
            field={FieldNames.LicenseNumber}
            placeholder="123456789"
            type="text"
            validate={validateLength(MIN_DL_LENGTH, `Driver License No.`)}
            validateOnBlur
            validateOnChange
            validateOnMount
            initialValue={String(additionalDriver.licenseNumber) ?? ""}
          />
        </div>

        <div className={styles.InputWrapper}>
          <Text>Driver license date of issue</Text>

          <TextInput
            field={FieldNames.LicenseDateOfIssue}
            placeholder={"DD / MM / YYYY"}
            type="date"
            maintainCursor
            validate={validateIssueDateOfLicense}
            validateOnBlur
            validateOnChange
            validateOnMount
            initialValue={
              additionalDriver.licenseDateOfIssue
                ? formatIsoDate(additionalDriver.licenseDateOfIssue)
                : undefined
            }
          />
        </div>
      </div>

      <div className={styles.LicenseTypeWrapper}>
        <Text>Driver license type</Text>

        <div className={styles.LicenseTypeButtons}>
          {LICENSE_TYPES.map((licenseType) => {
            return (
              <Button
                key={`LicenseTypeButton-${licenseType.name}`}
                className={
                  licenseType.value === selectedLicenseType
                    ? styles.LicenseTypeSelected
                    : ""
                }
                onClick={() => {
                  setSelectedLicenseType(licenseType.value);
                }}
              >
                {licenseType.name}
              </Button>
            );
          })}
        </div>
      </div>

      <Button
        className={styles.ContinueButton}
        disabled={formState.invalid || !selectedLicenseType}
        onClick={handleSaveClick}
      >
        Save
      </Button>
    </div>
  );
}
