import React from "react";
import cx from "classnames";
import ReactModal from "react-modal";
import { useModalContext } from "~/contexts/ModalContext";
import ButtonIcon from "~/components/ButtonIcon";
import { H4 } from "~/components/Typography";

import styles from "./index.module.scss";
import "./animations.scss";

type PropsType = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  overlayClassName?: string;
  contentClassName?: string;
  className?: string;
  title?: string;
  renderHeader?: React.ReactNode;
  noOffset?: boolean;
  hasDescription?: boolean;
};

type BaseModalPropsType = PropsType & {
  isOpen?: boolean;
  id?: string;
  shouldOverlayCloseOnClick: boolean;
};

ReactModal.setAppElement("#root");
ReactModal.defaultStyles.content = {};

/*
  Modals:
  ReactModal uses the portal functionality of React to render the component at the root of the DOM,
  outside of the scope of React. It isn't rendered in semantic order. To implement a modal, just
  wrap your content with the <Modal> element at the bottom of your page component, or in some other
  component. Open/Closed state is managed in the ModalContextProvider. The className prop is passed
  to the Overlay of the modal, allowing you to position it wherever you want and control the margin.
*/

const Modal = ({
  children,
  className,
  overlayClassName,
  contentClassName,
  onClose,
  title,
  renderHeader,
  shouldOverlayCloseOnClick,
  noOffset = false,
  hasDescription,
  id,
  ...rest
}: BaseModalPropsType) => {
  const { setOpenModal } = useModalContext();

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    if (shouldOverlayCloseOnClick) {
      setOpenModal(undefined);
    }
  };

  return (
    <ReactModal
      id={id}
      closeTimeoutMS={300}
      className={cx(styles.Modal, className, noOffset && "NoOffset")}
      overlayClassName={cx(styles.Overlay, overlayClassName)}
      bodyOpenClassName={styles.Open}
      shouldCloseOnOverlayClick={shouldOverlayCloseOnClick}
      onRequestClose={handleOnClose}
      aria={{
        labelledby: `modal-title-${id}`,
        describedby: hasDescription ? `modal-desc-${id}` : undefined,
      }}
      {...rest}
    >
      {!renderHeader ? (
        <div className={styles.HeaderWrapper}>
          <H4 className={styles.ModalTitle}>
            <span id={`modal-title-${id}`}>{title}</span>
          </H4>
          <ButtonIcon
            iconName="ArrowDown"
            className={styles.ArrowDown}
            onClick={handleOnClose}
            ariaLabel="close modal"
          />
        </div>
      ) : (
        renderHeader
      )}

      <div className={cx(styles.Content, contentClassName)}>{children}</div>
    </ReactModal>
  );
};

export default Modal;
