import React from "react";
import { useFormState } from "informed";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";

import { Text, H3, Bold, TextSmall } from "~/components/Typography";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";

import { useMotorContext } from "~/contexts/MotorProvider";
import { NO_COVERAGE_URL } from "~/routes/index.constant";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import pushWithParams from "~/helpers/pushWithParams";
import {
  MIN_ALLOWED_AGE,
  MAX_ALLOWED_AGE,
  required,
} from "~/helpers/validators";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";

type Props = {
  nextPath?: string;
};

export default function HowOldAreYou(props: Props) {
  const { nextPath } = props;
  const history = useHistory();
  const motorCtx = useMotorContext();
  const formState = useFormState();
  const field = useCustomFieldState("age");
  const heapCtx = useHeapContext();

  const {
    values: { age },
  } = formState;

  useUpdateAssistant({
    text: (
      <TextSmall>
        You must be at least <Bold>20 years old </Bold>
        to get Almi motor insurance.
      </TextSmall>
    ),
  });

  const handleOnNext = async () => {
    try {
      if (nextPath && age) {
        await motorCtx.policyInfo.setItem("age", Number(age));

        heapCtx.track(HeapEventName.MOTOR_AGE, {
          Age: Number(age),
        });

        if (Number(age) > MAX_ALLOWED_AGE || Number(age) < MIN_ALLOWED_AGE) {
          history.push(`${NO_COVERAGE_URL}?message=under-age`);
        } else {
          pushWithParams(history, nextPath);
        }
      } else {
        motorCtx.policyInfo.removeItem("age");
      }
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <>
      <div className={styles.InlineTitleBox}>
        <Text>
          You&lsquo;ve chosen <Bold>Motor Insurance</Bold>
        </Text>
      </div>

      <H3 className={styles.Question} component="h1">
        How old are you?
      </H3>

      <TextInput
        className={styles.TextInputWrapper}
        label="How old are you?"
        field="age"
        placeholder="00"
        type="number"
        size={3}
        initialValue={motorCtx.policyInfo.data.age?.toString()}
        keepState
        autoFocus
        hideLabelFromView
        validate={required("Please enter a valid age")}
        validateOnChange
        validateOnBlur
        validateOnMount
        inputMode="numeric"
      />

      <div className={styles.ButtonWrapper}>
        <Button
          onClick={handleOnNext}
          disabled={!!field.error}
          className={styles.ButtonNext}
        >
          Next
        </Button>
      </div>
    </>
  );
}
