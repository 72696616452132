/* eslint-disable react/button-has-type */
import React, { FormEvent, memo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./index.module.scss";

import { Text, TextSmall } from "~/components/Typography";
import { Props } from "./index.types";

function Button(props: Props) {
  const {
    type = "button",
    size = "large",
    variant = "primary",
    children,
    className,
    text,
    externalLink,
    internalLink,
    role,
    disabled,
    onClick,
    ...rest
  } = props;

  const classes = classNames(className, styles.Button, {
    [styles.ButtonPrimary]: variant === "primary",
    [styles.ButtonSecondary]: variant === "secondary",
    [styles.ButtonTertiary]: variant === "tertiary",
    [styles.ButtonQuaternary]: variant === "quaternary",
    [styles.ButtonQuinary]: variant === "quinary",
    [styles.ButtonSenary]: variant === "senary",
    [styles.ButtonSelector]: variant === "selector",
    [styles.ButtonSelector]: variant === "selector",
    [styles.Large]: size === "large",
    [styles.Small]: size === "small",
    [styles.XSmall]: size === "xsmall",
    [styles.Text]: text,
    [styles.Link]: externalLink || internalLink,
  });

  const onClickIfNotDisabled =
    typeof onClick === "function" && disabled
      ? (e: FormEvent) => {
          e.preventDefault();
        }
      : onClick;

  if (externalLink) {
    return (
      <a
        href={externalLink}
        className={classes}
        type={type}
        onClick={onClickIfNotDisabled}
        {...rest}
      >
        {text ? (
          variant === "textSmall" ? (
            <TextSmall>{children}</TextSmall>
          ) : (
            <Text fontWeight="bold">{children}</Text>
          )
        ) : null}
        {!text && children}
      </a>
    );
  }

  if (internalLink) {
    return (
      <Link
        to={internalLink}
        className={classes}
        type={type}
        onClick={onClickIfNotDisabled}
        {...rest}
      >
        {text ? (
          variant === "textSmall" ? (
            <TextSmall>{children}</TextSmall>
          ) : (
            <Text fontWeight="bold">{children}</Text>
          )
        ) : null}
        {!text && children}
      </Link>
    );
  }

  return (
    <button
      className={classes}
      type={type}
      role={role}
      aria-disabled={disabled}
      onClick={onClickIfNotDisabled}
      {...rest}
    >
      {text ? (
        variant === "textSmall" ? (
          <TextSmall>{children}</TextSmall>
        ) : (
          <Text fontWeight="bold">{children}</Text>
        )
      ) : null}
      {!text && children}
    </button>
  );
}
export default memo(Button);
