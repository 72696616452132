import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import Loading from "~/components/Loading";
import styles from "./index.module.scss";
import {
  A,
  ButtonAnchor,
  H4,
  Link,
  Text,
  TextSmall,
} from "~/components/Typography";
import InfoCardWrapper from "~/components/InfoCardWrapper";
import Modal from "~/components/Modal";
import PortalUpdatePassword from "~/components/PortalUpdatePassword";
import GridWrapper from "~/components/GridWrapper";
import { usePortalHeaderContext } from "~/contexts/PortalHeaderProvider";
import { formatIsoDate } from "~/helpers/dates";
import { useQueryThrowError } from "~/helpers/queryWithErrorHandling";

const GET_PORTAL_INFO = gql`
  {
    getPortalInfo {
      individualProfile {
        id
        email
        name
        phoneNumber
        dateOfBirth
        address
        city
        province
        postalCode
      }
    }
  }
`;

const AccountDetails = () => {
  const { loading, data } = useQueryThrowError(GET_PORTAL_INFO);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const portalHeaderContext = usePortalHeaderContext();

  useEffect(() => {
    if (data) {
      portalHeaderContext.setChosenName(
        data.getPortalInfo.individualProfile.name
      );
    }
  }, [data, portalHeaderContext]);

  if (loading) {
    return (
      <div className={styles.Loading}>
        <Loading />
      </div>
    );
  }

  if (data && data.getPortalInfo) {
    const { individualProfile } = data.getPortalInfo;
    const address = [
      individualProfile.address,
      individualProfile.city,
      individualProfile.province,
      // individualProfile.postalCode,
      // ToDo: Country?
    ]
      .filter((value) => !!value)
      .join(", ");

    return (
      <>
        <div className={styles.BackLinkWrapper}>
          <Link to="/portal" className={styles.BackLink}>
            <TextSmall fontWeight="bold">
              <svg
                width="15"
                height="9"
                viewBox="0 0 15 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.BackArrow}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.75 5.25C14.1642 5.25 14.5 4.91421 14.5 4.5C14.5 4.08579 14.1642 3.75 13.75 3.75L2.56066 3.75L5.03033 1.28033C5.32322 0.987436 5.32322 0.512563 5.03033 0.21967C4.73744 -0.0732237 4.26256 -0.0732238 3.96967 0.219669L0.219669 3.96967C-0.0732233 4.26256 -0.0732233 4.73744 0.219669 5.03033L3.96967 8.78033C4.26256 9.07322 4.73744 9.07322 5.03033 8.78033C5.32322 8.48744 5.32322 8.01256 5.03033 7.71967L2.56066 5.25L13.75 5.25Z"
                  fill="#23453A"
                />
              </svg>
              Back to Dashboard
            </TextSmall>
          </Link>
        </div>
        <H4 className={styles.Heading} component="h2">
          My Account
        </H4>
        <InfoCardWrapper
          color="coral"
          className={styles.ProfileCard}
          groupDesc="profile details"
        >
          <div className={styles.ProfileTitle}>
            <TextSmall>Personal Information</TextSmall>
            <H4 component="h3">Profile Details</H4>
          </div>
          <dl className={styles.ProfileList}>
            <div>
              <dt>
                <TextSmall fontWeight="bold">Name</TextSmall>
              </dt>
              <dd>
                <TextSmall>{individualProfile.name}</TextSmall>
              </dd>
            </div>
            <div>
              <dt>
                <TextSmall fontWeight="bold">Date of Birth</TextSmall>
              </dt>
              <dd>
                <TextSmall>
                  {individualProfile.dateOfBirth
                    ? formatIsoDate(individualProfile.dateOfBirth)
                    : "-"}
                </TextSmall>
              </dd>
            </div>

            <div>
              <dt>
                <TextSmall fontWeight="bold">Address</TextSmall>
              </dt>
              <dd>
                <TextSmall>
                  {individualProfile.address ? address : "-"}
                </TextSmall>
              </dd>
            </div>
            <div>
              <dt>
                <TextSmall fontWeight="bold">Email</TextSmall>
              </dt>
              <dd>
                <TextSmall>{individualProfile.email}</TextSmall>
              </dd>
            </div>
            <div>
              <dt>
                <TextSmall fontWeight="bold">Password</TextSmall>
              </dt>
              <dd>
                <TextSmall>
                  <span className={styles.MaskedPassword}>••••••••</span>{" "}
                  <ButtonAnchor
                    onClick={() => setShowPasswordModal(true)}
                    fontWeight="bold"
                  >
                    Change password
                  </ButtonAnchor>
                </TextSmall>
              </dd>
            </div>
            <div>
              <dt>
                <TextSmall fontWeight="bold">Phone</TextSmall>
              </dt>
              <dd>
                <TextSmall>{individualProfile.phoneNumber}</TextSmall>
              </dd>
            </div>
          </dl>
        </InfoCardWrapper>
        <InfoCardWrapper
          color="gold"
          groupDesc="contact support"
          iconImg={
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="exclamation mark"
            >
              <circle
                cx="15"
                cy="15"
                r="14.5652"
                fill="#FCF9F3"
                stroke="#F6B600"
                strokeWidth="0.869565"
              />
              <path
                d="M15.2131 20.0859V14.0859"
                stroke="#23453A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.214 10.0859H15.229"
                stroke="#23453A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        >
          <Text className={styles.SupportIntro}>
            Please, contact support if you need to change any details from your
            account.
          </Text>
          <Text className={styles.SupportPhone}>
            Call us
            <br />
            <A
              href="tel:+5921234567"
              className={styles.SupportAction}
              fontWeight="bold"
            >
              +1 (246) 850-6800
            </A>
          </Text>
          <Text className={styles.SupportEmail}>
            Email us
            <br />
            <A
              href="mailto:support@almi.com"
              className={styles.SupportAction}
              fontWeight="bold"
            >
              support@almi.com
            </A>
          </Text>
        </InfoCardWrapper>
        <Modal
          isOpen={showPasswordModal}
          shouldOverlayCloseOnClick
          onClose={() => setShowPasswordModal(false)}
          title="Change Password"
          id="change-password-modal"
        >
          <GridWrapper>
            <div className={styles.Content}>
              <PortalUpdatePassword />
            </div>
          </GridWrapper>
        </Modal>
      </>
    );
  } else {
    throw new Error("Could not retrieve profile");
  }
};

export default AccountDetails;
