/* eslint-disable no-magic-numbers */
import React, { useContext } from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { FormValue, Radio } from "informed";
import styles from "./index.module.scss";

import { H3, H4, H5, Meta } from "~/components/Typography";
import SearchList from "~/components/SearchList";
import TileCard from "~/components/TileCard";
import VehicleSearchDisplay from "~/components/VehicleSearchDisplay";
import Loading from "~/components/Loading";

import { MotorContext } from "~/contexts/MotorProvider";
import { MakeModelType } from "~/helpers/sortVehicles";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import pushWithParams from "~/helpers/pushWithParams";

type Props = {
  makes: MakeModelType[];
  nextPath?: string;
  loading: boolean;
};

export default function VehicleMakes(props: Props) {
  const { makes, nextPath, loading } = props;
  const motorCtx = useContext(MotorContext);
  const history = useHistory();
  const [filter, setFilter] = React.useState<string>("");
  const [sectionHeaders, setSectionHeaders] = React.useState({});
  const heapCtx = useHeapContext();

  const handleOnChange = async (value: FormValue) => {
    try {
      await motorCtx.vehicleInfo.setItem("make", value as string);

      heapCtx.track(HeapEventName.MOTOR_MAKE, {
        Make: value as string,
      });

      // TODO: pull correct value from the vehicle make;
      await motorCtx.vehicleInfo.setItem("isSportsCar", false);

      if (nextPath) {
        pushWithParams(history, nextPath);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  React.useEffect(() => {
    const headers = {};

    //Will go through the list and stop when it reaches the first
    //make with no logo (first "Other" make).
    makes.every((make, index) => {
      if (index === 0) {
        headers[index] = "Popular Makes";
      } else if (!make.logo) {
        headers[index] = "Other Makes";
        return false;
      }

      return true;
    });

    setSectionHeaders(headers);
  }, [makes]);

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
  };

  const isFirstInSection = (index: number) => {
    return sectionHeaders && sectionHeaders[index];
  };

  const titleOfSection = (index: number) => {
    return sectionHeaders && sectionHeaders[index] ? sectionHeaders[index] : "";
  };

  return (
    <fieldset>
      <legend>
        <H3 component="h1">{`What make is your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()}?`}</H3>
      </legend>

      <VehicleSearchDisplay display={["year"]} />

      <div className={cx(styles.Spinner, { [styles.SpinnerVisible]: loading })}>
        <Loading />
      </div>

      <div
        className={cx(styles.MakeList, { [styles.MakeListVisible]: !loading })}
      >
        <SearchList
          loading={loading}
          list={makes}
          listKeyToFind="make"
          field="make"
          keepState
          autoFocus
          renderItem={(obj, i) => {
            const item = obj as MakeModelType;

            return (
              <React.Fragment key={item.make}>
                {!filter && isFirstInSection(i) && (
                  <H4
                    className={cx(
                      styles.SectionHeader,
                      i === 0 ? "" : styles.OtherHeader
                    )}
                    key={`Section-Header-${i}`}
                  >
                    {titleOfSection(i)}
                  </H4>
                )}

                <TileCard
                  key={`SearchInput-TileCard-${i}`}
                  title={
                    <>
                      {item.logo && (
                        <Meta className={styles.PopularMeta}>POPULAR</Meta>
                      )}

                      <H5>{item.make}</H5>
                    </>
                  }
                  inputId={`SearchInput-TileCard-${i}`}
                  checked={motorCtx.vehicleInfo.data.make === item?.make}
                  left={
                    <>
                      {item.logo && (
                        <div className={styles.LogoMake}>
                          <img
                            src={item.logo}
                            alt={item.make}
                            className={styles.Logo}
                            aria-hidden
                          />
                        </div>
                      )}
                    </>
                  }
                  inputElement={
                    <Radio
                      id={`SearchInput-TileCard-${i}`}
                      field="make"
                      value={item}
                      onClick={() => handleOnChange(item.make)}
                      className={styles.HideRadio}
                    />
                  }
                />
              </React.Fragment>
            );
          }}
          onChange={handleOnChange}
          onFilterChange={handleFilterChange}
        />
      </div>
    </fieldset>
  );
}
