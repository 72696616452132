import { MotorContextType } from "~/contexts/MotorProvider";
import { CountryCode } from "../types";
import getBranchFromCountryCode from "~/helpers/getBranchFromCountryCode";

const formMotorPolicyPayload = (
  motorInfo: MotorContextType,
  email: string | undefined,
  isPaymentRecurring: boolean,
  countryCode: CountryCode,
  toCreate: boolean
) => {
  const policy = {
    id: toCreate ? undefined : motorInfo.policyInfo.data.id,
    branch: getBranchFromCountryCode(countryCode),
    policyObject: "auto",
    // at this stage the typeofCover should be undefined
    typeOfCover: toCreate ? undefined : motorInfo.policyInfo.data.typeOfCover,
    coverageStart: motorInfo.policyInfo.data.coverageStart,
    holderEmail: email,
    previousClaims: motorInfo.savingsInfo.data.previousClaims
      ? motorInfo.savingsInfo.data.previousClaims.map((prevClaim) => {
          return {
            ...prevClaim,
            amount: parseFloat(prevClaim.amount),
          };
        })
      : [],
    isPaymentRecurring,
    motorProposal: {
      mainDriver: {
        age: motorInfo.policyInfo.data.age,
        yearsDriving: motorInfo.policyInfo.data.yearsDriving,
        occupation: motorInfo.savingsInfo.data.occupation,
        association: motorInfo.savingsInfo.data.association,
        licenseNumber: "",
      },
      isShiftWorker: motorInfo.policyInfo.data.isShiftWorker === "yes",
      isForBusiness: motorInfo.policyInfo.data.isForBusiness === "yes",
      claimsHistory: motorInfo.policyInfo.data.claimsHistory,
      yearsWithoutClaims: motorInfo.policyInfo.data.yearsWithoutClaims,
      drivers: motorInfo.policyInfo.data.drivers,
    },
  };

  const vehicle = {
    type: motorInfo.vehicleInfo.data.type,
    year: motorInfo.vehicleInfo.data.year,
    make: motorInfo.vehicleInfo.data.make,
    model: motorInfo.vehicleInfo.data.model,
    value: motorInfo.vehicleInfo.data.value?.toLocaleString(),
    isSportsCar: motorInfo.vehicleInfo.data.isSportsCar,
    engineSize: motorInfo.vehicleInfo.data.engineSize,
    engineNumber: motorInfo.vehicleInfo.data.engineNumber,
    chassisNumber: motorInfo.vehicleInfo.data.chassisNumber,
    isVehicleOwned: motorInfo.vehicleInfo.data.isVehicleOwned === "yes",
    isEngineModified: motorInfo.vehicleInfo.data.isEngineModified === "yes",
    isLeftSide: motorInfo.vehicleInfo.data.isLeftSide === "yes",
  };

  const policyPayload = {
    ...policy,
    motorProposal: {
      ...policy.motorProposal,
      vehicle: {
        ...vehicle,
        value: motorInfo.vehicleInfo.data.value,
      },
    },
  };

  return policyPayload;
};

export default formMotorPolicyPayload;
