import React from "react";
import classnames from "classnames";
import TypeImage from "~/components/TypeImage";
import styles from "./index.module.scss";
import Status from "~/components/Status";
import Button from "../Button";
import { Bold, H5, TextSmall } from "../Typography";
import Modal from "~/components/Modal";
import { getTypeOfCoverage } from "~/helpers/getCoverageData";
import getStatusColor from "~/helpers/getStatusColor";
import PolicyDetails from "~/components/PolicyDetails";

import { PortalPolicyType } from "../../types";
import { matchPath, useHistory } from "react-router";

type PathMatchType = {
  params: { policyId: string };
};

type PropsType = {
  policy: PortalPolicyType;
};

export default function PolicyCard({ policy }: PropsType) {
  const { externalId, insuredObject, typeOfCover, status, vehicle } = policy;
  const history = useHistory();
  const pathMatch: PathMatchType | null = matchPath(history.location.pathname, {
    path: [
      "/portal/policy/:policyId",
      "/portal/policy/:policyId/update-payment",
    ],
    exact: true,
    strict: false,
  });
  const modalOpen = !!(pathMatch && pathMatch.params.policyId === externalId);
  const openModal = () => {
    history.push(`/portal/policy/${externalId}`);
  };
  const closeModal = () => {
    history.push("/portal");
  };

  return (
    <>
      <div
        className={classnames(
          styles.PolicyCardWrapper,
          styles[getStatusColor(status)]
        )}
        role="group"
        aria-label="policy"
      >
        <TypeImage
          type={policy.policyObject === "auto" ? "motor" : "home"}
          vehicleMake={
            policy.policyObject === "auto" ? vehicle.make : undefined
          }
        />

        <div className={styles.TextWrapper}>
          <H5 className={styles.WrapperElements} component="h3">
            {insuredObject}
          </H5>
          <TextSmall className={styles.WrapperElements}>
            {getTypeOfCoverage(typeOfCover)?.title}
          </TextSmall>
          <TextSmall className={styles.WrapperElements}>
            Policy ID <Bold>{externalId}</Bold>
          </TextSmall>
          <Status status={status} />
        </div>
        <Button
          className={styles.Button}
          size="small"
          variant="tertiary"
          onClick={openModal}
        >
          See policy details
        </Button>
      </div>
      <Modal
        title={`Policy ${externalId}`}
        isOpen={modalOpen}
        shouldOverlayCloseOnClick
        onClose={closeModal}
        noOffset
        id="policy-modal"
      >
        <div className={styles.Modal}>
          <PolicyDetails policy={policy} />
        </div>
      </Modal>
    </>
  );
}
