import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import { Form } from "informed";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  MakeModelsReduced,
  MakeModelType,
  sortMakes,
} from "~/helpers/sortVehicles";
import { MotorContext } from "~/contexts/MotorProvider";
import { PAYMENT_MOTOR_RESULT_URL } from "~/routes/index.constant";

import MultiStepForm from "~/components/MultiStepForm";
import QuickNote from "~/screens/Motor/QuickNote";
import HowOldAreYou from "~/screens/Motor/HowOldAreYou";
import HowManyYearsDriving from "~/screens/Motor/HowManyYearsDriving";
import VehicleMakes from "~/screens/Motor/VehicleMakes";
import VehicleTypes from "~/screens/Motor/VehicleTypes";
import VehicleYear from "~/screens/Motor/VehicleYear";
import VehicleModel from "~/screens/Motor/VehicleModel";
import EngineSize from "~/screens/Motor/EngineSize";
import LeftRightHand from "~/screens/Motor/LeftRightHand";
import VehicleValue from "~/screens/Motor/VehicleValue";
import CoverageStart from "~/screens/Motor/CoverageStart";
import VehicleBusinessPurpose from "~/screens/Motor/VehicleBusinessPurpose";
import DriversDetails from "~/screens/Motor/DriversDetails";
import NoClaimsLength from "~/screens/Motor/NoClaimsLength";
import ClaimsYears from "~/screens/Motor/ClaimsYears";
import ClaimHistory from "~/screens/Motor/ClaimHistory";
import ClaimsInformation from "~/screens/Motor/ClaimsInformation";
import CoveragePlans from "~/screens/Motor/CoveragePlans";
import AccountNote from "~/screens/Account/Note";
import AccountName from "~/screens/Account/Name";
import AccountEmail from "~/screens/Account/Email";
import AccountPassword from "~/screens/Account/Password";
import AccountPhone from "~/screens/Account/PhoneNumber";
import AccountCreated from "~/screens/Account/Created";
import EngineAndChassisNumber from "~/screens/Motor/EngineAndChassisNumber";
import RequiredDocuments from "~/screens/Motor/RequiredDocuments";
import Association from "~/screens/Motor/Association";
import Occupation from "~/screens/Motor/Occupation";
import DocumentsReview from "~/screens/Identification/DocumentsReview";
import EngineModifications from "~/screens/Motor/EngineModifications";
import OwnVehicle from "~/screens/Motor/OwnVehicle";
import ReviewPolicy from "~/screens/Motor/ReviewPolicy";
import { InsuranceType } from "../../types";
import AuthorizedRoute from "~/components/AuthorizedRoute";
import JobShiftWork from "~/screens/Motor/JobShiftWork";
import VehicleStyle from "~/screens/Motor/VehicleStyle";
import CoverageNote from "~/screens/Motor/CoverageNote";
import PaymentResult from "~/screens/Payment/PaymentResult";
import Payment from "~/screens/Payment/Payment";
import PaymentCheckout from "~/screens/Payment/PaymentCheckout";

//assets
import toyotaLogo from "~/assets/images/makes-toyota.png";
import suzukiLogo from "~/assets/images/makes-suzuki.png";
import subaruLogo from "~/assets/images/makes-subaru.png";
import nissanLogo from "~/assets/images/makes-nissan.png";
import mazdaLogo from "~/assets/images/makes-mazda.png";
import mitsubishiLogo from "~/assets/images/makes-mitsubishi.png";
import kiaLogo from "~/assets/images/makes-kia.png";
import isuzuLogo from "~/assets/images/makes-isuzu.png";
import hyundaiLogo from "~/assets/images/makes-hyundai.png";
import hondaLogo from "~/assets/images/makes-honda.png";

import { StepType } from "~/helpers/flow";

const COUNTRY_NAME = "Barbados";

const BARBADOS_MOTOR_STEPS: StepType[] = [
  {
    path: "/BRB/motor/quick-note",
    headerTitle: "Personal Information",
  },
  {
    path: "/BRB/motor/age",
    headerTitle: "Personal Information",
  },
  {
    path: "/BRB/motor/driving-experience",
    headerTitle: "Personal Information",
  },
  {
    path: "/BRB/motor/types",
    headerTitle: "Personal Information",
  },
  {
    path: "/BRB/motor/own-vehicle",
    headerTitle: "Personal Information",
  },
  {
    path: "/BRB/motor/year",
    headerTitle: "Vehicle Information",
  },
  {
    path: "/BRB/motor/makes",
    headerTitle: "Vehicle Information",
  },
  {
    path: "/BRB/motor/models",
    headerTitle: "Vehicle Information",
  },
  {
    path: "/BRB/motor/style",
    headerTitle: "Vehicle Information",
  },
  {
    path: "/BRB/motor/left-right-hand",
    headerTitle: "Vehicle Information",
  },
  {
    path: "/BRB/motor/engine-size",
    headerTitle: "Vehicle Information",
  },
  {
    path: "/BRB/motor/engine-modifications",
    headerTitle: "Vehicle Information",
  },
  {
    path: "/BRB/motor/value",
    headerTitle: "Vehicle Information",
  },
  {
    path: "/BRB/motor/coverage-start",
    title: "Coverage Start",
    headerTitle: "Coverage Details",
  },
  {
    path: "/BRB/motor/vehicle-purpose",
    title: "Vehicle Purpose",
    headerTitle: "Coverage Details",
  },
  {
    path: "/BRB/motor/drivers-details",
    title: "Drivers' Info",
    headerTitle: "Coverage Details",
  },
  {
    path: "/BRB/motor/no-claims-length",
    title: "Claim History",
    headerTitle: "Coverage Details",
  },
  {
    path: "/BRB/motor/claims-years",
    title: "Claim History",
    headerTitle: "Coverage Details",
  },
  {
    path: "/BRB/motor/claim-history",
    title: "Claim History",
    headerTitle: "Coverage Details",
  },
  {
    path: "/BRB/motor/claim-info",
    title: "Claim Info",
    headerTitle: "Coverage Details",
  },
  {
    path: "/BRB/motor/job-shift-work",
    headerTitle: "Coverage Details",
  },
  {
    path: "/BRB/motor/occupation",
    headerTitle: "Occupation",
  },
  {
    path: "/BRB/motor/association",
    headerTitle: "Occupation",
  },
  {
    path: "/BRB/motor/email",
    title: "What's your email",
    headerTitle: "Almost there!",
  },
  {
    path: "/BRB/motor/coverage-plans",
    title: "Coverage Plans",
    headerTitle: "Coverage Plans",
    hideBackButton: true,
  },
  {
    path: "/BRB/motor/account",
    title: "Create your account",
    headerTitle: "Create an account",
    hideBackButton: true,
  },
  {
    path: "/BRB/motor/your-name",
    title: "What shall we call you",
    headerTitle: "Create an account",
    hideBackButton: true,
  },
  {
    path: "/BRB/motor/phone",
    title: "What's your phone number",
    headerTitle: "Create an account",
  },
  {
    path: "/BRB/motor/password",
    title: "Please create a password",
    headerTitle: "Create an account",
  },
  {
    path: "/BRB/motor/account-complete",
    title: "Thank you! Your account has been created.",
    headerTitle: "Create an account",
    hideBackButton: true,
  },
  {
    path: "/BRB/motor/engine-and-chassis",
    title: "Engine and Chassis Number",
    headerTitle: "Engine and Chassis Number",
    hideBackButton: true,
  },
  {
    path: "/BRB/motor/documents",
    title: "Identity Documents",
    headerTitle: "Identity Documents",
  },
  {
    path: "/BRB/motor/documents-review",
    title: "Identity Documents",
    headerTitle: "Identity Documents",
  },
  {
    path: "/BRB/motor/review-policy",
    title: "Review Policy and Signature",
    headerTitle: "Review Proposal and Signature",
    hideBackButton: true,
  },
  {
    path: "/BRB/motor/payment",
    title: "Payment",
    headerTitle: "Payment",
    hideBackButton: true,
  },
  {
    path: "/brb/motor/checkout",
    headerTitle: "Payment",
    hideBackButton: true,
  },
  {
    path: PAYMENT_MOTOR_RESULT_URL,
    hideBackButton: true,
    headerTitle: "Payment",
  },
  {
    path: "/brb/motor/coverage-note",
    hideBackButton: true,
    headerTitle: "Coverage Confirmation",
  },
];

export const popularMakesList = {
  toyota: {
    make: "Toyota",
    logo: toyotaLogo,
  },
  suzuki: {
    make: "Suzuki",
    logo: suzukiLogo,
  },
  nissan: {
    make: "Nissan",
    logo: nissanLogo,
  },
  mitsubishi: {
    make: "Mitsubishi",
    logo: mitsubishiLogo,
  },
  hyundai: {
    make: "Hyundai",
    logo: hyundaiLogo,
  },
  isuzu: {
    make: "Isuzu",
    logo: isuzuLogo,
  },
  kia: {
    make: "Kia",
    logo: kiaLogo,
  },
  mazda: {
    make: "Mazda",
    logo: mazdaLogo,
  },
  honda: {
    make: "Honda",
    logo: hondaLogo,
  },
  subaru: {
    make: "Subaru",
    logo: subaruLogo,
  },
  // TODO: For now, there's no logo for Ford
  // ford: {
  //   make: "Ford",
  //   logo: undefined,
  // },
};

const GET_MAKES = gql`
  query getMakes($year: Float!) {
    getMakes(year: $year) {
      make
      models {
        modelName
        countryOfOrigin
        classification
        isSportsCar
        trimOptions {
          isSportsCar
          trimName
        }
      }
    }
  }
`;

const BarbadosMotor = () => {
  const motorCtx = useContext(MotorContext);

  const [getMakes, { data, error, loading }] = useLazyQuery(GET_MAKES);
  const [makes, setMakes] = useState<MakeModelType[]>([]);
  const [models, setModels] = useState([]);
  const [trims, setTrims] = useState([]);
  const [isSportsCar, setIsSportsCar] = useState(false);
  const [searchedYear, setSearchedYear] = useState<number | undefined>(
    undefined
  );
  const [fetchedData, setFetchedData] = useState(false);
  const allowCloseConfirm = useRef(true);
  const setCloseConfirm = (value: boolean) => {
    allowCloseConfirm.current = value;
  };

  useEffect(() => {
    if (
      motorCtx.vehicleInfo.data.year &&
      (motorCtx.vehicleInfo.data.year !== searchedYear || !makes) &&
      !fetchedData
    ) {
      setSearchedYear(motorCtx.vehicleInfo.data.year);
      getMakes({ variables: { year: motorCtx.vehicleInfo.data.year } });
      setFetchedData(true);
    }
  }, [
    motorCtx.vehicleInfo.data.year,
    getMakes,
    searchedYear,
    makes,
    fetchedData,
  ]);

  useEffect(() => {
    if (data && data?.getMakes && data?.getMakes?.length) {
      const reducedMakes = data.getMakes.reduce(
        (acc: MakeModelsReduced, current: MakeModelType) => {
          const makeToKey = current.make.toLocaleLowerCase();
          const isPopular = popularMakesList[makeToKey];
          if (isPopular) {
            const popular = {
              ...popularMakesList[makeToKey],
              models: current.models,
            };
            return {
              ...acc,
              popular: [...acc?.popular, popular],
            };
          }
          return {
            ...acc,
            rest: [...acc?.rest, { ...current, logo: undefined }],
          };
        },
        { popular: [], rest: [] }
      );

      setMakes([
        ...reducedMakes.popular.sort(sortMakes),
        ...reducedMakes.rest.sort(sortMakes),
      ]);
    }
  }, [data, setMakes]);

  useEffect(() => {
    if (motorCtx.vehicleInfo.data.make && data && data.getMakes) {
      const make = data.getMakes.find((item: MakeModelType) => {
        return item.make === motorCtx.vehicleInfo.data.make;
      });

      setModels(make ? make.models : []);
    }
  }, [motorCtx.vehicleInfo.data.make, data]);

  useEffect(() => {
    if (motorCtx.vehicleInfo.data.model && data && data.getMakes) {
      const model = data.getMakes
        .find((item: MakeModelType) => {
          return item.make === motorCtx.vehicleInfo.data.make;
        })
        ?.models.find(({ modelName }: { modelName: string }) => {
          return modelName === motorCtx.vehicleInfo.data.model;
        });

      setTrims(model ? model.trimOptions : []);
      setIsSportsCar(model ? model.isSportsCar : false);
    }
  }, [motorCtx.vehicleInfo.data.model, motorCtx.vehicleInfo.data.make, data]);

  const {
    insuranceType,
  }: {
    insuranceType: InsuranceType;
  } = useParams();

  if (error) {
    throw new Error("Error fetching data from API");
  }

  useBeforeunload((event) => {
    // Warn before closing if in between age and email screen
    const { age, id } = motorCtx.policyInfo.data;
    if (age && !id && allowCloseConfirm.current) {
      event.preventDefault();
    }
  });

  return (
    <Form>
      <MultiStepForm steps={BARBADOS_MOTOR_STEPS}>
        <QuickNote insuranceType={insuranceType} />
        <HowOldAreYou />
        <HowManyYearsDriving />
        <VehicleTypes />
        <OwnVehicle />
        <VehicleYear />
        <VehicleMakes makes={makes} loading={loading} />
        <VehicleModel modelData={models} loading={loading} />
        <VehicleStyle
          isSportsCar={isSportsCar}
          trimsData={trims}
          loading={loading}
        />
        <LeftRightHand />
        <EngineSize />
        <EngineModifications />
        <VehicleValue />
        <CoverageStart />
        <VehicleBusinessPurpose />
        <DriversDetails />
        <NoClaimsLength />
        <ClaimsYears />
        <ClaimHistory />
        <ClaimsInformation />
        <JobShiftWork />
        <Occupation />
        <Association />
        <AccountEmail
          insuranceType={insuranceType}
          countryCode={"BRB"}
          setCloseConfirm={setCloseConfirm}
        />
        <CoveragePlans />
        <AccountNote />
        <AccountName />
        <AccountPhone />
        <AccountPassword insuranceType={insuranceType} />
        <AuthorizedRoute passOnProps>
          <AccountCreated insuranceType={insuranceType} />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <EngineAndChassisNumber />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <RequiredDocuments insuranceType={insuranceType} />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <DocumentsReview insuranceType={insuranceType} />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <ReviewPolicy insuranceType={insuranceType} />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <Payment insuranceType={insuranceType} countryName={COUNTRY_NAME} />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <PaymentCheckout
            paymentResultUrl={PAYMENT_MOTOR_RESULT_URL}
            insuranceType={insuranceType}
          />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <PaymentResult didNotAgreeRedirect={PAYMENT_MOTOR_RESULT_URL} />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <CoverageNote />
        </AuthorizedRoute>
      </MultiStepForm>
    </Form>
  );
};

export default BarbadosMotor;
