import React from "react";
import classnames from "classnames";
import { CoverageObjType } from "../../types";
import { TextSmall } from "~/components/Typography";
import Icon from "~/components/Icon";
import { formatCurrency } from "~/helpers/currency";
import styles from "./index.module.scss";

type ListItem = {
  id: string;
  title: string;
  types: string[];
  requiredCoverage?: string;
};

type PropsType = {
  typeOfCover: string;
  coverage: CoverageObjType[];
  showNotCovered?: boolean;
  skipById?: Array<ListItem["id"]>;
  itemFilterPredicate?: (item: ListItem) => boolean;
};

const DEDUCTIBLE = 1000;

const CoverageList = ({
  typeOfCover,
  coverage,
  showNotCovered = true,
  itemFilterPredicate = () => true,
}: PropsType) => {
  const getCoveredValue = (peril: string): number => {
    const foundCoverage = coverage.find((cover) => cover.peril === peril);
    if (!foundCoverage) return 0;
    return foundCoverage.coveredValue;
  };

  const itemIsCovered = (item: ListItem): boolean => {
    if (!item.types.includes(typeOfCover)) {
      return false;
    }

    if (
      item.requiredCoverage &&
      !coverage.find((cover) => {
        return cover.peril === item.requiredCoverage;
      })
    ) {
      return false;
    }

    return true;
  };

  const items: ListItem[] = [
    {
      id: "third-party",
      title: `${formatCurrency(
        getCoveredValue("third-party-casualty"),
        true
      )} Third Party Coverage`,
      types: ["L", "T", "C"],
    },
    { id: "comprehensive", title: "Comprehensive coverage", types: ["L", "C"] },
    {
      id: "emergency-assistance",
      title: "24/7 Emergency Assistance",
      types: ["L", "T", "C"],
    },
    {
      id: "1000-deductable",
      title: `${formatCurrency(DEDUCTIBLE, true)} deductible`,
      types: ["L", "C"],
    },
    {
      id: "windscreen-protection",
      title: "Windscreen protection",
      types: ["L", "C"],
    },
    { id: "courtesy-vehicle", title: "Courtesy Vehicle", types: ["C"] },
    {
      id: "home-natural-disasters",
      title: "Natural Disaster Protection",
      types: ["S"],
    },
    {
      id: "home-contents-coverage",
      title: "Contents Coverage",
      types: ["S"],
      requiredCoverage: "home-contents-coverage",
    },
    {
      id: "home-all-risks-coverage",
      title: "All Risks Coverage",
      types: ["S"],
      requiredCoverage: "home-all-risks-coverage",
    },
    {
      id: "home-theft-burglary",
      title: "Theft and Burglary",
      types: ["S"],
    },
    {
      id: "home-emergency-expenses",
      title: "Emergency Expenses",
      types: ["S"],
    },
    {
      id: "home-accidental-damage",
      title: "Accidental Damage",
      types: ["S"],
    },
  ].filter(itemFilterPredicate);

  const list = showNotCovered
    ? items
    : items.filter((item) => itemIsCovered(item));

  const renderItem = (item: ListItem) => {
    const disabled = !itemIsCovered(item);

    return (
      <TextSmall
        key={item.id}
        className={classnames(styles.ListItem, {
          [styles.ItemDisabled]: disabled,
        })}
      >
        <Icon
          name={disabled ? "Close" : "CheckRound"}
          backgroundColor="transparent"
          fill={disabled ? "#91A29C" : "#31AD63"}
        />
        {item.title}
      </TextSmall>
    );
  };

  return <>{list.map((item) => renderItem(item))}</>;
};

export default CoverageList;
