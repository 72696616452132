import React from "react";
import { useHistory } from "react-router-dom";
import { useFormApi } from "informed";
import { useMotorContext } from "~/contexts/MotorProvider";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import styles from "./index.module.scss";
import { H3, TextSmall, Bold } from "~/components/Typography";
import Button from "~/components/Button";
import NoteWithList from "~/components/NoteWithList";
import pushWithParams from "~/helpers/pushWithParams";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { addIsoDate, formatIsoDate, todaysDateInAst } from "~/helpers/dates";

export default function CoverageNote({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const motorCtx = useMotorContext();
  const onboardCtx = useOnboardContext();
  const heapCtx = useHeapContext();
  const form = useFormApi();
  const daysFromNow = 7;
  const documentDueDate = formatIsoDate(
    addIsoDate(todaysDateInAst(), { days: daysFromNow }),
    "MMM dd, yyyy"
  );

  const documentList = [
    "Your Motor Purchase Letter",
    "Your Motor Registration Card",
    "Your Vehicle valuation",
    "Your Assignment Letter (if your vehicle is financed)",
  ];

  if ((motorCtx.policyInfo.data.yearsWithoutClaims || 0) >= 1) {
    documentList.push("Your No Claims Discount Letter");
  }

  const handleNext = () => {
    heapCtx.track(HeapEventName.ONBOARDING_COVERAGE_SUCCESS_CONTINUE, {
      "Policy ID": motorCtx.policyInfo.data.id,
    });

    motorCtx.vehicleInfo.removeStore();
    motorCtx.savingsInfo.removeStore();
    motorCtx.policyInfo.removeStore();
    onboardCtx.removeStore();
    form.reset();

    if (nextPath) {
      pushWithParams(history, nextPath);
    } else {
      pushWithParams(history, "/portal");
    }
  };

  return (
    <div className={styles.Content}>
      <H3 className={styles.Title} component="h1">
        Almost there!
      </H3>
      <TextSmall>
        You are now covered. To complete the process you will need to provide
        some additional documents. Your Cover Note is ready to download in your
        portal. You can find it in your policy details, under policy documents.
        Print it out and keep it in your vehicle.
      </TextSmall>
      <TextSmall className={styles.CoveredPeriod}>
        <Bold>Your cover note is valid for 30 days.</Bold>
      </TextSmall>
      <NoteWithList
        mainText={`You’ll need to upload the following documents by ${documentDueDate} (${daysFromNow} days from now) to complete your Coverage Plan:`}
        list={documentList}
        className={styles.Note}
      />
      <Button onClick={handleNext} className={styles.BtnNext}>
        Complete Coverage Plan
      </Button>
    </div>
  );
}
