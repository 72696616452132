import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutationThrowError } from "~/helpers/queryWithErrorHandling";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import styles from "./index.module.scss";

import imgReviewPolicy from "~/assets/images/review-policy.png";

import { H3 } from "~/components/Typography";
import Button from "~/components/Button";
import { MotorContext } from "~/contexts/MotorProvider";
import AppError from "~/helpers/AppError";

import { CONTRACT_STATUS, POLICY_STATUS } from "~/helpers/constants";
import useHasRequiredData from "~/helpers/useHasRequiredData";
import { InsuranceType, PolicyStatus } from "../../../types";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { HomeContext } from "~/contexts/HomeProvider";

const GET_POLICY = gql`
  query($getGeneralPolicyInput: String!) {
    getGeneralPolicy(input: $getGeneralPolicyInput) {
      status
    }
  }
`;

const SET_CONTRACT_STATUS = gql`
  mutation setContractStatus($policyId: String!) {
    setContractStatus(policyId: $policyId) {
      envelopeStatus
    }
  }
`;

const GET_CONTRACT_STATUS = gql`
  query getContractStatus($policyId: String!) {
    getContractStatus(policyId: $policyId) {
      envelopeStatus
    }
  }
`;

export default function ReviewPolicy({
  nextPath,
  insuranceType,
}: {
  nextPath?: string;
  insuranceType: InsuranceType;
}) {
  const history = useHistory();
  const [timedout, setTimedout] = useState(false);

  const [timer, setTimer] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();

  const motorCtx = useContext(MotorContext);
  const homeCtx = useContext(HomeContext);
  const heapCtx = useHeapContext();

  const policyId =
    insuranceType === "motor" ? motorCtx.policyInfo.data.id : homeCtx.policyId;

  const proposalName =
    insuranceType === "motor" ? "Motor Insurance" : "Home Insurance";

  const { data: policyData } = useQuery(GET_POLICY, {
    variables: {
      getGeneralPolicyInput: policyId,
    },
    skip: !policyId,
    fetchPolicy: "no-cache",
  });

  const policyStatus = policyData?.getGeneralPolicy?.status;

  useMountEffect(() => {
    heapCtx.track(HeapEventName.ONBOARDING_PROPOSAL_SUCCESS_SCREEN, {
      "Policy ID": policyId,
    });
  });

  const POLL_INTERVAL = 1000;
  const POLL_TIMEOUT = 30000;
  const [setContractStatus] = useMutationThrowError(SET_CONTRACT_STATUS);
  const requiredStatus = CONTRACT_STATUS.completed;

  const {
    data: getStatusData,
    error: getStatusError,
    stopPolling,
    startPolling,
  } = useQuery(GET_CONTRACT_STATUS, {
    variables: {
      policyId,
    },
    onCompleted: (data) => {
      if (data.getContractStatus.envelopeStatus === requiredStatus) {
        setContractStatus({
          variables: {
            policyId,
          },
        });
      }
    },
  });

  useEffect(() => {
    if (
      getStatusData &&
      getStatusData.getContractStatus.envelopeStatus !== requiredStatus
    ) {
      startPolling(POLL_INTERVAL);

      if (!timer) {
        const pollTimeout = setTimeout(() => {
          !timedout && setTimedout(true);
          stopPolling();
        }, POLL_TIMEOUT);
        setTimer(pollTimeout);
      }
    } else if (
      getStatusData &&
      getStatusData.getContractStatus.envelopeStatus === requiredStatus
    ) {
      timer && clearTimeout(timer);
      stopPolling();
    }

    return () => {
      timer && clearTimeout(timer);
      stopPolling();
    };
  }, [
    startPolling,
    stopPolling,
    timedout,
    requiredStatus,
    getStatusData,
    timer,
  ]);

  useHasRequiredData({
    requiredData: [policyId],
    redirectUrl: "/portal",
    policyRequiredStatus: [
      POLICY_STATUS.pendingSigning as PolicyStatus,
      POLICY_STATUS.pendingPayment as PolicyStatus,
    ],
    policyStatus,
    skip: !policyStatus,
  });

  const handleOnNext = () => {
    heapCtx.track(HeapEventName.ONBOARDING_PROPOSAL_SUCCESS_CONTINUE, {
      "Policy ID": policyId,
    });

    if (nextPath) {
      history.push(nextPath);
    }
  };

  if (getStatusError) {
    throw new AppError("There was a problem verifying your contract status");
  }

  const status = getStatusData?.getContractStatus.envelopeStatus;

  if (timedout) {
    throw new AppError(
      "There was a problem verifying your contract status, the service has timed out"
    );
  }

  return (
    <div className={styles.Content}>
      <H3 component="h1">
        {status !== requiredStatus
          ? `Processing your Almi ${proposalName} Proposal...`
          : `Great! Your Almi ${proposalName} Proposal has been signed.`}
      </H3>

      <img src={imgReviewPolicy} alt="Review Policy" className={styles.Image} />

      <Button
        onClick={handleOnNext}
        className={styles.BtnNext}
        autoFocus
        disabled={status !== requiredStatus}
      >
        {status !== requiredStatus
          ? "Fetching proposal status..."
          : "Continue to payment"}
      </Button>
    </div>
  );
}
