import React, { useState } from "react";
import gql from "graphql-tag";
import { useApolloClient } from "@apollo/react-hooks";
import styles from "./index.module.scss";

import Modal from "~/components/Modal";
import { H3, H4, TextSmall } from "~/components/Typography";

import CheckedImg from "~/assets/images/checked.png";
import Button from "~/components/Button";
import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";
import Assistant from "~/components/Assistant";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  email: string | undefined;
};

const SEND_VERIFICATION_EMAIL = gql`
  query sendVerificationEmail {
    sendVerificationEmail
  }
`;

export default function UserVerification(props: Props) {
  const { isOpen, onClose, email } = props;
  const assistant = useAssistantContext();
  const apolloClient = useApolloClient();
  const [, setError] = useState();

  useUpdateAssistant({
    isOpen,
    text: (
      <TextSmall>
        If you didn’t receive a verification email,{" "}
        <Button
          className={styles.assistantButton}
          type="button"
          text
          variant="text"
          onClick={() => {
            apolloClient
              .query({
                query: SEND_VERIFICATION_EMAIL,
              })
              .catch((error) => {
                // Make error boundary catch this
                setError(() => {
                  throw error;
                });
              });
            assistant.updateAssistant({
              isOpen: true,
              text: (
                <TextSmall>
                  Verification sent. Please check your inbox.
                </TextSmall>
              ),
            });
          }}
        >
          send it again.
        </Button>
      </TextSmall>
    ),
  });

  return (
    <Modal
      isOpen={isOpen}
      shouldOverlayCloseOnClick
      onClose={onClose}
      title="User Verification"
      id="user-verification-modal"
    >
      <div className={styles.Wrapper}>
        <img src={CheckedImg} alt="checked" className={styles.CheckedImg} />
        <H3 className={styles.title}>
          Please verify your account before you continue.
        </H3>
        {email && (
          <>
            <TextSmall>A verification link has been sent to</TextSmall>{" "}
            <H4 className={styles.email}>{email}</H4>
          </>
        )}
        <TextSmall>
          Follow the instructions in the email to verify your account.
        </TextSmall>
        <Button
          className={styles.mainButton}
          variant="primary"
          onClick={onClose}
        >
          Got it!
        </Button>
        <div className={styles.FooterContentLeft}>
          <Assistant className={styles.assistant} />
        </div>
      </div>
    </Modal>
  );
}
