import { useApolloClient } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import HeapJS from "reactjs-heap";

const trackingId = process.env.REACT_APP_HEAP_TRACKING_ID;

if (trackingId) {
  HeapJS.initialize(trackingId);
}

const FETCH_PROFILE_ID = gql`
  query profileIdForEmail($email: String!) {
    profileIdForEmail(email: $email)
  }
`;

type Props = {
  children?: React.ReactNode;
};

export type HeapUserProperties = {
  "Almi ID"?: string;
  "Country of Residence"?: string;
  "Date of Birth"?: string;
  "Driving Experience"?: number;
  Occupation?: string;
  Association?: string;
};

export type HeapEventProperties = {
  "Country of Residence"?: string;
  Age?: number;
  Experience?: number;
  "Vehicle Type"?: string;
  "Ownership Type"?: string;
  Year?: number;
  Make?: string;
  Model?: string;
  "Left-hand Drive"?: string;
  "Engine Size"?: string;
  "Engine Modifications"?: string;
  "Vehicle Value"?: number;
  "Coverage Date"?: string;
  "Personal Use"?: string;
  "Number of Additional Drivers"?: number;
  "Number of Past Claims"?: string;
  Value?: number;
  Date?: string;
  "At Fault"?: string;
  Finalized?: string;
  "NCD Amount"?: number;
  "Shift Work"?: string;
  Occupation?: string;
  Association?: string;
  "Policy ID"?: string;
  "Plan Type"?: string;
  "Payment Type"?: string;
  "ID/Document Type"?: string;
  "Error Code"?: string;
  "Error Message"?: string;
};

export enum HeapEventName {
  MOTOR_COUNTRY_OF_RESIDENCE = "Basic Info - Submit - Country of Residence",
  MOTOR_AGE = "Basic Info - Submit - Age",
  MOTOR_DRIVING_EXPERIENCE = "Basic Info - Submit - Driving Experience",
  MOTOR_VEHICLE_TYPE = "Vehicle Info - Submit - Vehicle Type",
  MOTOR_OWNERSHIP_TYPE = "Vehicle Info - Submit - Ownership Type",
  MOTOR_YEAR = "Vehicle Info - Submit - Year",
  MOTOR_MAKE = "Vehicle Info - Submit - Make",
  MOTOR_MODEL = "Vehicle Info - Submit - Model",
  MOTOR_LEFT_HAND_DRIVE = "Vehicle Info - Submit - Left-hand Drive",
  MOTOR_ENGINE_SIZE = "Vehicle Info - Submit - Engine Size",
  MOTOR_ENGINE_MODIFICATIONS = "Vehicle Info - Submit - Engine Modifications",
  MOTOR_VEHICLE_VALUE = "Vehicle Info - Submit - Vehicle Value",
  MOTOR_COVERAGE_DATE = "Coverage Details - Submit - Coverage Date",
  MOTOR_PERSONAL_USE = "Coverage Details - Submit - Personal Use",
  MOTOR_ADDITIONAL_DRIVERS = "Coverage Details - Submit - Additional Drivers",
  MOTOR_DRIVER_DETAILS = "Coverage Details - Submit - Driver Details",
  MOTOR_PAST_CLAIMS = "Coverage Details - Submit - Past Claims",
  MOTOR_CLAIM_DETAILS = "Coverage Details - Submit - Claim Details",
  MOTOR_NCD = "Coverage Details - Submit - NCD",
  MOTOR_SHIFT_WORK = "Occupation - Submit - Shift Work",
  MOTOR_OCCUPATION = "Occupation - Submit - Occupation",
  MOTOR_ASSOCIATION = "Occupation - Submit - Association",
  MOTOR_EMAIL_ADDRESS = "Motor Quote - Submit - Email Address",
  MOTOR_PLAN_SELECTION = "Motor Quote - Submit - Plan Selection",
  HOME_OCCUPATION = "Occupation - Submit - Occupation",
  ONBOARDING_CREATE_ACCOUNT = "Create Account - Click - Intro Continue",
  ONBOARDING_NAME = "Create Account - Submit - Name",
  ONBOARDING_PHONE_NUMBER = "Create Account - Submit - Phone Number",
  ONBOARDING_PASSWORD = "Create Account - Submit - Password",
  ONBOARDING_PASSWORD_VISIBILITY = "Create Account - Toggle - Password Visibility",
  ONBOARDING_ACCOUNT_CREATION_DESKTOP = "Create Account - View - Creation Screen - Desktop",
  ONBOARDING_ACCOUNT_CREATION_MOBILE = "Create Account - View - Creation Screen - Mobile",
  ONBOARDING_CREATE_ACCOUNT_SUCCESS = "Create Account - Click - Success Continue",
  ONBOARDING_DOCUMENT_UPLOAD_SCREEN = "Identity - View - Document Upload Screen",
  ONBOARDING_UPLOAD_SELFIE = "Identity - Upload - Selfie",
  ONBOARDING_UPLOAD_DOCUMENT = "Identity - Upload - IDs/Documents",
  ONBOARDING_IDENTITY_SUBMIT = "Identity - Submit",
  ONBOARDING_ID_ERROR_SCREEN = "Identity - View - ID Error Screen",
  ONBOARDING_INFO_REVIEW_CONTINUE = "Identity - Click - Info Review Continue",
  ONBOARDING_PROPOSAL_SUCCESS_SCREEN = "Proposal - View - Proposal Success Screen",
  ONBOARDING_PROPOSAL_SUCCESS_CONTINUE = "Proposal - Click - Proposal Success Continue",
  ONBOARDING_REVIEW_COMPLETE = "Payment - Click - Review Complete",
  ONBOARDING_PAYMENT_INFORMATION = "Payment - Submit - Payment Information",
  ONBOARDING_PAYMENT_ERROR_SCREEN = "Payment - View - Payment Error Screen",
  ONBOARDING_PAYMENT_SUCCESS_CONTINUE = "Payment - Click - Payment Success Continue",
  ONBOARDING_COVERAGE_SUCCESS_CONTINUE = "Payment - Click - Coverage Success Continue",
}

type HeapContextType = {
  initialized: boolean;
  getIdentity: () => string | undefined;
  checkForProfileId: (email: string) => void;
  identify: (profileId: string) => void;
  track: (event: HeapEventName, properties: HeapEventProperties) => void;
  addUserProperties: (properties: HeapUserProperties) => void;
  resetIdentity: () => void;
};

const defaultError = "Heap context has not been initialized.";
const defaultWarn = "Heap is not initialized.";

const getHeapReference = () => {
  const heapWindow = (window as unknown) as {
    heap: {
      identify: (profileId: string) => void;
      track: (event: string, properties: HeapEventProperties) => void;
      addUserProperties: (properties: HeapUserProperties) => void;
      resetIdentity: () => void;
      identity: string | undefined;
    };
  };

  return heapWindow?.heap;
};

const initialState: HeapContextType = {
  initialized: false,
  getIdentity: () => {
    throw new Error(defaultError);
  },
  checkForProfileId: () => {
    throw new Error(defaultError);
  },
  identify: () => {
    throw new Error(defaultError);
  },
  track: () => {
    throw new Error(defaultError);
  },
  addUserProperties: () => {
    throw new Error(defaultError);
  },
  resetIdentity: () => {
    throw new Error(defaultError);
  },
};

const HeapContext = React.createContext(initialState);
export const useHeapContext = () => React.useContext(HeapContext);

export default function HeapProvider({ children }: Props) {
  const apolloClient = useApolloClient();
  const [state, setState] = React.useState<HeapContextType>(initialState);

  useMountEffect(() => {
    if (getHeapReference()) {
      setState({
        ...state,
        initialized: true,
      });
    } else {
      console.warn("HeapContext useMountEffect: ", defaultWarn);
    }
  });

  const resetIdentity = () => {
    const heapReference = getHeapReference();

    if (heapReference) {
      heapReference.resetIdentity();
    } else {
      console.warn("HeapContext resetIdentity: ", defaultWarn);
    }
  };

  const getIdentity = () => {
    const heapReference = getHeapReference();
    return heapReference ? heapReference.identity : undefined;
  };

  const identify = (profileId: string) => {
    const heapReference = getHeapReference();

    if (heapReference) {
      heapReference.identify(profileId);

      heapReference.addUserProperties({
        "Almi ID": profileId,
      });
    } else {
      console.warn("HeapContext identity: ", defaultWarn);
    }
  };

  const checkForProfileId = async (email: string) => {
    const heapReference = getHeapReference();

    if (heapReference) {
      try {
        const { data } = await apolloClient.query({
          query: FETCH_PROFILE_ID,
          variables: {
            email,
          },
          fetchPolicy: "no-cache",
        });

        if (data && data.profileIdForEmail) {
          identify(data.profileIdForEmail);
        }
      } catch (error) {
        console.warn("HeapContext checkForProfileId: ", error);
      }
    } else {
      console.warn("HeapContext checkForProfileId: ", defaultWarn);
    }
  };

  const track = (event: HeapEventName, properties: HeapEventProperties) => {
    const heapReference = getHeapReference();

    if (heapReference) {
      heapReference.track(event, properties);
    } else {
      console.warn("HeapContext track: ", defaultWarn);
    }
  };

  const addUserProperties = (properties: HeapUserProperties) => {
    const heapReference = getHeapReference();

    if (heapReference) {
      heapReference.addUserProperties(properties);
    } else {
      console.warn("HeapContext addUserProperties: ", defaultWarn);
    }
  };

  return (
    <HeapContext.Provider
      value={{
        ...state,
        checkForProfileId,
        identify,
        getIdentity,
        track,
        addUserProperties,
        resetIdentity,
      }}
    >
      {children}
    </HeapContext.Provider>
  );
}
