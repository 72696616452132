import { Option, Select, useFieldState, useFormApi } from "informed";
import React from "react";
import gql from "graphql-tag";
import CreateSinglePayment from "~/screens/Portal/AdminDashboard/AdminCreateSinglePayment";
import CancelRecurringPayment from "~/screens/Portal/AdminDashboard/AdminCancelRecurringPayment";
import UpdateRecurringPayment from "~/screens/Portal/AdminDashboard/AdminUpdateRecurringPayment";
import { Bold, H4, Text } from "~/components/Typography";
import { useQueryThrowError } from "~/helpers/queryWithErrorHandling";
import Loading from "~/components/Loading";
import styles from "./index.module.scss";
import AlertImage from "~/assets/images/alert.png";

const GET_PORTAL_INFO = gql`
  {
    getPortalInfo {
      individualProfile {
        isAdmin
      }
    }
  }
`;

const ADMIN_CONTROL = {
  createSinglePayment: {
    url: "create-single-payment",
    title: "Create Single Payment",
  },
  updateRecurringPayment: {
    url: "update-recurring-payment",
    title: "Update Recurring Payment",
  },
  cancelRecurringPayment: {
    url: "cancel-recurring-payment",
    title: "Cancel Recurring Payment",
  },
};

const AdminDashboard = () => {
  const formApi = useFormApi();
  const controlFieldName = "Admin-ControlSelectField";
  const controlFieldState = useFieldState(controlFieldName);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();
  const { loading, data } = useQueryThrowError(GET_PORTAL_INFO);

  React.useEffect(() => {
    if (success) {
      formApi.reset();
    }
  }, [success, formApi]);

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleError = (message: string | undefined, code?: string) => {
    if (message) {
      const messageSplit = message.split(":");
      const parsedMessage = messageSplit[messageSplit.length - 1].trim();
      setError(parsedMessage);
      window.scroll({ top: 0 });
    } else {
      setError(undefined);
    }

    if (code === "NOT_ADMIN") {
      window.location.reload();
    }
  };

  const getControlComponent = () => {
    switch (controlFieldState?.value) {
      case ADMIN_CONTROL.createSinglePayment.url:
        return (
          <CreateSinglePayment
            onSuccess={handleSuccess}
            onError={handleError}
          />
        );
      case ADMIN_CONTROL.updateRecurringPayment.url:
        return (
          <UpdateRecurringPayment
            onSuccess={handleSuccess}
            onError={handleError}
          />
        );
      case ADMIN_CONTROL.cancelRecurringPayment.url:
        return (
          <CancelRecurringPayment
            onSuccess={handleSuccess}
            onError={handleError}
          />
        );
    }

    return undefined;
  };

  if (loading) {
    return (
      <div className={styles.Loading}>
        <Loading />
      </div>
    );
  } else if (data?.getPortalInfo?.individualProfile?.isAdmin) {
    return (
      <div className={styles.Container}>
        <Select
          className={styles.Dropdown}
          field={controlFieldName}
          onChange={() => {
            setError(undefined);
            setSuccess(false);
          }}
        >
          <Option value={""} disabled>
            Select A Control
          </Option>

          <Option value={ADMIN_CONTROL.createSinglePayment.url}>
            {ADMIN_CONTROL.createSinglePayment.title}
          </Option>

          <Option value={ADMIN_CONTROL.updateRecurringPayment.url}>
            {ADMIN_CONTROL.updateRecurringPayment.title}
          </Option>

          <Option value={ADMIN_CONTROL.cancelRecurringPayment.url}>
            {ADMIN_CONTROL.cancelRecurringPayment.title}
          </Option>
        </Select>

        <hr />

        {success ? (
          <H4 className={styles.Success}>Operation Successful!</H4>
        ) : undefined}

        {error ? <H4 className={styles.Error}>{error}</H4> : undefined}

        {getControlComponent()}
      </div>
    );
  } else {
    return (
      <div className={styles.NoAccessWrapper}>
        <img className={styles.NoAccessAlert} src={AlertImage} alt="Alert" />
        <H4 className={styles.NoAccessHeading}>Access Denied</H4>

        <Text className={styles.NoAccessText}>
          Unfortunately you do not have access to this link. Please contact us
          at <Bold>almi.bb/support</Bold> for access.
        </Text>
      </div>
    );
  }
};

export default AdminDashboard;
