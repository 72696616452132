import React from "react";
import { H3, Text } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import Button from "~/components/Button";
import { useHistory } from "react-router-dom";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import styles from "./style.module.scss";

import TileCard from "~/components/TileCard";
import Checkbox from "~/components/Checkbox";
import classnames from "classnames";
import Icon from "~/components/Icon";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import LoadingWrapper from "~/components/LoadingWrapper";

type Condition = {
  field: string;
  label: string;
  selected: boolean;
};

type ConditionsContainer = { [index: string]: Condition };

const POSSIBLE_CONDITIONS: ConditionsContainer = {
  VisionOrHearing: {
    field: "VisionOrHearing",
    label: "Vision or hearing",
    selected: false,
  },
  DiabetesOrLossOfConciousness: {
    field: "DiabetesOrLossOfConciousness",
    label: "Diabetes or loss of conciousness",
    selected: false,
  },
  PhysicalOrMentalDisabilities: {
    field: "PhysicalOrMentalDisabilities",
    label: "Physical or mental disabilities",
    selected: false,
  },
  HeartComplaints: {
    field: "HeartComplaints",
    label: "Heart complaints",
    selected: false,
  },
};

type Props = {
  nextPath?: string;
};

export default function HealthConditions({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const [saving, setSaving] = React.useState<boolean>(false);
  const [conditions, setConditions] = React.useState(POSSIBLE_CONDITIONS);

  useMountEffect(() => {
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 3,
      title: "Driver's History",
    });
  });

  React.useEffect(() => {
    if (Array.isArray(checklistCtx?.healthConditions)) {
      const newList = Object.keys(conditions).reduce((acc, key) => {
        const isSelected = checklistCtx.healthConditions?.includes(key);
        return {
          ...acc,
          [key]: { ...conditions[key], selected: isSelected },
        };
      }, {});
      setConditions(newList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelected = (value: string) => {
    const newList = Object.keys(conditions).reduce((acc, key) => {
      const isSelected = conditions[key].selected;
      if (key === value) {
        return {
          ...acc,
          [key]: { ...conditions[key], selected: !isSelected },
        };
      }
      return {
        ...acc,
        [key]: { ...conditions[key] },
      };
    }, {});
    setConditions(newList);
  };

  const onNextClick = async () => {
    try {
      if (nextPath) {
        setSaving(true);
        const selectedConditions = Object.keys(conditions).filter(
          (key) => conditions[key].selected
        );
        await checklistCtx.saveChecklist({
          policyId: checklistCtx.policyId,
          healthConditions: selectedConditions?.length
            ? selectedConditions
            : ["None"],
          nextPath,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      console.warn(error);
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H3>
            Do you, or anyone driving, suffer from any health conditions listed
            below?
          </H3>

          <Text className={styles.SubTitle}>
            Select all that apply. If you and anyone else driving have none of
            the listed conditions below, please skip this step.
          </Text>

          <div className={styles.HealthConditionsContainer}>
            {Object.keys(conditions).map((key, index) => {
              const condition = conditions[key];
              return (
                <TileCard
                  key={`HealthConditions-TileCard-${index}`}
                  title={condition.label}
                  inputId={`HealthConditions-TileCard-${index}`}
                  checked={condition.selected}
                  inputElement={
                    <Checkbox
                      id={`HealthConditions-TileCard-${index}`}
                      field="healthCondition"
                      className={styles.HideCheckbox}
                      onChange={() => onSelected(key)}
                    />
                  }
                  left={
                    <>
                      <div
                        className={classnames(styles.CheckBoxIcon, {
                          [styles.Checked]: condition.selected,
                        })}
                      >
                        {condition.selected && (
                          <Icon name="CheckSquare" backgroundColor="#FF6B28" />
                        )}
                      </div>
                    </>
                  }
                  className={styles.TileCard}
                />
              );
            })}
          </div>

          <Button className={styles.ContinueButton} onClick={onNextClick}>
            {Object.keys(conditions).filter((key) => conditions[key].selected)
              ?.length
              ? "Continue"
              : "Skip"}
          </Button>
        </>
      )}
    </>
  );
}
