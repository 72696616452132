import React from "react";
import { useHistory } from "react-router-dom";
import { useFormApi } from "informed";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import styles from "./index.module.scss";
import { H3, TextSmall } from "~/components/Typography";
import Button from "~/components/Button";
import NoteWithList from "~/components/NoteWithList";
import pushWithParams from "~/helpers/pushWithParams";
import { format } from "date-fns";
import { localDateFromIso } from "~/helpers/dates";

export default function CoverageConfirmation({
  nextPath,
}: {
  nextPath?: string;
}) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const onboardCtx = useOnboardContext();
  const form = useFormApi();

  const handleNext = async () => {
    await homeCtx.removeStore();
    await onboardCtx.removeStore();
    await form.reset();
    if (nextPath) {
      pushWithParams(history, nextPath);
    } else {
      pushWithParams(history, "/portal");
    }
  };

  return (
    <div className={styles.Content}>
      <H3 className={styles.Title} component="h1">
        You&apos;re set to be insured
        {homeCtx.coverageStart &&
          ` on ${format(
            localDateFromIso(homeCtx.coverageStart),
            "MMM dd, yyyy"
          )}.`}
      </H3>
      <TextSmall>
        To complete your home coverage plan onboarding you will need to provide
        some additional information.
      </TextSmall>
      <NoteWithList
        mainText={`You will need to upload a photo of the following to complete your Home Coverage Plan onboarding:`}
        list={["A recent property valuation from an appraiser"]}
        className={styles.Note}
      />
      <div className={styles.BtnRow}>
        <Button onClick={handleNext} className={styles.BtnNext} autoFocus>
          Complete coverage plan
        </Button>
      </div>
    </div>
  );
}
