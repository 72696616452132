import React, { useState } from "react";
import { ENVIRONMENTS } from "~/helpers/constants";
import NoAccess from "../NoAccess";

type PropsType = {
  children: React.ReactElement;
};

const AccessRoute = ({ children }: PropsType) => {
  const [isGranted, setIsGranted] = useState(
    localStorage.getItem("accessKey") === process.env.REACT_APP_ACCESS_KEY
  );

  const isLocal = process.env.REACT_APP_ENV === ENVIRONMENTS.local;

  const handleAccess = (access: boolean) => {
    setIsGranted(access);
  };

  return (
    <>
      {isGranted || isLocal ? (
        children
      ) : (
        <NoAccess handleAccess={handleAccess} />
      )}
    </>
  );
};

export default AccessRoute;
