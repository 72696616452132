import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import { H3 } from "~/components/Typography";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useFieldState } from "informed";
import styles from "./index.module.scss";
import Checkbox from "~/components/Checkbox";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import hasErrorCode from "~/helpers/hasErrorCode";
import cleanNumberInput from "~/helpers/formatters/cleanNumberInput";

enum Field {
  PolicyId = "AdminUpdateRecurringPayment-PolicyId",
  PaymentAmount = "AdminUpdateRecurringPayment-PaymentAmount",
  ExistingCC = "AdminUpdateRecurringPayment-ExistingCC",
}

const hostedPageUpdateURL = process.env.REACT_APP_FAC_HOSTED_PAGE_UPDATE_URL;

const UPDATE_RECURRING_PAYMENT_EXISTING_CC = gql`
  mutation adminUpdateRecurringPaymentWithExistingCC(
    $policyId: String!
    $paymentAmount: Float!
  ) {
    adminUpdateRecurringPaymentWithExistingCC(
      policyId: $policyId
      paymentAmount: $paymentAmount
    )
  }
`;

const UPDATE_RECURRING_PAYMENT_NEW_CC = gql`
  mutation adminUpdateRecurringPaymentWithNewCC(
    $policyId: String!
    $paymentAmount: Float!
  ) {
    adminUpdateRecurringPaymentWithNewCC(
      policyId: $policyId
      paymentAmount: $paymentAmount
    )
  }
`;

type Props = {
  onSuccess: () => void;
  onError: (errorMessage: string | undefined, errorCode?: string) => void;
};

const AdminUpdateRecurringPayment = ({ onSuccess, onError }: Props) => {
  const policyIdState = useFieldState(Field.PolicyId);
  const paymentAmountState = useFieldState(Field.PaymentAmount);
  const existingCCState = useFieldState(Field.ExistingCC);
  const [securityToken, setSecurityToken] = React.useState<string>();
  const [processing, setProcessing] = React.useState<boolean>(false);

  const [updateRecurringPaymentExistingCC] = useMutation(
    UPDATE_RECURRING_PAYMENT_EXISTING_CC
  );

  const [updateRecurringPaymentNewCC] = useMutation(
    UPDATE_RECURRING_PAYMENT_NEW_CC
  );

  useMountEffect(() => {
    // Set up a message listener on mount and remove it on unmount
    const messageListener = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      if (event.data.messageType === "result-clicked-next") {
        onSuccess();
      }

      if (
        event.data.messageType === "result-error" ||
        event.data.messageType === "checkout-error"
      ) {
        onError(event.data.error.message);
      }
    };

    window.addEventListener("message", messageListener, false);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  });

  const handleSubmit = async () => {
    setProcessing(true);
    onError(undefined);

    try {
      const response = existingCCState.value
        ? await updateRecurringPaymentExistingCC({
            variables: {
              policyId: String(policyIdState.value),
              paymentAmount: cleanNumberInput(paymentAmountState.value),
            },
          })
        : await updateRecurringPaymentNewCC({
            variables: {
              policyId: String(policyIdState.value),
              paymentAmount: cleanNumberInput(paymentAmountState.value),
            },
          });

      setProcessing(false);

      if (response?.data?.adminUpdateRecurringPaymentWithNewCC) {
        setSecurityToken(response.data.adminUpdateRecurringPaymentWithNewCC);
      } else if (response?.data?.adminUpdateRecurringPaymentWithExistingCC) {
        onSuccess();
      }
    } catch (error) {
      onError(
        error.message,
        hasErrorCode(error, "NOT_ADMIN") ? "NOT_ADMIN" : undefined
      );

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      <H3>Update Recurring Payment</H3>

      {!securityToken ? (
        <>
          <TextInput
            type="text"
            label="Policy ID"
            placeholder="AL-000000-AAAAAA"
            field={Field.PolicyId}
          />

          <TextInput
            type="currency"
            label="Payment Amount"
            placeholder="$ 0.00"
            field={Field.PaymentAmount}
          />

          <Checkbox field={Field.ExistingCC} label="Use Credit Card On File" />

          <Button
            disabled={
              !policyIdState.value || !paymentAmountState.value || processing
            }
            onClick={handleSubmit}
          >
            {processing ? "Processing..." : "Process"}
          </Button>
        </>
      ) : undefined}

      {securityToken ? (
        <div className={styles.Wrapper}>
          <iframe
            className={styles.Iframe}
            title="FAC-Payment"
            src={`${hostedPageUpdateURL}${securityToken}`}
          />
        </div>
      ) : undefined}
    </div>
  );
};

export default AdminUpdateRecurringPayment;
