import React, { memo, useState, useEffect, useRef } from "react";
import { useHistory, matchPath } from "react-router-dom";
import gql from "graphql-tag";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { parse } from "query-string";
import jwtDecode from "jwt-decode";

import { useMotorContext } from "~/contexts/MotorProvider";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import { useAuth0Context } from "~/contexts/Auth0Context";
import { useUserContext } from "~/contexts/UserProvider";

import { TextSmall } from "~/components/Typography";

import VehicleSearchDisplay from "~/components/VehicleSearchDisplay";
import PolicySummaryCard from "~/components/PolicySummaryCard";
import MotorPlanDetail from "~/components/PlanDetail/MotorPlanDetail";
//import Button from "~/components/Button";
import formMotorPolicyPayload from "~/helpers/formMotorPolicyPayload";

import Loading from "~/components/Loading";
import { Quote } from "~/contexts/MotorProvider/PolicyInfo";
import CoveredList from "./CoveredList";
import { CountryCode, InsuranceType, PolicyStatus } from "../../../types";
import pushWithParams from "~/helpers/pushWithParams";
import useHasRequiredData from "~/helpers/useHasRequiredData";
import { useQueryThrowError } from "~/helpers/queryWithErrorHandling";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import { saveItem } from "~/helpers/localStorage";
import Note from "~/components/Note";
import { POLICY_STATUS } from "~/helpers/constants";

import styles from "./index.module.scss";
import { HeapEventName, useHeapContext } from "~/contexts/HeapProvider";
import RadioButton from "~/components/RadioButton";
import { RadioGroup } from "informed";

import { useUpdateSupport } from "~/helpers/useUpdateSupport";

export type PathMatchType = {
  params: { countryCode: CountryCode; insuranceType: InsuranceType };
};

const GET_POLICY = gql`
  query($getGeneralPolicyInput: String!) {
    getGeneralPolicy(input: $getGeneralPolicyInput) {
      status
    }
  }
`;

/*
TODO: getGeneralPolicy query returning generatedQuotes should be able to replace the autoQuotes query
      not sure if it is required to build a new quote at this point or if the quotes in the DB
      are good to use at this point
*/
const AUTO_QUOTES = gql`
  query($proposal: AutoPolicyProposalInput!) {
    autoQuotes(proposal: $proposal) {
      typeOfCover
      basePremiumValue
      netPremiumValue
      taxValue
      totalValue
      coverage {
        peril
        coveredValue
        excessValue
        isVariableExcess
      }
      valueByNumberOfInstallments {
        twelve
      }
    }
  }
`;

export const SAVE_POLICY = gql`
  mutation SavePolicy($savePolicyInput: SavePolicyInput!) {
    savePolicy(input: $savePolicyInput)
  }
`;

const NUMBER_OF_PAYMENTS = {
  twelve: 12,
  one: 1,
};

const MAX_COMPREHENSIVE_CAR_YEARS = 15;

function CoveragePlans({ nextPath }: { nextPath?: string }) {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const motorCtx = useMotorContext();
  const onboardCtx = useOnboardContext();
  const userCtx = useUserContext();
  const heapCtx = useHeapContext();
  const auth0 = useAuth0Context();
  const ref = useRef();
  const [showDetail, setShowDetail] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState<Quote>();
  const [currentDetailIndex, setCurrentDetailIndex] = useState<number>(0);
  const [, setError] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const id = motorCtx.policyInfo.data.id;

  const { data: policyData, loading: policyLoading } = useQuery(GET_POLICY, {
    variables: {
      getGeneralPolicyInput: id,
    },
    skip: !id,
  });

  const status = policyData?.getGeneralPolicy.status;

  useHasRequiredData({
    requiredData: [],
    policyRequiredStatus: POLICY_STATUS.quote as PolicyStatus,
    policyStatus: status,
    skip: !status || complete,
  });

  useUpdateAssistant({
    isOpen: true,
    text: (
      <TextSmall>With Almi you can enjoy 0% interest on instalments</TextSmall>
    ),
  });

  const searchParams = parse(history.location.search);

  const pathMatch: PathMatchType | null = matchPath(history.location.pathname, {
    path: "/:countryCode/:insuranceType/:step",
    exact: true,
    strict: false,
  });

  const proposal = {
    branch: "BB",
    coverageStart: motorCtx.policyInfo.data.coverageStart,
    engineSize: motorCtx.vehicleInfo.data.engineSize,
    vehicleValue: motorCtx.vehicleInfo.data.value,
    vehicleYear: motorCtx.vehicleInfo.data.year,
    vehicleMake: motorCtx.vehicleInfo.data.make,
    vehicleModel: motorCtx.vehicleInfo.data.model,
    vehicleIsSportsCar: motorCtx.vehicleInfo.data.isSportsCar,
    yearsWithoutClaims: motorCtx.policyInfo.data.yearsWithoutClaims,
    occupation: motorCtx.savingsInfo.data.occupation,
    association: motorCtx.savingsInfo.data.association,
    driverAge: motorCtx.policyInfo.data.age,
    driverExperience: motorCtx.policyInfo.data.yearsDriving,
    isShiftWorker: motorCtx.policyInfo.data.isShiftWorker === "yes",
    isEngineModified: motorCtx.vehicleInfo.data.isEngineModified === "yes",
    isLeftSide: motorCtx.vehicleInfo.data.isLeftSide === "yes",
    drivers: motorCtx.policyInfo.data.drivers,
  };

  const { loading, data } = useQueryThrowError(AUTO_QUOTES, {
    variables: {
      emailQuoteInput: {
        email: userCtx.email,
        policyId: motorCtx.policyInfo.data.id,
      },
      proposal,
    },
    onCompleted: () =>
      motorCtx.policyInfo.data.id &&
      saveItem("verifyEmailId", motorCtx.policyInfo.data.id),
  });

  const comprehensiveQuote =
    data && data.autoQuotes.find((quote: Quote) => quote.typeOfCover === "C");
  const thirdPartyQuote =
    data && data.autoQuotes.find((quote: Quote) => quote.typeOfCover === "T");
  const comprehensiveLiteQuote =
    data && data.autoQuotes.find((quote: Quote) => quote.typeOfCover === "L");

  const quotes: Quote[] = [
    thirdPartyQuote,
    comprehensiveLiteQuote,
    comprehensiveQuote,
  ].filter((e) => !!e);

  const oldVehicleMessage =
    thirdPartyQuote &&
    quotes.length === 1 &&
    (motorCtx.vehicleInfo.data.year ?? 0) + MAX_COMPREHENSIVE_CAR_YEARS <
      new Date().getFullYear()
      ? `Since your ${motorCtx.vehicleInfo.data.make} is older than ${MAX_COMPREHENSIVE_CAR_YEARS} years, we’re unable to offer you comprehensive coverage.`
      : "";

  const handleHeapEvent = () => {
    heapCtx.track(HeapEventName.MOTOR_PLAN_SELECTION, {
      "Policy ID": motorCtx.policyInfo.data.id,
      "Plan Type": motorCtx.policyInfo.data.typeOfCover,
      "Payment Type": onboardCtx.paymentIsRecurring ? "Recurring" : "Lump Sum",
    });
  };

  const createClickHandler = (quote: Quote) => {
    return async () => {
      motorCtx.policyInfo.setItem("typeOfCover", quote.typeOfCover);
      motorCtx.policyInfo.setItem("basePremiumValue", quote.basePremiumValue);
      motorCtx.policyInfo.setItem("netPremiumValue", quote.netPremiumValue);
      motorCtx.policyInfo.setItem("taxValue", quote.taxValue);
      motorCtx.policyInfo.setItem("totalValue", quote.totalValue);

      motorCtx.policyInfo.setItem("coverage", quote.coverage);
      motorCtx.policyInfo.setItem(
        "valueByNumberOfInstallments",
        quote.valueByNumberOfInstallments
      );
      motorCtx.policyInfo.setItem("generatedQuotes", quotes);

      if (pathMatch) {
        const policyPayload = formMotorPolicyPayload(
          motorCtx,
          userCtx.email,
          !!onboardCtx.paymentIsRecurring,
          pathMatch.params.countryCode,
          false
        );
        try {
          const policyMutation = await apolloClient.mutate({
            mutation: SAVE_POLICY,
            variables: {
              savePolicyInput: {
                policy: policyPayload,
              },
            },
          });
          if (policyMutation.data.savePolicy) {
            if (!auth0.isLoading()) {
              if (auth0.token) {
                const tokenObj: { email: string } = jwtDecode(
                  auth0.token.idToken
                );

                if (userCtx.email === tokenObj.email) {
                  if (pathMatch) {
                    setComplete(true);
                    handleHeapEvent();
                    pushWithParams(
                      history,
                      `/${pathMatch.params.countryCode}/${pathMatch.params.insuranceType}/engine-and-chassis`
                    );
                    return;
                  }
                }
              } else {
                setComplete(true);
                motorCtx.policyInfo.setItem(
                  "id",
                  policyMutation.data.savePolicy
                );
              }
            }
            if (nextPath) {
              handleHeapEvent();
              history.push(nextPath);
            }
          }
        } catch (error) {
          // Make error boundary catch this
          setError(() => {
            throw error;
          });
          setShowLoading(false);
        }
      }
    };
  };

  const handleOnSelectPlan = () => {
    if (selectedQuote) {
      setShowLoading(true);
      setShowDetail(false);
      createClickHandler(selectedQuote)();
    }
  };

  const handleOnDetails = (quote: Quote, index: number) => {
    setSelectedQuote(quote);
    setShowDetail(true);
    setCurrentDetailIndex(index);
  };

  useEffect(() => {
    if (
      searchParams.type &&
      typeof searchParams.type === "string" &&
      !loading &&
      !ref.current
    ) {
      const coverIndexes = {
        T: 0,
        L: 1,
        C: 2,
      };
      const foundQuote = data.autoQuotes.find((quote: Quote) => {
        return quote.typeOfCover === searchParams.type;
      });
      if (!selectedQuote || selectedQuote?.typeOfCover === searchParams.type) {
        ref.current = foundQuote.typeOfCover;
        handleOnDetails(foundQuote, coverIndexes[searchParams.type]);
      }
    }
  }, [searchParams, data, selectedQuote, loading]);

  const handleOnNext = () => {
    const newIndex = currentDetailIndex + 1;
    setCurrentDetailIndex(newIndex);
    const newQuote = quotes[newIndex];
    setSelectedQuote(newQuote);
  };

  const handleOnPrev = () => {
    const newIndex = currentDetailIndex - 1;
    setCurrentDetailIndex(newIndex);
    const newQuote = quotes[newIndex];
    setSelectedQuote(newQuote);
  };

  const handleOnSelectedPayment = (isRecurring: boolean) => {
    onboardCtx.setState({
      paymentIsRecurring: isRecurring,
      // TODO: we need to better handle the number of payments
      paymentNumberOfRecurrences: isRecurring
        ? NUMBER_OF_PAYMENTS.twelve
        : NUMBER_OF_PAYMENTS.one,
    });
  };

  useUpdateSupport({ isOpen: true });

  if (loading || showLoading || policyLoading)
    return (
      <div className={styles.LoadingWrapper}>
        <Loading />
      </div>
    );
  return (
    <>
      <div className={styles.CoveragePlansContent}>
        <div className={styles.HeadingScreen}>
          <TextSmall component="h1">
            {quotes.length > 1
              ? `Here are ${quotes.length} coverage plans for your`
              : `Here is the plan for your`}
          </TextSmall>

          <VehicleSearchDisplay display={["year", "make", "model"]} />
        </div>

        <div
          className={styles.SelectorButtons}
          role="tablist"
          aria-label="Coverage Plans"
        >
          <RadioGroup
            field="12-month instalments"
            initialValue={
              onboardCtx.paymentIsRecurring
                ? "12-month instalments"
                : "One-time payment"
            }
          >
            <RadioButton
              value="12-month instalments"
              field="12-month instalments"
              aria-selected={onboardCtx.paymentIsRecurring}
              aria-controls="instalment-content"
              label="12-month instalments"
              onClick={() => handleOnSelectedPayment(true)}
            />
            <RadioButton
              value="One-time payment"
              field="One-time payment"
              aria-selected={!onboardCtx.paymentIsRecurring}
              aria-controls="annual-content"
              label="One-time payment"
              onClick={() => handleOnSelectedPayment(false)}
            ></RadioButton>
          </RadioGroup>
        </div>

        {oldVehicleMessage && (
          <div className={styles.DesktopNoteWrapper}>
            <Note className={styles.DesktopNote} mainText={oldVehicleMessage} />
          </div>
        )}

        <div
          className={styles.CoveragePlanCards}
          id={
            onboardCtx.paymentIsRecurring
              ? "instalment-content"
              : "annual-content"
          }
        >
          {comprehensiveLiteQuote ? (
            <PolicySummaryCard
              title="Comprehensive Lite"
              subTitle={`Intermediate Coverage`}
              installmentCount={onboardCtx.paymentNumberOfRecurrences || 1}
              installmentValue={
                onboardCtx.paymentIsRecurring
                  ? comprehensiveLiteQuote.valueByNumberOfInstallments.twelve
                  : comprehensiveLiteQuote.totalValue
              }
              columns={3}
              onClickDetails={() => handleOnDetails(comprehensiveLiteQuote, 1)}
            >
              <CoveredList quote={comprehensiveLiteQuote} />
            </PolicySummaryCard>
          ) : null}

          {comprehensiveQuote ? (
            <PolicySummaryCard
              title="Comprehensive"
              subTitle="Complete Coverage"
              variant="highlight"
              installmentCount={onboardCtx.paymentNumberOfRecurrences || 1}
              installmentValue={
                onboardCtx.paymentIsRecurring
                  ? comprehensiveQuote.valueByNumberOfInstallments.twelve
                  : comprehensiveQuote.totalValue
              }
              // eslint-disable-next-line no-magic-numbers
              onClickDetails={() => handleOnDetails(comprehensiveQuote, 2)}
            >
              <CoveredList quote={comprehensiveQuote} />
            </PolicySummaryCard>
          ) : null}

          {thirdPartyQuote ? (
            <PolicySummaryCard
              title={"Third Party"}
              subTitle="Basic Coverage"
              className={quotes.length === 1 ? styles.SinglePlan : ""}
              installmentCount={onboardCtx.paymentNumberOfRecurrences || 1}
              installmentValue={
                onboardCtx.paymentIsRecurring
                  ? thirdPartyQuote.valueByNumberOfInstallments.twelve
                  : thirdPartyQuote.totalValue
              }
              variant="common"
              columns={3}
              onClickDetails={() => handleOnDetails(thirdPartyQuote, 0)}
            >
              <CoveredList quote={thirdPartyQuote} />
            </PolicySummaryCard>
          ) : null}
          {oldVehicleMessage && (
            <Note className={styles.MobileNote} mainText={oldVehicleMessage} />
          )}
        </div>
      </div>
      {selectedQuote ? (
        <MotorPlanDetail
          isOpen={showDetail}
          quote={selectedQuote}
          // TS-ignoring here, all types and checks are explicit, similar code exists above
          // @ts-ignore
          installmentCount={onboardCtx.paymentNumberOfRecurrences || 1}
          installmentValue={
            onboardCtx.paymentIsRecurring
              ? selectedQuote?.valueByNumberOfInstallments.twelve
              : selectedQuote?.totalValue
          }
          vehicleValue={motorCtx.vehicleInfo.data.value}
          onCloseModal={() => setShowDetail(false)}
          onSelectPlan={handleOnSelectPlan}
          onPrev={
            quotes.length > 1 && currentDetailIndex > 0
              ? handleOnPrev
              : undefined
          }
          onNext={
            quotes.length > 1 && currentDetailIndex < quotes.length - 1
              ? handleOnNext
              : undefined
          }
        />
      ) : null}
    </>
  );
}

export default memo(CoveragePlans);
