import React from "react";
import { Bold, TextSmall } from "~/components/Typography";
import { CoverageObjType } from "../types";
import { formatCurrency } from "./currency";

export type KeyContent =
  | "comprehensiveLiteQuote"
  | "comprehensiveQuote"
  | "thirdPartyQuote"
  | "standardQuote";

type ContentCardsProps = {
  excessValue: string;
  coveredValue: string;
};

export const CONTENT_CARDS = {
  thirdPartyQuote: {
    summary: `Our Essential Plan offering third party insurance covers damages caused to another person's vehicle or property in an accident that's your fault.`,
    covered: {
      "third-party-casualty": {
        text: (
          props: ContentCardsProps
        ) => `If you have an accident involving one other person, you'll be covered up to a maximum of ${props.coveredValue}  in costs owed to the other party.
      `,
      },
      "third-party-casualties": {
        text: (
          props: ContentCardsProps
        ) => `If you have an accident involving any number of persons, you'll be covered up to a maximum of ${props.coveredValue} in costs owed to the other party.
          `,
      },
      "third-party-property": {
        text: (
          props: ContentCardsProps
        ) => `If you have an accident causing damage to another person's property, you'll be covered up to a maximum of ${props.coveredValue}  in costs owed to the other party.
      `,
      },
    },
    notCovered: [
      {
        text: `If your car is damaged in a collision, this plan will NOT cover the costs. You will be responsible for the full amount.`,
        youPay: "Full Amount",
        coveredAmount: "$0",
      },
      {
        text: `If your car is damaged outside of a collision, this plan will NOT cover it. You will be responsible for the full amount.`,
        youPay: "Full Amount",
        coveredAmount: "$0",
      },
    ],
  },
  comprehensiveQuote: {
    summary: `Our Complete Plan offering comprehensive insurance covers you for all potential damages at an affordable price. This includes getting you a rental car if you need one.
     `,
    covered: {
      "third-party-casualty": {
        text: (props: ContentCardsProps) => `
        If you have an accident involving one other person, you'll be covered up to a maximum of ${props.coveredValue}  in costs owed to the other party.
          `,
      },
      "third-party-casualties": {
        text: (
          props: ContentCardsProps
        ) => `If you have an accident involving any number of persons, you'll be covered up to a maximum of ${props.coveredValue} in costs owed to the other party.
          `,
      },
      "third-party-property": {
        text: (
          props: ContentCardsProps
        ) => `If you have an accident causing damage to another person's property, you'll be covered up to a maximum of ${props.coveredValue} in costs owed to the other party.
          `,
      },
      "own-vehicle": {
        text: (props: ContentCardsProps) =>
          `If you have an accident, you’ll have to pay up to ${props.excessValue} in repair costs for your vehicle. You are covered up to a maximum of ${props.coveredValue}.`,
      },
    },
    notCovered: [
      {
        text: `Comprehensive insurance doesn't cover things like legal fees resulting from an accident or damages caused by someone driving without a license.`,
      },
    ],
  },
  comprehensiveLiteQuote: {
    summary: `Our Balanced Plan offering comprehensive insurance covers you for all accidents and damages at an affordable price. The only thing you'll need to cover is how to get around while your vehicle is being repaired or replaced.
      `,
    covered: {
      "third-party-casualty": {
        text: (
          props: ContentCardsProps
        ) => `If you have an accident involving one other person, you'll be covered up to a maximum of ${props.coveredValue} in costs owed to the other party.
          `,
      },
      "third-party-casualties": {
        text: (
          props: ContentCardsProps
        ) => `If you have an accident involving any number of persons, you'll be covered up to a maximum of ${props.coveredValue} in costs owed to the other party.
          `,
      },
      "third-party-property": {
        text: (
          props: ContentCardsProps
        ) => `If you have an accident causing damage to another person's property, you'll be covered up to a maximum of ${props.coveredValue} in costs owed to the other party.
          `,
      },
      "own-vehicle": {
        text: (props: ContentCardsProps) =>
          `If you have an accident, you’ll have to pay up to ${props.excessValue} in repair costs for your vehicle. You are covered up to a maximum of ${props.coveredValue}.`,
      },
    },
    notCovered: [
      {
        text: `The balanced plan offers comprehensive lite insurance which doesn't include a rental car as part of its package. Comprehensive insurance doesn't cover things like legal fees resulting from an accident or damages caused by someone driving without a license.`,
      },
    ],
  },
  standardQuote: {
    summary: ``,
    covered: {
      "home-natural-disasters": {
        text: (
          <TextSmall>
            <Bold>Natural Disasters:</Bold> In the event of a natural
            disaster—fire, explosion, flood, earthquake— you will be covered.
          </TextSmall>
        ),
      },
      "home-contents-coverage": {
        text: (
          <TextSmall>
            <Bold>Contents Coverage:</Bold> If your possessions get stolen or
            damaged, you will be covered up to the insured amount.
          </TextSmall>
        ),
      },
      "home-all-risks-coverage": {
        text: (
          <TextSmall>
            <Bold>All Risks Coverage:</Bold> If your possessions get broken from
            accidental damage or lost outside your home, you will be covered up
            to the insured amount.
          </TextSmall>
        ),
      },
      "home-theft-burglary": {
        text: (
          <TextSmall>
            <Bold>Theft and Burglary:</Bold> If anyone attempts or breaks into
            your private property and damages or steals items, you’ll be
            covered.
          </TextSmall>
        ),
      },
      "home-accidental-damage": {
        text: (
          <TextSmall>
            <Bold>Accidents:</Bold> If an accident occurs and your property gets
            damaged, you’ll be covered. This includes: <br></br> &bull; &nbsp;
            Accidental breakage of glass <br></br> &bull; &nbsp; Falling
            branches from trees <br></br> &bull; &nbsp; Breakage or collapse of
            radio/television aerials
          </TextSmall>
        ),
      },
    },
    notCovered: [
      {
        text: `If there is loss or damage to a sea wall leading to property damage, you will not be covered and you will have to pay the full amount.`,
      },
    ],
  },
};

export const parseCoveredItems = (
  coverage: CoverageObjType,
  keyContent: KeyContent
) => {
  if (keyContent === "standardQuote") {
    //Home insurance only
    return {
      text: CONTENT_CARDS[keyContent]?.covered[coverage.peril]?.text,
    };
  } else {
    return {
      text: CONTENT_CARDS[keyContent].covered[coverage.peril].text({
        excessValue: formatCurrency(coverage.excessValue, true),
        coveredValue: formatCurrency(coverage.coveredValue, true),
      }),
      coveredAmount: `${formatCurrency(coverage.coveredValue, true)}`,
      youPay: `${formatCurrency(coverage.excessValue, true)}`,
    };
  }
};

export const parseNotCoveredItems = (keyContent: KeyContent) => {
  return CONTENT_CARDS[keyContent].notCovered;
};

export const getTypeOfCoverage = (
  code: string
): { title: string; subTitle: string; keyContent: KeyContent } => {
  switch (code) {
    case "T": {
      return {
        title: "Third Party",
        subTitle: "Basic Coverage",
        keyContent: "thirdPartyQuote",
      };
    }

    case "C": {
      return {
        title: "Comprehensive",
        subTitle: "Complete Coverage",
        keyContent: "comprehensiveQuote",
      };
    }

    case "L": {
      return {
        title: "Comprehensive Lite",
        subTitle: "Intermediate Coverage",
        keyContent: "comprehensiveLiteQuote",
      };
    }

    case "S": {
      return {
        title: "Standard",
        subTitle: "Basic Coverage",
        keyContent: "standardQuote",
      };
    }
  }

  throw new Error("Coverage type not found");
};
