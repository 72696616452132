import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DocumentUpload, {
  DocumentWrapper,
} from "~/screens/Identification/DocumentUpload";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useMotorContext } from "~/contexts/MotorProvider";
import { POLICY_STATUS, DOCUMENT_TYPES } from "~/helpers/constants";
import { PolicyStatus, InsuranceType } from "../../../types";
import useHasRequiredData from "~/helpers/useHasRequiredData";
import Loading from "~/components/Loading";
import styles from "./index.module.scss";

type Props = {
  nextPath?: string;
  insuranceType: InsuranceType;
};

const documentWrappersList: DocumentWrapper[] = [
  {
    name: "Selfie",
    shortDescription: "Tap to add selfie",
    description: "Take a selfie so we can verify your identity",
    documentParts: [
      {
        name: "Face",
        accept: "image/*",
        context: "face",
        documentType: DOCUMENT_TYPES.face,
      },
    ],
    verify: true,
  },
  {
    name: "Driver license",
    description: "Upload proof of driver's license",
    shortDescription: "Tap to see document inputs",
    minNumberOfOptionalDocs: 1,
    optionalDocuments: [
      {
        name: "Driver license",
        shortDescription: "Tap to add document",
        description:
          "Upload a photo of the front and back of your driver's license. If your license is expired, please upload a photo of your driver’s slip.",
        documentParts: [
          {
            name: "Driver license front",
            accept: "image/*",
            context: "document-front",
            documentType: DOCUMENT_TYPES.driversLicense,
            preMessage:
              "Please take document photo on a flat surface, in a well-lit location.",
          },
          {
            name: "Driver license back",
            accept: "image/*",
            context: "document-back",
            documentType: DOCUMENT_TYPES.driversLicense,
            preMessage:
              "Please take document photo on a flat surface, in a well-lit location.",
          },
        ],
      },
      {
        name: "License slip",
        shortDescription: "Tap to add document",
        description: "Upload a photo of your driver's slip",
        documentParts: [
          {
            name: "License slip",
            accept: "image/*",
            context: "document-front",
            documentType: DOCUMENT_TYPES.licenseSlip,
            preMessage:
              "Please take document photo on a flat surface, in a well-lit location.",
          },
        ],
      },
    ],
  },
  {
    name: "Identification",
    description: "Upload at least one of the following:",
    shortDescription: "Tap to add documents",
    minNumberOfOptionalDocs: 1,
    optionalDocuments: [
      {
        name: "National ID",
        shortDescription: "Tap to add document",
        description: "Take a photo of your National ID card",
        documentParts: [
          {
            name: "National ID front",
            accept: "image/*",
            context: "document-front",
            documentType: DOCUMENT_TYPES.idCard,
            preMessage:
              "Please take document photo on a flat surface, in a well-lit location.",
          },
          {
            name: "National ID back",
            accept: "image/*",
            context: "document-back",
            documentType: DOCUMENT_TYPES.idCard,
            preMessage:
              "Please take document photo on a flat surface, in a well-lit location.",
          },
        ],
        verify: true,
      },
      {
        name: "Passport",
        shortDescription: "Tap to add document",
        description: "Upload a photo of your Passport",
        documentParts: [
          {
            name: "Passport front",
            accept: "image/*",
            context: "document-front",
            documentType: DOCUMENT_TYPES.passport,
            preMessage:
              "Please take document photo on a flat surface, in a well-lit location.",
          },
        ],
        verify: true,
      },
    ],
  },
  {
    name: "Proof of address",
    shortDescription: "Tap to add document",
    description:
      "Upload a photo of a document with your address (eg. a utility bill or bank statement)",
    documentParts: [
      {
        name: "Proof of address",
        accept: "image/*,application/pdf",
        documentType: DOCUMENT_TYPES.proofAddress,
        preMessage:
          "Please take document photo on a flat surface, in a well-lit location.",
      },
    ],
  },
];

const GET_POLICY = gql`
  query($getGeneralPolicyInput: String!) {
    getGeneralPolicy(input: $getGeneralPolicyInput) {
      status
      externalId
      holder {
        email
      }
    }
  }
`;

const RequiredDocuments = ({ nextPath, insuranceType }: Props) => {
  const motorCtx = useMotorContext();
  const id = motorCtx.policyInfo.data.id;
  const [showUpload, setShowUpload] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (id === undefined) {
      history.replace("/portal");
    }
    // `history` context should never change.
    // `id` should only be checked once, otherwise you will be redirected to `/portal` when logged out
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: policyData, loading } = useQuery(GET_POLICY, {
    variables: {
      getGeneralPolicyInput: id,
    },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  const status = policyData?.getGeneralPolicy.status;

  useHasRequiredData({
    requiredData: [motorCtx.policyInfo.data.id],
    redirectUrl: "/portal",
    policyRequiredStatus: [
      POLICY_STATUS.pendingDocuments,
      POLICY_STATUS.pendingSigning,
    ] as PolicyStatus[],
    policyStatus: status,
    skip: !status,
  });

  useEffect(() => {
    if (nextPath && status === POLICY_STATUS.pendingSigning) {
      history.replace(nextPath);
    } else setShowUpload(true);
  }, [nextPath, status, history]);

  if (loading || !showUpload) {
    return (
      <div className={styles.LoadingWrapper}>
        <Loading />
      </div>
    );
  }
  return (
    <DocumentUpload
      policyId={policyData?.getGeneralPolicy.externalId}
      nextPath={nextPath}
      email={policyData?.getGeneralPolicy.holder.email}
      documentWrappersList={documentWrappersList}
      insuranceType={insuranceType}
    />
  );
};

export default RequiredDocuments;
