import React, { useEffect, useState } from "react";
import cx from "classnames";
import moment from "moment";
import { FormValues } from "informed";
import styles from "./index.module.scss";
import { useHistory } from "react-router-dom";
import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";
import { useModalContext } from "~/contexts/ModalContext";
import { useMotorContext } from "~/contexts/MotorProvider";
import { Text, H3 } from "~/components/Typography";
import Button from "~/components/Button";
import SelectableCard from "~/components/SelectableCard";
import ClaimModal from "./ClaimModal";
import { PreviousClaim } from "../../../types";
import { getOrdinalWord } from "~/helpers/getOrdinalWord";
import { NO_COVERAGE_URL } from "~/routes/index.constant";
import { previousClaimsRange } from "~/helpers/motorQuote";
import { getYearsWithoutClaims } from "~/helpers/getYearsWithoutClaims";
import pushWithParams from "~/helpers/pushWithParams";
import { formatUkDate } from "~/helpers/dates";
import { cleanInputDate } from "~/helpers/validators";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { maskedCurrency } from "~/components/TextInput/index.utils";

const sortClaimsOrder = (claims: Array<PreviousClaim | undefined>) => {
  return claims.sort((a, b) => {
    if (a === undefined && b === undefined) {
      return 0;
    }

    if (a === undefined && b !== undefined) {
      return 1;
    }

    if (a === undefined && b === undefined) {
      return -1;
    }

    if (a && b) {
      if (moment(a.date).isBefore(moment(b.date))) {
        return -1;
      }

      if (moment(a.date).isSame(moment(b.date))) {
        return 0;
      }

      if (moment(a.date).isAfter(moment(b.date))) {
        return 1;
      }
    }

    return 0;
  });
};

const ClaimsInformation = ({ nextPath }: { nextPath?: string }) => {
  const claimLimit = 2;
  const CLAIM_MAX_VALUE = 10000;
  const history = useHistory();
  const assistantCtx = useAssistantContext();
  const motorCtx = useMotorContext();
  const heapCtx = useHeapContext();
  const { setOpenModal, openModal } = useModalContext();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [nthClaimClicked, setNthClaimClicked] = useState<number>(0);

  const initialPreviousClaims = () => {
    const motorCtxClaims = motorCtx.savingsInfo.data.previousClaims;
    if (
      motorCtxClaims &&
      Array.isArray(motorCtxClaims) &&
      motorCtxClaims.length > 0
    ) {
      return sortClaimsOrder([...motorCtxClaims, undefined, undefined]);
    } else {
      return [undefined];
    }
  };
  const [componentPreviousClaims, setComponentPreviousClaims] = useState(
    [...initialPreviousClaims()].slice(0, claimLimit)
  );

  const isValidClaim = (claim: PreviousClaim | undefined) => {
    return Boolean(
      claim &&
        claim.amount &&
        claim.date &&
        typeof claim.atFault !== undefined &&
        typeof claim.isFinalized !== undefined
    );
  };

  useEffect(() => {
    assistantCtx.updateAssistant({
      isOpen: false,
      text: undefined,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = (formValues: FormValues) => {
    const cleanDate = cleanInputDate(`${formValues.claimDate}`);

    const values = {
      amount: maskedCurrency(`${formValues.claimAmount}`),
      date: formatUkDate(`${cleanDate}`),
      isFinalized: formValues.claimFinalized === "finalYes" ? true : false,
      atFault: formValues.claimFault === "faultYes" ? true : false,
    };

    heapCtx.track(HeapEventName.MOTOR_CLAIM_DETAILS, {
      Value: formValues.claimAmount as number,
      Date: values.date,
      "At Fault": values.atFault ? "yes" : "no",
      Finalized: values.isFinalized ? "yes" : "no",
    });

    const newClaims =
      typeof selectedIndex !== "number"
        ? [values, ...componentPreviousClaims].slice(0, claimLimit)
        : componentPreviousClaims.map((claim, index) =>
            index === selectedIndex ? { ...claim, ...values } : claim
          );

    setComponentPreviousClaims(sortClaimsOrder(newClaims));
    setOpenModal(undefined);
    setSelectedIndex(undefined);
  };

  const handleDelete = (index: number) => {
    let newClaims = [...componentPreviousClaims];
    newClaims.splice(index, 1);
    if (newClaims.length === claimLimit - 1 && newClaims[0] !== undefined) {
      newClaims = [...newClaims, undefined];
    }
    setComponentPreviousClaims(sortClaimsOrder(newClaims));
    setOpenModal(undefined);
  };

  const handleDone = async () => {
    try {
      await motorCtx.savingsInfo.setItem(
        "previousClaims",
        componentPreviousClaims.filter((claim) => claim)
      );

      const yearsWithoutClaims = getYearsWithoutClaims(motorCtx);

      await motorCtx.policyInfo.setItem(
        "yearsWithoutClaims",
        yearsWithoutClaims
      );

      heapCtx.track(HeapEventName.MOTOR_NCD, {
        "NCD Amount": yearsWithoutClaims,
      });

      if (
        componentPreviousClaims.find(
          (claim) =>
            claim?.atFault ||
            !claim?.isFinalized ||
            Number(claim?.amount.replace(/[^\d]/g, "")) >= CLAIM_MAX_VALUE
        )
      ) {
        return pushWithParams(
          history,
          `${NO_COVERAGE_URL}?message=invalid-claims`
        );
      } else if (nextPath) {
        return pushWithParams(history, nextPath);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  React.useEffect(() => {
    if (nthClaimClicked) setOpenModal("claimModal");
  }, [nthClaimClicked, setOpenModal]);

  return (
    <>
      <Text>1 or 2 claims</Text>
      <H3 className={styles.Subtitle} component="h1">
        <span id="add-claim-title">
          Start adding your claim details since {previousClaimsRange}.
        </span>
      </H3>
      <div className={styles.Toggles}>
        {componentPreviousClaims.map((claim, index) => {
          const isValid = isValidClaim(claim);
          return (
            <SelectableCard
              headingComponent="h2"
              key={index}
              title={claim && claim.amount ? `$${claim.amount}` : "Add claim"}
              description={
                isValid
                  ? `${getOrdinalWord(index + 1)} claim added`
                  : "Click to add claim details"
              }
              selected={isValid}
              onClick={() => {
                setSelectedIndex(isValid ? index : undefined);
                if (nthClaimClicked === index + 1) {
                  setOpenModal("claimModal");
                } else {
                  setNthClaimClicked(index + 1);
                }
              }}
              className={cx(styles.Toggle, {
                [styles.ToggleSelected]: isValid,
              })}
              ctaText={isValid ? "Edit" : undefined}
              describedby={!isValid ? "add-claim-title" : undefined}
            />
          );
        })}
      </div>
      <div className={styles.ButtonWrapper}>
        <Button
          onClick={handleDone}
          disabled={componentPreviousClaims.every(
            (previousClaim) => previousClaim === undefined
          )}
          className={styles.ButtonNext}
        >
          Done
        </Button>
      </div>
      <ClaimModal
        onSave={handleSave}
        modal="claimModal"
        openModal={openModal}
        onRemove={
          typeof selectedIndex === "number"
            ? () => handleDelete(selectedIndex)
            : undefined
        }
        initialValues={
          typeof selectedIndex === "number"
            ? componentPreviousClaims[selectedIndex]
            : undefined
        }
        closeModal={() => {
          setSelectedIndex(undefined);
          setOpenModal(undefined);
        }}
        options={{
          nth: nthClaimClicked,
        }}
      />
    </>
  );
};

export default ClaimsInformation;
