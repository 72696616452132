import React, { useState, useEffect } from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

import { H5, TextSmall } from "~/components/Typography";
import ButtonIcon from "~/components/ButtonIcon";
import Icon from "~/components/Icon";

import { AvailableIcons } from "~/components/Icon/index.types";
import { useAuth0Context } from "~/contexts/Auth0Context";
import { useMotorContext } from "~/contexts/MotorProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useUserContext } from "~/contexts/UserProvider";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import { Link } from "react-router-dom";

type PropsType = {
  className?: string;
  iconLetter: string | undefined;
};

export default function SideBarNavigation({
  className,
  iconLetter = "",
}: PropsType) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const auth0 = useAuth0Context();
  const homeCtx = useHomeContext();
  const motorCtx = useMotorContext();
  const userCtx = useUserContext();
  const onboardCtx = useOnboardContext();

  const linkParams = {
    className: styles.ListItemLink,
    onClick: () => {
      setToggleMenu(!toggleMenu);
    },
  };

  const handleLogout = () => {
    homeCtx.removeStore();
    motorCtx.vehicleInfo.removeStore();
    motorCtx.savingsInfo.removeStore();
    motorCtx.policyInfo.removeStore();
    userCtx.removeStore();
    onboardCtx.removeStore();
  };

  useEffect(() => {
    const handleEscPress = (event: KeyboardEvent) => {
      if (event.key === "Escape" && toggleMenu) {
        setToggleMenu(false);
      }
    };
    const handleClick = (event: MouseEvent) => {
      if (
        !window.document
          .querySelector(
            "#sidebar-wrapper [role='menuitem'], #profile-icon-btn"
          )
          ?.contains(event.target as Node)
      ) {
        setToggleMenu(false);
      }
    };

    window.document.addEventListener("keydown", handleEscPress);
    window.document.addEventListener("click", handleClick);

    return () => {
      window.document.removeEventListener("keydown", handleEscPress);
      window.document.removeEventListener("click", handleClick);
    };
  }, [toggleMenu]);

  return (
    <div className={className}>
      <button
        id="profile-icon-btn"
        className={styles.ProfileIcon}
        type="button"
        onClick={() => setToggleMenu(!toggleMenu)}
        aria-label="profile"
        aria-expanded={toggleMenu}
        aria-controls="profile-menu-list"
        aria-haspopup
      >
        <TextSmall>{iconLetter}</TextSmall>
      </button>
      <div
        id="sidebar-wrapper"
        className={classnames(styles.SidebarNavigationWrapper, {
          [styles.ToggleMenu]: toggleMenu,
        })}
      >
        <ButtonIcon
          iconName="Close"
          className={styles.CloseButton}
          iconBackgroundColor="#F6B600"
          width={32}
          height={32}
          onClick={() => setToggleMenu(!toggleMenu)}
        />
        <ul
          className={styles.List}
          id="profile-menu-list"
          hidden={!toggleMenu}
          role="menu"
        >
          <li className={styles.ListItem} role="menuitem">
            <Link {...linkParams} to="/portal" className={styles.ListItemLink}>
              <Icon name={"House" as AvailableIcons} />
              <H5 className={styles.ListItemLinkLabel}>Dashboard</H5>
            </Link>
          </li>
          <li className={styles.ListItem} role="menuitem">
            <Link
              {...linkParams}
              to="/portal/account"
              className={styles.ListItemLink}
            >
              <Icon name={"User" as AvailableIcons} />
              <H5 className={styles.ListItemLinkLabel}>My Account</H5>
            </Link>
          </li>
          <li className={styles.ListItem} role="menuitem">
            <a
              {...linkParams}
              href="https://almi.bb/faq/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.ListItemLink}
            >
              <Icon name={"Question" as AvailableIcons} />
              <H5 className={styles.ListItemLinkLabel}>FAQ</H5>
            </a>
          </li>
          <li className={styles.ListItem} role="menuitem">
            <a
              {...linkParams}
              onClick={() => {
                linkParams.onClick();
                handleLogout();
                auth0.logout();
              }}
              className={styles.ListItemLink}
              role="button"
              onKeyPress={() => {}}
              tabIndex={-1}
            >
              <Icon name={"LogOut" as AvailableIcons} />
              <H5 className={styles.ListItemLinkLabel}>Log Out</H5>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
