import React from "react";
import { FormValue } from "informed";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";

import { H3, TextSmall } from "~/components/Typography";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import VehicleSearchDisplaySmall from "~/components/VehicleSearchDisplaySmall";

import { useMotorContext } from "~/contexts/MotorProvider";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";

import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import pushWithParams from "~/helpers/pushWithParams";
import { NCD_HISTORY_CAP } from "~/helpers/constants";

export default function ClaimsYears({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const motorCtx = useMotorContext();

  const fieldName = "yearsWithoutClaims";
  const { value, error } = useCustomFieldState(fieldName);

  useUpdateAssistant({
    text: (
      <TextSmall>
        If you have a No Claims Discount, you may be asked to provide an NCD
        letter in the future.
      </TextSmall>
    ),
  });

  const validateYearsWithoutClaims = (value?: FormValue) => {
    if (value) {
      const yearsDrivingExperience = motorCtx.policyInfo.data.yearsDriving;
      if (value > Number(yearsDrivingExperience))
        return "Years since last claim can't be higher than number of years driving experience";
    }
    return undefined;
  };

  const handleOnNext = async () => {
    try {
      if (!error) {
        const yearsDriving = motorCtx.policyInfo.data.yearsDriving;
        const newYearsWithoutClaims = Number(
          value ? Number(value) : yearsDriving
        );
        await motorCtx.policyInfo.setItem(fieldName, newYearsWithoutClaims);

        const skipClaimHistory =
          !value || newYearsWithoutClaims > NCD_HISTORY_CAP;

        if (skipClaimHistory) {
          await motorCtx.policyInfo.setItem("claimsHistory", ">2");
          pushWithParams(history, "/BRB/motor/job-shift-work");
        } else {
          if (nextPath) {
            pushWithParams(history, nextPath);
          }
        }
      }
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <>
      <VehicleSearchDisplaySmall
        prefix={`Your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()} `}
      />

      <H3 className={styles.Question}>
        How many years has it been since your last motor insurance claim?
      </H3>
      <TextInput
        className={styles.TextInputWrapper}
        field={fieldName}
        placeholder="00 years"
        type="number"
        size={2}
        initialValue={motorCtx.policyInfo.data.yearsWithoutClaims?.toString()}
        keepState
        validate={validateYearsWithoutClaims}
        validateOnBlur
        validateOnChange
        autoFocus
        noValidate
        inputMode="numeric"
      />

      <div className={styles.ButtonWrapper}>
        <Button
          disabled={!!error || value === undefined}
          onClick={handleOnNext}
          className={styles.ButtonNext}
        >
          {"Next"}
        </Button>
      </div>
    </>
  );
}
