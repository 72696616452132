/* eslint-disable react/button-has-type */
import React, { memo } from "react";
import classnames from "classnames";

import Icon from "~/components/Icon";

import { Props } from "./index.types";
import styles from "./index.module.scss";

function ButtonIcon({
  iconName,
  className,
  type = "button",
  disabled,
  onClick,
  width,
  height,
  fill,
  iconBackgroundColor,
  children,
  autoFocus,
  ariaLabel,
  describedby,
}: Props) {
  const iconProps = {
    name: iconName,
    width,
    height,
    fill,
    backgroundColor: iconBackgroundColor,
    autoFocus,
  };

  return (
    <button
      className={classnames(className, styles.ButtonIconWrapper)}
      type={type}
      disabled={disabled}
      onClick={onClick}
      aria-label={ariaLabel || iconName}
      autoFocus={autoFocus}
      aria-describedby={describedby}
    >
      <Icon {...iconProps} />
      {children}
    </button>
  );
}
export default memo(ButtonIcon);
