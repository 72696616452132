import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { FormValue, RadioGroup } from "informed";

import { H3, TextSmall } from "~/components/Typography";
import VehicleSearchDisplaySmall from "~/components/VehicleSearchDisplaySmall";
import { useMotorContext } from "~/contexts/MotorProvider";
import { previousClaimsRange } from "~/helpers/motorQuote";

import styles from "./index.module.scss";
import RadioSelector from "~/components/RadioSelector";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import pushWithParams from "~/helpers/pushWithParams";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

const OPTIONS = [
  {
    title: "1 or 2 claims",
    value: "<=2",
  },
  {
    title: "More than 2 claims",
    value: ">2",
  },
];

function ClaimHistory({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const motorCtx = useMotorContext();
  const fieldName = "claimsHistory";
  const fieldState = useCustomFieldState(fieldName);
  const heapCtx = useHeapContext();

  useUpdateAssistant({
    text: (
      <TextSmall>
        Depending on how many claims you&apos;ve made in the past, you could
        qualify you for significant discounts.
      </TextSmall>
    ),
  });

  const handleOnChange = async (value: FormValue) => {
    motorCtx.policyInfo.removeItem("yearsWithoutClaims");

    try {
      if (nextPath && value) {
        await motorCtx.policyInfo.setItem("claimsHistory", value);

        heapCtx.track(HeapEventName.MOTOR_PAST_CLAIMS, {
          "Number of Past Claims": value as string,
        });

        if (value === ">2") {
          pushWithParams(
            history,
            `${"/contact-guardian"}?message=too-many-claims`
          );
        } else if (value === "<=2") {
          pushWithParams(history, "/BRB/motor/claim-info");
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <fieldset>
      <legend>
        <VehicleSearchDisplaySmall
          prefix={`Your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()} `}
        />
        <H3 component="h1">{`Have you submitted any car insurance claims since ${previousClaimsRange}?`}</H3>
      </legend>

      <div className={styles.ClaimHistoryContent}>
        <RadioGroup field={fieldName} keepState>
          {OPTIONS.map((option: { title: string; value: string }) => {
            return (
              <RadioSelector
                key={option.value}
                field={fieldName}
                value={option.value}
                checked={
                  fieldState.value === option.value ||
                  motorCtx.policyInfo.data.claimsHistory === option.value
                }
                onChange={(value) => handleOnChange(value)}
              >
                {option.title}
              </RadioSelector>
            );
          })}
        </RadioGroup>
      </div>
    </fieldset>
  );
}
export default memo(ClaimHistory);
