import React, {
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
  useState,
} from "react";
import { Route, useParams, useHistory } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { useFormState, useFormApi } from "informed";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useMotorContext } from "~/contexts/MotorProvider";
import styles from "../../App.module.scss";

import NotFound from "~/components/NotFound";
import GridWrapper from "~/components/GridWrapper";
import OnboardingLayout from "~/components/Layouts/OnboardingLayout";

import {
  calculateCurrentStepIndex,
  StepType,
  getNextPath,
  getPreviousPath,
  screenTransitionAnimation,
} from "~/helpers/flow";
import { useIsPaymentCheckout } from "~/helpers/useIsPaymentCheckout";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { NCD_HISTORY_CAP } from "~/helpers/constants";

type PropsType = {
  steps: StepType[];
  children: React.ReactElement[];
};

const MultiStepForm = ({ steps, children }: PropsType) => {
  const history = useHistory();
  const params: {
    countryCode: string | undefined;
    insuranceType: string | undefined;
    policyId: string | undefined;
    step: string | undefined;
  } = useParams();
  const {
    location: { pathname },
  } = useHistory();

  if (params.policyId !== undefined) {
    steps.map((_step: StepType) => {
      _step.path = _step.path.replace(":policyId", params.policyId ?? "");
    });
  }

  const { step } = useFormState();
  const currentStepIndex = calculateCurrentStepIndex(steps, pathname);
  const { setStep } = useFormApi();
  const stepEl = useRef<HTMLDivElement>(null);
  const headerCtx = useHeaderContext();
  const nextPath = getNextPath(steps, pathname);
  const previousPath = getPreviousPath(steps, pathname);
  const path = useRef("");
  const currentStepData = steps[currentStepIndex];
  const motorCtx = useMotorContext();
  const homeCtx = useHomeContext();
  const checklistCtx = useChecklistContext();
  const isPaymentCheckout = useIsPaymentCheckout();
  const [stepLoaded, setStepLoaded] = useState<boolean>();

  const childSteps = React.Children.map(children, (child, i) => {
    const props = {
      ...child.props,
      nextPath,
      step: i,
    };
    return React.cloneElement(child, props);
  });

  const updateHeader = useCallback(
    (total: number, step: number, title: string) => {
      headerCtx.setState({
        totalOfSteps: total,
        currentStep: step,
        title,
      });
    },
    [headerCtx]
  );

  const handlePrevious = () => {
    if (previousPath) {
      if (
        currentStepData.path === "/BRB/motor/email" &&
        motorCtx.savingsInfo.data.occupation?.match(/Unemployed/)
      ) {
        history.push("/BRB/motor/occupation");
      } else if (
        currentStepData.path === "/BRB/motor/left-right-hand" &&
        !motorCtx.vehicleInfo.data.isSportsCar
      ) {
        history.push("/BRB/motor/models");
      } else if (
        currentStepData.path === "/BRB/motor/job-shift-work" &&
        (motorCtx.policyInfo.data.claimsHistory === ">2" ||
          (motorCtx.policyInfo.data.yearsWithoutClaims || 0) > NCD_HISTORY_CAP)
      ) {
        motorCtx.policyInfo.removeItem("yearsWithoutClaims");
        history.push("/BRB/motor/no-claims-length");
      } else if (
        currentStepData.path ===
          `/checklist/motor/${checklistCtx.policyId}/started` ||
        currentStepData.path ===
          `/checklist/home/${checklistCtx.policyId}/started`
      ) {
        //TODO use the policy type from the checklist CTX to check once it is added
        history.push("/portal");
      } else if (
        currentStepData.path === "/BRB/home/insurance-review" &&
        !homeCtx.contentsValue
      ) {
        history.push("/BRB/home/contents-coverage");
      } else if (
        currentStepData.path === "/BRB/home/contents-value" &&
        !homeCtx.allRisk
      ) {
        history.push("/BRB/home/additional-protection");
      } else if (
        currentStepData.path ===
          `/checklist/motor/${checklistCtx.policyId}/health-conditions` &&
        (!checklistCtx.additionalDrivers ||
          checklistCtx.additionalDrivers.length === 0)
      ) {
        history.push(`/checklist/motor/${checklistCtx.policyId}/started`);
      } else {
        history.push(previousPath);
      }
    } else {
      if (
        currentStepData.path === "/BRB/motor/quick-note" ||
        currentStepData.path === "/BRB/home/quick-note"
      ) {
        history.push("/BRB/insurance");
      } else {
        history.goBack();
      }
    }
  };

  useEffect(() => {
    setStepLoaded(false);
    if (!params.step && history.location.pathname !== steps[0].path) {
      history.replace(steps[0].path);
    }
  }, [params, history, steps]);

  useEffect(() => {
    setStepLoaded(true);
  }, []);

  useEffect(() => {
    if (currentStepIndex >= 0 && currentStepIndex !== step) {
      setStep(currentStepIndex);
    }
  }, [step, pathname, setStep, steps, currentStepIndex]);

  useEffect(() => {
    if (!path || path.current !== pathname) {
      path.current = pathname;
      updateHeader(
        steps.length,
        currentStepIndex,
        (currentStepData && currentStepData.headerTitle) || ""
      );
    }
  }, [
    pathname,
    headerCtx,
    steps,
    currentStepData,
    currentStepIndex,
    updateHeader,
  ]);

  useLayoutEffect(() => {
    if (stepEl.current !== null) {
      stepEl.current.focus();
    }
  }, [step]);

  if (!steps[step]) {
    console.warn("Step undefined");
    return null;
  }
  return (
    <AnimatedSwitch
      {...screenTransitionAnimation}
      didLeave={() => setStepLoaded(true)}
      className={styles.SwitchWrapper}
    >
      {steps.map((item, index) => {
        return (
          <Route exact path={item.path} key={`${item.path}-${index}`}>
            <OnboardingLayout
              hideBackButton={item.hideBackButton}
              // if step is not fully loaded
              //pass in empty function for goBack button
              // this avoids app crashing if user clicks "go back" quickly
              onPrevClick={stepLoaded ? handlePrevious : () => {}}
            >
              {isPaymentCheckout ? (
                childSteps[index]
              ) : (
                <GridWrapper>{childSteps[index]}</GridWrapper>
              )}
            </OnboardingLayout>
          </Route>
        );
      })}
      <Route path="*" component={NotFound} />
    </AnimatedSwitch>
  );
};
export default MultiStepForm;
