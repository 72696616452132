import React from "react";
import cx from "classnames";
import ReactModal from "react-modal";
import { useModalContext } from "~/contexts/ModalContext";
import Button from "~/components/Button";
import { H4 } from "~/components/Typography";

import styles from "./index.module.scss";
import "./animations.scss";

type PropsType = {
  className?: string;
  overlayClassName?: string;
  onCancel?: () => void;
  internalLink?: string;
  externalLink?: string;
  hasDescription?: boolean;
  isOpen: boolean;
};

type BaseModalPropsType = PropsType & {
  isOpen?: boolean;
  message?: string;
  id?: string;
};

ReactModal.setAppElement("#root");

export default function AlertModal({
  className,
  overlayClassName,
  onCancel,
  internalLink,
  externalLink,
  hasDescription,
  message = "Are you sure you want to exit?",
  id,
  ...rest
}: BaseModalPropsType) {
  const { setOpenModal } = useModalContext();

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setOpenModal(undefined);
  };

  return (
    <ReactModal
      id={id}
      closeTimeoutMS={300}
      className={cx(styles.Modal, className)}
      overlayClassName={cx(styles.Overlay, overlayClassName)}
      bodyOpenClassName={styles.Open}
      onRequestClose={handleOnCancel}
      aria={{
        labelledby: `modal-title-${id}`,
        describedby: hasDescription ? `modal-desc-${id}` : undefined,
      }}
      {...rest}
    >
      <div className={styles.AlertModalWrapper}>
        <H4>{message}</H4>
        <div className={styles.ButtonGroup}>
          <Button
            variant="tertiary"
            size="small"
            className={styles.Btn}
            internalLink={internalLink}
            externalLink={externalLink}
          >
            Yes
          </Button>
          <Button size="small" className={styles.Btn} onClick={handleOnCancel}>
            No, thanks
          </Button>
        </div>
      </div>
    </ReactModal>
  );
}
