import React, { ReactNode } from "react";
import styles from "./index.module.scss";
import { H4 } from "../Typography";

type Props = {
  title?: string;
  contact: string;
  href: string;
  children?: ReactNode;
  target?: string;
};
export default function SupportContactCard(props: Props) {
  const { title = "Call Us", contact, children, href, target } = props;

  return (
    <a className={styles.Link} href={href} target={target}>
      <div className={styles.SupportContactCardWrapper}>
        <div className={styles.Heading}>
          {title && <H4 className={styles.Title}>{title}</H4>}
          {contact && <H4 className={styles.Contact}>{contact}</H4>}
        </div>
        {children && <div className={styles.BodyContent}>{children}</div>}
      </div>
    </a>
  );
}
