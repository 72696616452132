import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useFormApi } from "informed";

import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import { H3, Bold, TextSmall } from "~/components/Typography";
import Modal from "~/components/Modal";
import GridWrapper from "~/components/GridWrapper";
import { useModalContext } from "~/contexts/ModalContext";
import {
  cleanInputDate,
  validateCoverageStartDate,
} from "~/helpers/validators";

import styles from "./index.module.scss";
import DatePicker from "~/components/DatePicker";
import Icon from "~/components/Icon";
import {
  formatIsoDate,
  formatUkDate,
  getCoverageStartMaxDate,
  localDateFromIso,
  todaysDateInAst,
} from "~/helpers/dates";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import pushWithParams from "~/helpers/pushWithParams";
// import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useHomeContext } from "~/contexts/HomeProvider";

export default function CoverageStart({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const { setOpenModal, openModal } = useModalContext();
  const formApi = useFormApi();
  const { value, error } = useCustomFieldState<string>("coverageStart");
  // const heapCtx = useHeapContext();

  //We don't need to re-render this.
  const [maxDate] = React.useState<moment.Moment>(
    moment(localDateFromIso(getCoverageStartMaxDate()))
  );

  const coverageStart = homeCtx.coverageStart;
  const savedValue = coverageStart ? formatIsoDate(coverageStart) : undefined;

  useUpdateAssistant({
    text: (
      <TextSmall>
        Your coverage can start today, or any day until{" "}
        <Bold>{maxDate.format("MMM D, YYYY")}</Bold>.
      </TextSmall>
    ),
  });

  const handleOnClick = async () => {
    try {
      if (value && !error && typeof value === "string") {
        const formattedDate = formatUkDate(cleanInputDate(value));
        await homeCtx.setState({ coverageStart: formattedDate });

        // TODO: Once Heap for home is implement, this can be added
        // heapCtx.track(HeapEventName.HOME_COVERAGE_DATE, {
        //   "Coverage Date": formattedDate,
        // });
      }

      if (nextPath && !error) pushWithParams(history, nextPath);
    } catch (error) {
      console.warn(error);
    }
  };

  const setDateFromPicker = async (date: Date | undefined) => {
    formApi.setValue("coverageStart", moment(date).format("DD/MM/YYYY"));
    setOpenModal(undefined);
    try {
      await homeCtx.setState({
        coverageStart: moment(date).format("YYYY-MM-DD"),
      });
    } catch (error) {
      console.warn(error);
    }
  };

  const label = "When do you want your coverage to start?";

  return (
    <>
      <H3 className={styles.Question} component="h1">
        {label}
      </H3>
      <div className={styles.TextInputWrapper}>
        <TextInput
          label={label}
          hideLabelFromView
          field="coverageStart"
          placeholder="DD / MM / YYYY"
          type="date"
          maintainCursor
          validate={validateCoverageStartDate}
          validateOnMount
          initialValue={value && typeof value === "string" ? value : savedValue}
          validateOnBlur
          validateOnChange
          keepState
          autoFocus
        />
      </div>
      <button
        type="button"
        onClick={() => setOpenModal("coverage")}
        className={styles.CalendarToggle}
        aria-hidden
        tabIndex={-1}
      >
        <Icon name="Calendar" className={styles.CalendarIcon} />
        <TextSmall className={styles.CalendarCta}>Choose date</TextSmall>
      </button>
      <div className={styles.ButtonWrapper}>
        <Button
          onClick={handleOnClick}
          disabled={!value || !!error}
          className={styles.ButtonNext}
        >
          Next
        </Button>
      </div>
      <Modal
        isOpen={openModal === "coverage"}
        title="Select a day"
        shouldOverlayCloseOnClick
      >
        <GridWrapper contentClassName={styles.Grid}>
          <div className={styles.Calendar} aria-hidden>
            <DatePicker
              date={moment(value, "DD/MM/YYYY").toDate()}
              setDate={setDateFromPicker}
              minimumDate={localDateFromIso(todaysDateInAst())}
              maximumDate={maxDate.toDate()}
            />
          </div>
        </GridWrapper>
      </Modal>
    </>
  );
}
